import {
  FileOperationActionType,
  FILEUPLOAD_CLEAR
} from '../../frontend-common-libs/src/file-operations/file_operation_types';
import { Dispatch, GetState } from '../../types';
import {
  USERFILES_PROCESSING_COMPLETE,
  UserFilesActionType
} from '../../file-management/actions/action-types';
import FileUploadRepository from './fileupload_repository';
import FileUploadQueue from './fileupload_queue';
import FileUpload from './fileupload';
import FileUploadDoesNotExistError from './FileUploadDoesNotExistError';
import FileUploadTrackingEvent from '../../user-analytics/FileUploadTrackingEvent';
import { DEFAULT_PROJECT_ID } from '../../frontend-common-libs/src/common/project-management-types';
import FileOperationEvent from '../../frontend-common-libs/src/file-operations/FileOperationEvent';
import { getNextUploadId } from '../../frontend-common-libs/src/file-operations/utils';

export const fileUploadRepository = new FileUploadRepository();
const fileUploadQueue = new FileUploadQueue();

const onUploadResolved = (queueId: number): void => {
  fileUploadRepository.delete(queueId);
};

export function uploadFiles(
  files: File[],
  projectId: string = DEFAULT_PROJECT_ID
): (dispatch: Dispatch<FileOperationActionType, any>) => any {
  return (dispatch: Dispatch<FileOperationActionType, any>) => {
    files.forEach(file => {
      const queueId = getNextUploadId();
      const fileUpload = FileUpload.create(
        queueId,
        file,
        fileUploadQueue,
        dispatch,
        onUploadResolved,
        projectId
      );
      fileUploadRepository.add(fileUpload, queueId);
      fileUpload.start();
      new FileUploadTrackingEvent(file.name).track();
    });
  };
}

export function cancelUpload(uploadId: number): () => void {
  return () => {
    try {
      const fileUpload = fileUploadRepository.get(uploadId);
      fileUpload.cancelUpload();
    } catch (error) {
      if (error instanceof FileUploadDoesNotExistError) {
        return;
      }
      throw error;
    }
  };
}

export function cancelAllUploads(): () => void {
  return () => {
    fileUploadRepository.getAll().forEach((upload: FileUpload) => {
      upload.cancelUpload();
    });
  };
}

export function clearUploads(): (
  dispatch: Dispatch<FileOperationActionType, any>,
  getState: GetState
) => void {
  return (dispatch: Dispatch<FileOperationActionType, any>, getState: GetState) => {
    const state = getState();

    if (
      state.fileOperations.get('uploading').size === 0 &&
      state.fileOperations.get('processing').size === 0
    ) {
      dispatch({
        type: FILEUPLOAD_CLEAR,
        payload: null
      });
    }
  };
}

export function onIotMessage(dispatch: Dispatch<UserFilesActionType, any>, entity: any): void {
  const { id } = entity;
  try {
    const fileUpload = fileUploadRepository.getByEntityId(id);
    fileUpload.onIotMessage(entity);
  } catch (error) {
    if (error instanceof FileUploadDoesNotExistError) {
      dispatch({
        type: USERFILES_PROCESSING_COMPLETE,
        payload: {
          entity
        }
      });
    }
  }
}

export function dispatchFileOperation(
  event: FileOperationEvent
): (dispatch: Dispatch<FileOperationActionType, any>) => void {
  return (dispatch: Dispatch<FileOperationActionType, any>) => {
    dispatch({
      type: event.type,
      payload: event.payload
    });
  };
}
