import {
  USERFILES_ADDED,
  USERFILES_ARCHIVED,
  USERFILES_DELETED,
  USERFILES_ERROR,
  USERFILES_LOADED,
  USERFILES_LOADING,
  USERFILES_RENAMED,
  USERFILES_SEARCH_CLEARED,
  USERFILES_SEARCH_ERROR,
  USERFILES_SEARCH_INPUT_CHANGED,
  USERFILES_SEARCH_LOADED,
  USERFILES_SEARCH_LOADING,
  USERFILES_SEARCHED,
  UserFilesActionType
} from './action-types';
import { browserDownloadFile } from '../../frontend-common-libs/src/utils/petfUtils';
import notification from '../../frontend-common-libs/src/utils/notifications';
import {
  archiveEntities,
  deleteEntities,
  getEntitiesOfProject,
  getEntityDownloadUrl,
  moveEntity,
  putEntity
} from '../../frontend-common-libs/src/api/entities';
import { Dispatch, GetState } from '../../types';
import { PROTOCOL_ENTITY_TYPE } from '../../frontend-common-libs/src/common/protocol_common';
import {
  FILETYPE_IN_PROGRESS,
  FILETYPE_UPLOAD
} from '../../frontend-common-libs/src/common/userfiles_common';
import GeneratePcrd from '../../actions/generate_pcrd/generate_pcrd';
import { getSelectedProjectId } from '../../project-management';
import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import { CPCR_PROTOCOL_ENTITY_TYPE } from '../../frontend-common-libs/src/conventional-pcr/protocol';
import { UserFile } from '../../frontend-common-libs/src/common/types';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';

export const EXCLUDED_FILES = [PROTOCOL_ENTITY_TYPE, CPCR_PROTOCOL_ENTITY_TYPE];

export const EXCLUDE_TYPE_LIST = [FILETYPE_IN_PROGRESS, FILETYPE_UPLOAD, ...EXCLUDED_FILES];

type DispatchType = Dispatch<UserFilesActionType, Record<string, any> | string>;

async function getFilesOfProject(lastSeen: string, projectId: ProjectId, searchText?: string) {
  const query = {
    lastSeen,
    exclude: EXCLUDE_TYPE_LIST,
    search: searchText
  };
  return getEntitiesOfProject(query, projectId);
}

function getUserFiles(lastSeen: string, projectId: ProjectId, searchText?: string) {
  return async (dispatch: DispatchType) => {
    dispatch({
      type: USERFILES_LOADING,
      payload: { projectId }
    });

    try {
      const payload = await getFilesOfProject(lastSeen, projectId, searchText);

      dispatch({
        type: USERFILES_LOADED,
        payload: { ...payload, projectId }
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      dispatch({
        type: USERFILES_ERROR,
        payload: { message, projectId }
      });
    }
  };
}

function getUserFilesBySearchText(lastSeen: string, projectId: ProjectId, searchText?: string) {
  return async (dispatch: DispatchType) => {
    dispatch({
      type: USERFILES_SEARCH_LOADING,
      payload: { projectId }
    });

    try {
      const payload = await getFilesOfProject(lastSeen, projectId, searchText);
      dispatch({
        type: USERFILES_SEARCH_LOADED,
        payload: { ...payload, projectId }
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      dispatch({
        type: USERFILES_SEARCH_ERROR,
        payload: { message, projectId }
      });
    }
  };
}

function isUserFilesLoading(getState: GetState) {
  const state = getState();
  const projectId = getSelectedProjectId(state);
  return state.userFiles.getIn(['projects', projectId, 'isLoading']);
}

export function getUserFilesIfNeeded(lastSeen: string, searchText?: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const projectId = getSelectedProjectId(getState());
    if (!isUserFilesLoading(getState)) {
      await dispatch(getUserFiles(lastSeen, projectId, searchText));
    }
  };
}

export function searchFiles(searchText: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const projectId = getSelectedProjectId(getState());
    dispatch({
      type: USERFILES_SEARCHED,
      payload: { projectId }
    });
    if (!isUserFilesLoading(getState)) {
      await dispatch(getUserFilesBySearchText('', projectId, searchText));
    }
  };
}

export function onSearchInputChanged(searchText: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const projectId = getSelectedProjectId(getState());
    dispatch({
      type: USERFILES_SEARCH_INPUT_CHANGED,
      payload: { searchText, projectId }
    });
  };
}

export function onSearchCleared() {
  return async (dispatch: DispatchType, getState: GetState) => {
    const projectId = getSelectedProjectId(getState());
    dispatch({
      type: USERFILES_SEARCH_CLEARED,
      payload: { projectId }
    });
    await dispatch(getUserFiles('', projectId));
  };
}

// fileList should be a list of file ID's, e.x.:
// [ 10, 25, 6, 32 ]
export function deleteUserFiles(fileList: string[]) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const projectId = getSelectedProjectId(getState());
    try {
      await deleteEntities(fileList);
      dispatch({
        type: USERFILES_DELETED,
        payload: { fileList, projectId }
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      dispatch({
        type: USERFILES_ERROR,
        payload: { message, projectId }
      });
    }
  };
}

export function archiveUserFiles(fileList: string[]) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    const projectId = getSelectedProjectId(state);
    try {
      const res = await archiveEntities(fileList);
      const { entities } = res.data;
      dispatch({
        type: USERFILES_ARCHIVED,
        payload: { entities }
      });

      const searchText = state.userFiles.getIn(['projects', projectId, 'searchText']);

      if (searchText) {
        await searchFiles(<string>searchText)(dispatch, getState);
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      dispatch({
        type: USERFILES_ERROR,
        payload: { message, projectId }
      });
    }
  };
}

export function renameFile(fileId: string, newName: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    try {
      const { data: faEntity } = await putEntity(fileId, { name: newName });

      dispatch({
        type: USERFILES_RENAMED,
        payload: {
          faEntity,
          name: newName
        }
      });

      const state = getState();
      const projectId = getSelectedProjectId(state);
      const searchText = state.userFiles.getIn(['projects', projectId, 'searchText']);

      if (searchText) {
        await searchFiles(<string>searchText)(dispatch, getState);
      }
    } catch (ex) {
      throw Error('Edit failed. Check your network connection.');
    }
  };
}

export function moveFileToProject(entity: UserFile, projectId: string) {
  return async (dispatch: DispatchType) => {
    try {
      const faEntity = await moveEntity(entity, projectId);
      dispatch({
        type: USERFILES_DELETED,
        payload: { fileList: [entity.id], projectId: entity.parent_id }
      });
      dispatch({
        type: USERFILES_ADDED,
        payload: { entity: faEntity }
      });
      trackEvent(TrackedOrganizationEvents.MoveFileToProject, {
        entityName: entity.name,
        fileType: entity.type,
        projectId
      });
    } catch (ex) {
      notification.error('Error moving file to project');
    }
  };
}

export async function downloadFile(id: string, name: string) {
  try {
    const result = await getEntityDownloadUrl(id);
    browserDownloadFile(name, result.data.url);
  } catch (err) {
    notification.error('Error getting download connection. Please retry');
  }
}

export function generatePcrd(id: string, name: string) {
  return async (dispatch: Dispatch<UserFilesActionType, Record<string, any>>) => {
    const genPcrd = new GeneratePcrd(id, name, dispatch);
    try {
      await genPcrd.waitForFile();
    } catch (err) {
      genPcrd.handleErrorInGenerating(err as Error);
    }
  };
}
