import { useEffect, useState } from 'react';
import { Seq, Map } from 'immutable';
import { ImmutableMap, InstrumentItem } from '../../../frontend-common-libs/src/common/types';
import InstrumentReservation from '../InstrumentReservation';
import { getCurrentUserIdentityId } from '../../../frontend-common-libs/src/utils/authUtils';
import Reservation from '../Reservation';

export type UseFleetManagementParams = {
  instruments: Seq.Indexed<ImmutableMap<InstrumentItem>>;
  selectedTab: string;
};

export default function useReservations({ instruments, selectedTab }: UseFleetManagementParams) {
  const [isLoading, setIsLoading] = useState(false);
  const [reservations, setReservations] = useState<Map<string, InstrumentReservation>>(
    Map<string, InstrumentReservation>()
  );
  const [userReservations, setUserReservations] = useState<Map<string, InstrumentReservation>>(
    Map<string, InstrumentReservation>()
  );
  const [updatedReservation, setUpdatedReservation] = useState<InstrumentReservation | null>(null);
  const [processCallback, setProcessCallback] = useState<boolean>(false);
  const userId = getCurrentUserIdentityId();

  const disconnectAllInstruments = () => {
    const disconnectPromises = instruments.map(fleetInstrument => {
      const instrumentId = fleetInstrument.get('id');
      return new Reservation().disconnect(instrumentId, 'update');
    });
    // @ts-ignore
    if (instruments?.size > 0) {
      const disconnectAll = async () => {
        await Promise.all(disconnectPromises);
      };
      disconnectAll();
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      const componentCallback = (updatedInstrument: InstrumentReservation) => {
        setProcessCallback(true);
        setUpdatedReservation(updatedInstrument);
      };
      const connectPromises: Promise<void>[] = [];
      const updatedFleetInstruments = reservations.withMutations(updatedMap => {
        instruments.forEach(instrument => {
          const instrumentId = instrument.get('id');
          if (!updatedMap.has(instrumentId)) {
            const fleetInstrument = new InstrumentReservation(
              userId,
              instrumentId,
              componentCallback
            );
            updatedMap.set(instrumentId, fleetInstrument);
            connectPromises.push(fleetInstrument.connect());
          }
        });
      });
      setReservations(updatedFleetInstruments);

      if (connectPromises.length > 0) {
        const connectAll = async () => {
          await Promise.all(connectPromises);
          setIsLoading(false);
        };
        connectAll();
      } else {
        setIsLoading(false);
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!isLoading) {
      const updatedReservedInstruments = userReservations.withMutations(updatedMap => {
        reservations.forEach(fleetInstrument => {
          const { instrumentId } = fleetInstrument;
          if (fleetInstrument?.isReservedByUser()) {
            updatedMap.set(instrumentId, fleetInstrument);
          }
        });
      });
      setUserReservations(updatedReservedInstruments);
    }
  }, [isLoading]);

  useEffect(() => {
    if (updatedReservation) {
      const { instrumentId } = updatedReservation;
      const updatedReservedInstruments = userReservations.withMutations(updatedMap => {
        if (updatedReservation.isReservedByUser()) {
          updatedMap.set(instrumentId, updatedReservation);
        } else {
          updatedMap.delete(instrumentId);
        }
      });
      setUserReservations(updatedReservedInstruments);

      setUpdatedReservation(null);
    }
    setProcessCallback(false);
  }, [updatedReservation, processCallback]);

  useEffect(() => {
    return () => {
      disconnectAllInstruments();
    };
  }, []);

  return { reservations, userReservations, isLoading };
}
