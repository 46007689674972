import { Map } from 'immutable';
import noneProtocolItem from '../noneProtocolItem';
import InstrumentReservation from '../InstrumentReservation';

export default async function unreserveSelectedInstruments(
  selectedInstruments: string[],
  reservations: Map<string, InstrumentReservation>
): Promise<void> {
  const unreservePromises = selectedInstruments.map(instrumentId => {
    const reservation = reservations.get(instrumentId);
    return reservation?.unreserve();
  });
  await Promise.all(unreservePromises);
}

export async function assignProtocolHandler(
  selectedInstruments: string[],
  reservations: Map<string, InstrumentReservation>,
  selectedProtocolName: string,
  selectedProtocolId: string
): Promise<void> {
  if (selectedProtocolId === noneProtocolItem.id) {
    const unassignProtocolPromises = selectedInstruments.map(instrumentId => {
      const reservation = reservations.get(instrumentId);
      return reservation?.unassignProtocol();
    });
    await Promise.all(unassignProtocolPromises);
  } else {
    const assignProtocolPromises = selectedInstruments.map(instrumentId => {
      const reservation = reservations.get(instrumentId);
      return reservation?.assignProtocol(selectedProtocolId, selectedProtocolName);
    });
    await Promise.all(assignProtocolPromises);
  }
}
