import React, { useEffect } from 'react';
import { Map, OrderedMap } from 'immutable';
import { connect } from 'react-redux';
import * as instrumentActions from '../../instruments/actions/instrument.actions';
import { ReduxState } from '../../types';
import Loader from '../../frontend-common-libs/src/components/common/Loader';
import './styles/instrument-fleet.scss';
import { getSelectedProjectId } from '../../project-management';
import {
  InstrumentFamilyPluginRepository,
  InstrumentTypeEnum
} from '../../instrument-family-plugin';
import InstrumentList from '../InstrumentList';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';

export type Props = {
  isLoading: boolean;
  instruments: OrderedMap<string, Map<string, any>>;
  statuses: Map<string, Map<string, any>>;
  errorMessage: string;
  loadInstrumentListIfNeeded: typeof instrumentActions.loadInstrumentListIfNeeded;
  projectId: string;
};

export function FleetImpl(props: Props) {
  useEffect(() => {
    const { instruments, loadInstrumentListIfNeeded } = props;
    if (!instruments.size) {
      loadInstrumentListIfNeeded();
    }
  }, []);

  const { instruments, isLoading, errorMessage, loadInstrumentListIfNeeded, statuses, projectId } =
    props;

  if (isLoading) {
    return <Loader />;
  }

  const instrumentList = InstrumentList.create(instruments, statuses);

  const ptcTempoInstruments: ImmutableMap<InstrumentItem>[] = [];
  instrumentList.forEach(instrument => {
    if (instrument.get('type') === InstrumentTypeEnum.ptcTempo) {
      ptcTempoInstruments.push(instrument);
    }
  });

  const renderFleetManagementPlugin = () => {
    const plugin =
      InstrumentFamilyPluginRepository.getInstance().getPluginByInstrumentType('PTCTempo');
    if (plugin) {
      const FleetManagement = plugin.getFleetManagement();

      return (
        <FleetManagement
          instruments={ptcTempoInstruments}
          projectId={projectId}
          fetchMoreData={loadInstrumentListIfNeeded}
          isLoading={isLoading}
          isError={!!errorMessage}
        />
      );
    }

    return null;
  };

  return (
    <div id="instrument-fleet">
      <span id="fleet-page-subtitle" className="fleet-management-header">
        Instrument Reservation and Control
      </span>
      {renderFleetManagementPlugin()}
    </div>
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.instruments.get('isLoading'),
    projectId: getSelectedProjectId(state),
    instruments: state.instruments.get('instruments'),
    errorMessage: state.instruments.get('error'),
    statuses: state.instruments.get('statuses')
  };
}

export default connect(mapStateToProps, {
  loadInstrumentListIfNeeded: instrumentActions.loadInstrumentListIfNeeded
  // @ts-ignore
})(FleetImpl);
