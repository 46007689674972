import React from 'react';
import { Button, Wrapper, Menu, MenuItem } from 'react-aria-menubutton';
import classNames from 'classnames';

export type DropdownProps = {
  handleSelection?: (...args: Array<any>) => any;
  placeholder?: string;
  value?: any;
  id: string;
  disabled?: boolean;
};

type Props = {
  className: string;
  children: any;
} & DropdownProps;

function Dropdown(props: Props) {
  const { handleSelection, value, placeholder, id, className, disabled, children, ...rest } = props;

  return (
    <Wrapper id={id} className={className} onSelection={handleSelection} {...rest}>
      <Button
        tabIndex={disabled ? -1 : 0}
        id={`${id}-button`}
        className={classNames(`${className}-button`, {
          disabled
        })}
        disabled={disabled}
      >
        <span
          className={classNames(`${className}-display`, {
            'default-value': !value
          })}
        >
          {value || placeholder}
        </span>
      </Button>
      <Menu className={`${className}-menu`}>{children}</Menu>
    </Wrapper>
  );
}

Dropdown.defaultProps = {
  handleSelection: undefined,
  placeholder: undefined,
  value: undefined,
  disabled: false
};

export { MenuItem };
export default Dropdown;
