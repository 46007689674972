import { v4 as uuidv4 } from 'uuid';
import InstrumentNamedShadow, {
  TopicStub
} from '../../../frontend-common-libs/src/iot/InstrumentNamedShadow';
import ReservationShadowWebsocketRepository from './ReservationShadowWebsocketRepository';

type Action = 'startRun' | 'openLid' | 'closeLid' | 'stopRun' | 'skipStep';

type DesiredReservationState = {
  state: {
    desired: {
      reservation: {
        userID?: string;
        reserved?: boolean;
        job?: { jobId: string } | null;
        action?: Action;
      };
    };
  };
  clientToken: string;
};

export default class ReservationShadow {
  private readonly shadowName = 'reservation';

  private instrumentNamedShadow: InstrumentNamedShadow | undefined;

  private reservationShadowWebsocketRepository = ReservationShadowWebsocketRepository;

  public async get(instrumentId: string): Promise<unknown> {
    const instrumentWebsocket =
      await ReservationShadowWebsocketRepository.instance.getInstrumentWebsocket(instrumentId);
    return new Promise((resolve, reject) => {
      const instrumentNamedShadow: InstrumentNamedShadow = new InstrumentNamedShadow(
        instrumentWebsocket
      );
      const reservationGetTopicCallback = (topic: string, payload: unknown): void => {
        const acceptedTopic = `${instrumentId}/shadow/name/${this.shadowName}/get/accepted`;
        if (topic.includes(acceptedTopic)) {
          instrumentNamedShadow.unsubscribeNamedShadow(instrumentId, this.shadowName, 'get');
          resolve((payload as Buffer).toString());
        } else {
          reject(new Error(`Error getting the ${this.shadowName} shadow state`));
        }
      };
      try {
        instrumentNamedShadow.subscribeToNamedShadow(
          instrumentId,
          this.shadowName,
          'get',
          reservationGetTopicCallback
        );
        instrumentNamedShadow.publishToNamedShadow(instrumentId, this.shadowName, 'get');
      } catch (error) {
        reject(
          new Error(`Something went wrong while trying to get the ${this.shadowName} shadow state`)
        );
      }
    });
  }

  public async updateReservation(
    instrumentId: string,
    reserved: boolean,
    userId = '',
    job: { jobId: string } | null = null
  ): Promise<void> {
    const clientToken = ReservationShadow.generateClientToken();
    const desiredShadowState: DesiredReservationState = {
      state: { desired: { reservation: { userID: userId, reserved, job } } },
      clientToken
    };

    await this.updateNamedShadow(instrumentId, desiredShadowState);
  }

  public async updateJob(instrumentId: string, jobId: string | null): Promise<void> {
    const clientToken = ReservationShadow.generateClientToken();
    const jobSection = jobId ? { jobId } : null;
    const desiredShadowState: DesiredReservationState = {
      state: { desired: { reservation: { job: jobSection } } },
      clientToken
    };

    await this.updateNamedShadow(instrumentId, desiredShadowState);
  }

  public async updateAction(instrumentId: string, action: Action): Promise<void> {
    const clientToken = ReservationShadow.generateClientToken();
    const desiredShadowState: DesiredReservationState = {
      state: { desired: { reservation: { action } } },
      clientToken
    };

    await this.updateNamedShadow(instrumentId, desiredShadowState);
  }

  private static async getInstrumentNamedShadow(
    instrumentId: string
  ): Promise<InstrumentNamedShadow> {
    const instrumentWebsocket =
      await ReservationShadowWebsocketRepository.instance.getInstrumentWebsocket(instrumentId);
    return new InstrumentNamedShadow(instrumentWebsocket);
  }

  private async updateNamedShadow(
    instrumentId: string,
    desiredShadowState: DesiredReservationState
  ): Promise<void> {
    this.instrumentNamedShadow = await ReservationShadow.getInstrumentNamedShadow(instrumentId);
    try {
      this.instrumentNamedShadow.updateNamedShadow(
        instrumentId,
        this.shadowName,
        desiredShadowState
      );
    } catch (e) {
      throw new Error(`Failed to update ${this.shadowName} shadow`);
    }
  }

  private static generateClientToken(): string {
    return `brio-${uuidv4()}`;
  }

  public async unsubscribeFromTopic(instrumentId: string, topicStub: TopicStub) {
    this.instrumentNamedShadow = await ReservationShadow.getInstrumentNamedShadow(instrumentId);
    try {
      this.instrumentNamedShadow.unsubscribeNamedShadow(instrumentId, this.shadowName, topicStub);
    } catch (e) {
      throw new Error(
        `Failed to unsubscribe to update topic for ${instrumentId} in ${this.shadowName} shadow`
      );
    }
  }

  public async closeWebsocketConnection(instrumentId: string): Promise<void> {
    await this.reservationShadowWebsocketRepository.instance.closeInstrumentWebsocket(instrumentId);
  }

  public async subscribeToUpdateTopic(
    instrumentId: string,
    callback: (topic: string, payload: unknown) => void
  ): Promise<void> {
    this.instrumentNamedShadow = await ReservationShadow.getInstrumentNamedShadow(instrumentId);
    try {
      this.instrumentNamedShadow.subscribeToNamedShadow(
        instrumentId,
        this.shadowName,
        'update',
        callback
      );
    } catch (e) {
      throw new Error(
        `Failed to subscribe to update topic for ${instrumentId} in ${this.shadowName} shadow`
      );
    }
  }
}
