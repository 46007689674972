import { Dispatch } from '../../types';
import {
  USERFILES_ADDED,
  USERFILES_ARCHIVED,
  USERFILES_RESTORED,
  UserFilesActionType
} from '../../file-management/actions/action-types';
import { onIotMessage as fileUploadOnIotMessage } from '../file_upload/fileupload_actions';
import { EXCLUDED_FILES } from '../../file-management/actions/actions';
import { entityActionEnum } from '../../frontend-common-libs/src/iot/entityActionEnum';
import {
  CDGO_MSCN,
  CDGO_SCN,
  CDGO_SMCN,
  CDGO_SSCN,
  FILETYPE_IN_PROGRESS_PTC_TEMPO_RUN
} from '../../frontend-common-libs/src/common/userfiles_common';

export default class FilesIotHandler {
  static onIotMessage(entity: any, dispatch: Dispatch<UserFilesActionType, any>) {
    if (entity.action === entityActionEnum.archiveEntity) {
      FilesIotHandler.dispatchFileArchive(entity, dispatch);
    }

    if (entity.action === entityActionEnum.restoreEntity) {
      FilesIotHandler.dispatchFileRestore(entity, dispatch);
    }

    if (EXCLUDED_FILES.includes(entity.type)) return;

    if (entity.action === entityActionEnum.updateEntity) {
      fileUploadOnIotMessage(dispatch, entity);
    }

    if (
      entity.action === entityActionEnum.addEntity &&
      (entity.type === FILETYPE_IN_PROGRESS_PTC_TEMPO_RUN ||
        entity.type === CDGO_SCN ||
        entity.type === CDGO_SSCN ||
        entity.type === CDGO_MSCN ||
        entity.type === CDGO_SMCN)
    ) {
      FilesIotHandler.dispatchEntityAdded(entity, dispatch);
    }
  }

  static dispatchFileArchive(entity: any, dispatch: Dispatch<UserFilesActionType, any>) {
    dispatch({
      type: USERFILES_ARCHIVED,
      payload: { entities: [entity] }
    });
  }

  static dispatchFileRestore(entity: any, dispatch: Dispatch<UserFilesActionType, any>) {
    dispatch({
      type: USERFILES_RESTORED,
      payload: { entities: [entity] }
    });
  }

  static dispatchEntityAdded(entity: any, dispatch: Dispatch<UserFilesActionType, any>) {
    dispatch({
      type: USERFILES_ADDED,
      payload: { entity }
    });
  }
}
