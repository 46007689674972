import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { Map, Seq } from 'immutable';
import Loader from '../../frontend-common-libs/src/components/common/Loader';
import {
  getUserFilesIfNeeded as getUserFilesIfNeededAction,
  onSearchInputChanged as onSearchInputChangedAction,
  searchFiles as searchFilesAction,
  onSearchCleared as onSearchClearedAction,
  archiveUserFiles as archiveUserFilesAction,
  renameFile as renameFileAction,
  generatePcrd as generatePcrdAction,
  moveFileToProject as moveFileToProjectAction
} from '../actions/actions';
import { uploadFiles as uploadFilesAction } from '../../actions/file_upload/fileupload_actions';
import FileTable, { Props as FileTableProps } from '../../components/files/FileTable';
import withDropZone, {
  Props as WithDropZoneProps
} from '../../frontend-common-libs/src/components/files/WithDropZone';
import { ReduxState } from '../../types';
import { SearchToolbar } from '../../components/file_operations/file-search';
import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import { getSelectedProjectId } from '../../project-management';
import UploadHandler from '../../frontend-common-libs/src/file-operations/upload-files/UploadHandler';
import routes from '../../core/routes';
import UploadHandlerRepository from '../../frontend-common-libs/src/file-operations/upload-files/UploadHandlerRepository';
import { ContextBar } from '../../frontend-common-libs/src/components/common/context-bar/ContextBar';
import UploadFilesButton from '../../frontend-common-libs/src/components/common/upload-files/UploadFilesButton';
import { UserFile } from '../../frontend-common-libs/src/common/types';
import MoveFileToProject from '../../project-management/manage-projects/move-file-to-project/MoveFileToProject';
import MoveFileToProjectVm from '../../project-management/manage-projects/move-file-to-project/MoveFileToProjectVm';
import {
  canMoveFileToProject,
  getSelectedProjectName
} from '../../project-management/selectors/selectors';
import FileHistoryVm from '../../frontend-common-libs/src/components/files/file-history/FileHistoryVm';
import { FileHistory } from '../../frontend-common-libs/src/components/files/file-history/FileHistory';

export type Props = {
  isLoading: boolean;
  files: Seq.Indexed<Map<string, Object>>;
  filesSearched: Seq.Indexed<Map<string, Object>>;
  lastSeen: string;
  errorMessage: string;
  staleData: boolean;
  searchText?: string;
  onSearchInputChanged?: (searchText: string) => void;
  isSearching?: boolean;
  getUserFilesIfNeeded: typeof getUserFilesIfNeededAction;
  uploadFiles: typeof uploadFilesAction;
  searchFiles: typeof searchFilesAction;
  onSearchCleared: typeof onSearchClearedAction;
  archiveUserFiles: typeof archiveUserFilesAction;
  renameFile: typeof renameFileAction;
  generatePcrd: typeof generatePcrdAction;
  projectId: ProjectId;
  projectName?: string;
  canMoveFile: boolean;
  moveFileToProject: (entity: UserFile, projectId: string) => any;
};

export type State = { moveFileToProjectVm: MoveFileToProjectVm; fileHistoryVm: FileHistoryVm };

export class UserFilesImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.DroppableFileTable = withDropZone<FileTableProps>(FileTable);
    UploadHandlerRepository.instance.addHandler(
      new UploadHandler(routes.FILE_LIST, props.uploadFiles, 'Upload Files', '.pcrd,.zpcr')
    );
    this.state = {
      moveFileToProjectVm: new MoveFileToProjectVm(props.moveFileToProject),
      fileHistoryVm: new FileHistoryVm()
    };
  }

  DroppableFileTable: ComponentType<FileTableProps & WithDropZoneProps>;

  componentDidMount() {
    const { files, staleData } = this.props;
    if (!files.size || staleData) {
      this.fetchMore();
    }
  }

  componentDidUpdate() {
    const { errorMessage, isLoading, staleData } = this.props;
    if (errorMessage === '' && !isLoading && staleData) this.fetchMore();
  }

  uploadFilesToProject = async (files: File[]) => {
    const { uploadFiles, projectId } = this.props;
    return uploadFiles(files, projectId);
  };

  fetchMore = () => {
    const { getUserFilesIfNeeded, lastSeen, searchText, isSearching } = this.props;
    if (isSearching && searchText) {
      getUserFilesIfNeeded(lastSeen, searchText);
    } else {
      getUserFilesIfNeeded(lastSeen);
    }
  };

  shouldShowEmpty() {
    const { isLoading, errorMessage, files, filesSearched } = this.props;
    return !isLoading && !errorMessage && files.size === 0 && filesSearched.size === 0;
  }

  handleInputChanged = (inputSearchText: string) => {
    const { onSearchInputChanged } = this.props;
    if (onSearchInputChanged) {
      onSearchInputChanged(inputSearchText);
    }
  };

  handleOnSearch = () => {
    const { searchText, searchFiles } = this.props;
    if (searchText && searchFiles) {
      searchFiles(searchText);
    }
  };

  handleOnClearSearch = () => {
    const { onSearchCleared, isSearching } = this.props;
    if (onSearchCleared && isSearching) {
      onSearchCleared();
    }
  };

  renderLoaderIfLoading = () => {
    const { isLoading, lastSeen } = this.props;
    const renderLoader = isLoading && !lastSeen;
    return renderLoader && <Loader />;
  };

  renderEmptySearch = () => {
    const { isLoading, errorMessage, filesSearched, isSearching } = this.props;
    const isSearchEmpty = !isLoading && !errorMessage && filesSearched.size === 0 && isSearching;
    return isSearchEmpty && <div id="empty-search-message">No items found</div>;
  };

  onMoveFile = (file: UserFile) => {
    const { moveFileToProjectVm } = this.state;
    moveFileToProjectVm.showMoveFileToProject(file);
  };

  renderMoveFile = () => {
    const { moveFileToProjectVm } = this.state;
    return <MoveFileToProject moveFileToProjectVm={moveFileToProjectVm} />;
  };

  showFileHistory = (file: UserFile) => {
    const { fileHistoryVm } = this.state;
    fileHistoryVm.showFileHistoryDialog(file);
  };

  renderFileHistory = () => {
    const { fileHistoryVm } = this.state;
    return <FileHistory fileHistoryVm={fileHistoryVm} />;
  };

  renderMenuItemPopups = () => {
    return [this.renderMoveFile(), this.renderFileHistory()];
  };

  renderFileTable = () => {
    const {
      isSearching,
      isLoading,
      files,
      filesSearched,
      errorMessage,
      lastSeen,
      archiveUserFiles,
      renameFile,
      generatePcrd,
      canMoveFile
    } = this.props;

    const moveFile = canMoveFile ? this.onMoveFile : undefined;
    const fileTableCommonProps = {
      isLoading,
      isError: !!errorMessage,
      hasMoreData: !!lastSeen,
      fetchMoreData: this.fetchMore,
      archiveUserFiles,
      renameFile,
      generatePcrd,
      moveFile,
      fileHistory: this.showFileHistory
    };

    if (isSearching) {
      return (
        <>
          <FileTable {...fileTableCommonProps} list={filesSearched || files} />
          {this.renderMenuItemPopups()}
        </>
      );
    }
    const { DroppableFileTable } = this;
    return (
      <>
        <DroppableFileTable
          {...fileTableCommonProps}
          uploadFiles={this.uploadFilesToProject}
          showEmpty={this.shouldShowEmpty()}
          className="flex-column-container"
          list={files}
        />
        {this.renderMenuItemPopups()}
      </>
    );
  };

  render() {
    const { isLoading, staleData, searchText, projectId, projectName } = this.props;
    if (isLoading && staleData) {
      return <Loader />;
    }

    return (
      <>
        <ContextBar
          id="breadcrumb-files"
          titleId="my-files"
          title="Files"
          projectName={projectName}
        >
          <UploadFilesButton selectedProjectId={projectId} />
        </ContextBar>
        <SearchToolbar
          searchText={searchText || ''}
          onSearchInputChanged={this.handleInputChanged}
          onSearch={this.handleOnSearch}
          onClearSearch={this.handleOnClearSearch}
        />
        {this.renderLoaderIfLoading() || this.renderEmptySearch() || this.renderFileTable()}
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  const projectFiles = state.userFiles.getIn(['projects', projectId]) as Map<string, any>;
  return {
    isLoading: projectFiles.get('isLoading'),
    files: projectFiles.get('files').valueSeq(),
    lastSeen: projectFiles.get('lastSeen'),
    errorMessage: projectFiles.get('errorMessage'),
    staleData: projectFiles.get('staleData'),
    searchText: projectFiles.get('searchText'),
    isSearching: projectFiles.get('isSearching'),
    filesSearched: projectFiles.get('filesSearched').valueSeq(),
    projectId,
    projectName: getSelectedProjectName(state),
    canMoveFile: canMoveFileToProject(state)
  };
}

export default connect(mapStateToProps, {
  getUserFilesIfNeeded: getUserFilesIfNeededAction,
  uploadFiles: uploadFilesAction,
  onSearchInputChanged: onSearchInputChangedAction,
  searchFiles: searchFilesAction,
  onSearchCleared: onSearchClearedAction,
  archiveUserFiles: archiveUserFilesAction,
  renameFile: renameFileAction,
  generatePcrd: generatePcrdAction,
  moveFileToProject: moveFileToProjectAction
  // @ts-ignore
})(UserFilesImpl);
