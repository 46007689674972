import React, { Component } from 'react';
import Dropdown, { DropdownProps } from '../../components/common/dropdown/Dropdown';
import NonSelectableMenuItem from '../../components/common/dropdown/NonSelectableMenuItem';
import './styles/select.scss';

type DataProps = Record<string, any>;

export type SelectProps = {
  children: any;
} & DropdownProps &
  DataProps;

export default class Select extends Component<SelectProps> {
  handleSelection = (event: string | symbol) => {
    if (event !== NonSelectableMenuItem.value) {
      const { handleSelection } = this.props;
      if (handleSelection) {
        handleSelection(event);
      }
    }
  };

  render() {
    const { id, children, handleSelection, placeholder, value, disabled, ...rest } = this.props;
    let dataProps: DataProps = {};
    Object.keys(rest).forEach((prop: string) => {
      if (prop.startsWith('data-')) {
        dataProps = { ...dataProps, [prop]: rest[prop] };
      }
    });
    return (
      <Dropdown
        id={id}
        className="br-select"
        handleSelection={this.handleSelection}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        {...dataProps}
      >
        {children}
      </Dropdown>
    );
  }
}
