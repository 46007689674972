import ModalDialogViewModel from '../../../frontend-common-libs/src/components/common/dialogs/ModalDialogViewModel';
import { UserFile } from '../../../frontend-common-libs/src/common/types';
import { Project } from '../../../frontend-common-libs/src/common/project-management-types';

export default class MoveFileToProjectVm extends ModalDialogViewModel {
  protected _projects: Project[] = [];

  public get projects() {
    return this._projects;
  }

  public set projects(projects: Project[]) {
    this.notifyIfChanged('_projects', projects);
  }

  protected _selectedProjectId: string | undefined = undefined;

  public get selectedProjectId() {
    return this._selectedProjectId;
  }

  public set selectedProjectId(projectId: string | undefined) {
    this.notifyIfChanged('_selectedProjectId', projectId);
  }

  public get selectedProjectName() {
    const project = this.projects.find(p => p.id === this.selectedProjectId);
    if (!project) return null;
    return project.name;
  }

  file!: UserFile;

  title = 'Move file to project';

  okBtnText = 'Move';

  protected readonly moveFileToProject: (entity: UserFile, projectId: string) => any;

  constructor(moveFileToProject: (entity: UserFile, projectId: string) => any) {
    super();
    this.moveFileToProject = moveFileToProject;
  }

  onMove = async () => {
    this.saveInProgress = true;
    await this.moveFileToProject(this.file, this.selectedProjectId as string);
    this.saveInProgress = false;
    this.hideDialog();
  };

  showMoveFileToProject(file: UserFile) {
    this.file = file;
    this.selectedProjectId = undefined;
    this.showDialog();
  }
}
