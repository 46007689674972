import React from 'react';
import { Map } from 'immutable';
import styles from './styles/actions.module.scss';
import OpenLid from './assets/icons-instrument-tempo-open-lid.svg';
import CloseLid from './assets/icons-instrument-tempo-close-lid.svg';
import StartRun from './assets/icons-instrument-start-run.svg';
import StopRun from './assets/icons-instrument-stop-run.svg';
import SkipStep from './assets/icons-instrument-skip-step.svg';
import { VerticalIconButton } from '../../../../frontend-common-libs/src/components/common/buttons';
import { ImmutableMap, InstrumentItem } from '../../../../frontend-common-libs/src/common/types';
import InstrumentReservation from '../../InstrumentReservation';
import InstrumentFacade, {
  InstrumentLidStatusEnum,
  InstrumentStatusEnum
} from '../../../common/InstrumentFacade';

export type Props = {
  instruments: ImmutableMap<InstrumentItem>[];
  reservations: Map<string, InstrumentReservation>;
  selectedInstruments: string[];
};

export default function Actions(props: Readonly<Props>) {
  const { instruments, reservations, selectedInstruments } = props;
  const numberOfSelectedInstruments = selectedInstruments.length;

  const getSelectedInstrumentsFacade = () => {
    return selectedInstruments
      .map(selectedInstrumentId => {
        const instrument = instruments.find(
          instrumentItem => instrumentItem.get('id') === selectedInstrumentId
        );
        if (instrument) {
          return new InstrumentFacade(instrument);
        }
        return null;
      })
      .filter(item => item !== null);
  };

  const hasProtocolAssignedFromBrio = (selectedInstrumentsParam: string[]): boolean => {
    return selectedInstrumentsParam.every(selectedInstrument => {
      const reservation = reservations.get(selectedInstrument) as InstrumentReservation;
      return reservation.protocolName !== null;
    });
  };

  const areInstrumentsAbleToSkipStep = (): boolean => {
    let validSelectedInstruments = selectedInstruments.length > 0;

    if (validSelectedInstruments) {
      validSelectedInstruments = hasProtocolAssignedFromBrio(selectedInstruments);
    }

    if (validSelectedInstruments) {
      validSelectedInstruments = getSelectedInstrumentsFacade().every(instrumentFacade => {
        const { instrumentStatus, lidStatus } = instrumentFacade as InstrumentFacade;

        return (
          (instrumentStatus === InstrumentStatusEnum.Running ||
            instrumentStatus === InstrumentStatusEnum.InfiniteHold) &&
          lidStatus !== InstrumentLidStatusEnum.Opened
        );
      });
    }

    return validSelectedInstruments;
  };

  const areInstrumentsAbleToStartRun = (): boolean => {
    let validSelectedInstruments = selectedInstruments.length > 0;

    if (validSelectedInstruments) {
      validSelectedInstruments = getSelectedInstrumentsFacade().every(instrumentFacade => {
        const { instrumentStatus, lidStatus } = instrumentFacade as InstrumentFacade;

        return (
          instrumentStatus === InstrumentStatusEnum.Idle &&
          lidStatus === InstrumentLidStatusEnum.Closed
        );
      });
    }

    if (validSelectedInstruments) {
      validSelectedInstruments = hasProtocolAssignedFromBrio(selectedInstruments);
    }

    return validSelectedInstruments;
  };

  const areInstrumentsAbleToStopRun = (): boolean => {
    let validSelectedInstruments = selectedInstruments.length > 0;

    if (validSelectedInstruments) {
      validSelectedInstruments = hasProtocolAssignedFromBrio(selectedInstruments);
    }

    if (validSelectedInstruments) {
      validSelectedInstruments = getSelectedInstrumentsFacade().every(instrumentFacade => {
        const { instrumentStatus } = instrumentFacade as InstrumentFacade;

        return (
          instrumentStatus === InstrumentStatusEnum.Running ||
          instrumentStatus === InstrumentStatusEnum.InfiniteHold ||
          instrumentStatus === InstrumentStatusEnum.Paused
        );
      });
    }

    return validSelectedInstruments;
  };

  const areInstrumentsAbleToCloseLid = () => {
    return getSelectedInstrumentsFacade().every(instrumentFacade => {
      return (
        instrumentFacade &&
        instrumentFacade.instrumentStatus !== InstrumentStatusEnum.Offline &&
        instrumentFacade.instrumentStatus !== InstrumentStatusEnum.Error &&
        instrumentFacade.lidStatus !== InstrumentLidStatusEnum.Closed
      );
    });
  };

  const areInstrumentsAbleToOpenLid = () => {
    return getSelectedInstrumentsFacade().every(instrumentFacade => {
      return (
        instrumentFacade &&
        instrumentFacade.instrumentStatus !== InstrumentStatusEnum.Offline &&
        instrumentFacade.instrumentStatus !== InstrumentStatusEnum.Error &&
        instrumentFacade.lidStatus !== InstrumentLidStatusEnum.Opened
      );
    });
  };

  const startRunAllowed = areInstrumentsAbleToStartRun();
  const stopRunAllowed = areInstrumentsAbleToStopRun();
  const skipStepAllowed = areInstrumentsAbleToSkipStep();
  const closeLidAllowed = selectedInstruments.length > 0 && areInstrumentsAbleToCloseLid();
  const openLidAllowed = selectedInstruments.length > 0 && areInstrumentsAbleToOpenLid();

  const handleStartRun = async () => {
    const promises: Promise<void>[] = [];
    selectedInstruments.forEach(selectedInstrument => {
      const reservation = reservations.get(selectedInstrument) as InstrumentReservation;
      promises.push(reservation.startRun());
    });

    await Promise.all(promises);
  };

  const handleCloseLid = async () => {
    if (closeLidAllowed) {
      const closeLidPromises = selectedInstruments.map(selectedInstrument => {
        const reservation = reservations.get(selectedInstrument);
        return reservation?.instrumentCloseLid();
      });
      await Promise.all(closeLidPromises);
    }
  };

  const handleOpenLid = async () => {
    if (openLidAllowed) {
      const openLidPromises = selectedInstruments.map(selectedInstrument => {
        const reservation = reservations.get(selectedInstrument);
        return reservation?.instrumentOpenLid();
      });
      await Promise.all(openLidPromises);
    }
  };

  const handleStopRun = async () => {
    const promises: Promise<void>[] = [];
    selectedInstruments.forEach(selectedInstrument => {
      const reservation = reservations.get(selectedInstrument) as InstrumentReservation;
      promises.push(reservation.stopRun());
    });

    await Promise.all(promises);
  };

  const handleSkipStep = async () => {
    const promises: Promise<void>[] = [];
    selectedInstruments.forEach(selectedInstrument => {
      const reservation = reservations.get(selectedInstrument) as InstrumentReservation;
      promises.push(reservation.skipStep());
    });

    await Promise.all(promises);
  };

  return (
    <section className={styles.actions}>
      <h1>Actions</h1>
      <div className={styles.counter} data-testid="counter">
        ({numberOfSelectedInstruments}) Selected Instruments
      </div>
      <div className={styles.lidButtons}>
        <VerticalIconButton
          id="open-lid-button"
          iconSrc={OpenLid}
          alt="Open Lid"
          title={
            openLidAllowed
              ? 'Open Lid'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={styles.button}
          onClick={handleOpenLid}
          disabled={!openLidAllowed}
        >
          Open Lid
        </VerticalIconButton>
        <VerticalIconButton
          id="close-lid-button"
          iconSrc={CloseLid}
          alt="Close Lid"
          title={
            closeLidAllowed
              ? 'Close Lid'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={styles.button}
          onClick={handleCloseLid}
          disabled={!closeLidAllowed}
        >
          Close Lid
        </VerticalIconButton>
      </div>
      <hr />
      <div className={styles.actionButtons}>
        <VerticalIconButton
          id="start-button"
          iconSrc={StartRun}
          alt="Start Run"
          title={
            startRunAllowed
              ? 'Start Run'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={styles.button}
          onClick={handleStartRun}
          disabled={!startRunAllowed}
        >
          Start
        </VerticalIconButton>
        <VerticalIconButton
          id="skip-button"
          iconSrc={SkipStep}
          alt="Skip Step"
          title={
            skipStepAllowed
              ? 'Skip Step'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={styles.button}
          onClick={handleSkipStep}
          disabled={!skipStepAllowed}
        >
          Skip
        </VerticalIconButton>
        <VerticalIconButton
          id="stop-button"
          iconSrc={StopRun}
          alt="Stop Run"
          title={
            stopRunAllowed
              ? 'Stop Run'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={`${styles.button} ${styles.stopButton}`}
          onClick={handleStopRun}
          disabled={!stopRunAllowed}
        >
          Stop
        </VerticalIconButton>
      </div>
    </section>
  );
}
