import { Map } from 'immutable';
import {
  PROTOCOL_TEMPLATES_LOADING,
  PROTOCOL_TEMPLATES_LOADED,
  PROTOCOL_TEMPLATES_ERROR,
  PROTOCOL_TEMPLATES_RENAMED,
  PROTOCOL_TEMPLATES_DELETED,
  PROTOCOL_TEMPLATES_ARCHIVED
} from './protocolTemplate_types';
import {
  getEntity,
  putEntity,
  deleteEntities,
  archiveEntities,
  getEntitiesOfProject,
  moveEntity
} from '../frontend-common-libs/src/api/entities';
import {
  errorCode,
  commonErrorMessage,
  isNetworkError
} from '../frontend-common-libs/src/utils/errorUtils';

import { ProtocolTemplatesActionType } from './protocolTemplate_types';
import { Dispatch, GetState } from '../types';
import { PROTOCOL_ENTITY_TYPE } from '../frontend-common-libs/src/common/protocol_common';
import { getSelectedProjectId } from '../project-management';
import { ProjectId } from '../frontend-common-libs/src/common/project-management-types';
import { getProtocol } from '../api/protocols';
import { downloadPrclFile } from '../frontend-common-libs/src/components/pcr/export-protocol/export-prcl';
import {
  FileOperationActionType,
  EXPORT_FILE_COMPLETE,
  EXPORT_FILE_FAILED,
  EXPORT_FILE_STARTED
} from '../frontend-common-libs/src/file-operations/file_operation_types';
import { getNextUploadId } from '../frontend-common-libs/src/file-operations/utils';
import QpcrProtocolUpload from '../components/pcr/upload-protocol/QpcrProtocolUpload';
import { DownloadNetworkError } from '../frontend-common-libs/src/file-operations/messages';
import {
  TrackedFilesEvents,
  TrackedOrganizationEvents,
  trackEvent
} from '../frontend-common-libs/src/user-analytics/trackedEvents';
import notification from '../frontend-common-libs/src/utils/notifications';
import { UserFile } from '../frontend-common-libs/src/common/types';

type DispatchType = Dispatch<ProtocolTemplatesActionType, Record<string, any> | string>;

function getProtocolTemplates(lastSeen: string, projectId: ProjectId) {
  return async (dispatch: DispatchType) => {
    dispatch({
      type: PROTOCOL_TEMPLATES_LOADING,
      payload: { projectId }
    });

    try {
      const query = {
        type: PROTOCOL_ENTITY_TYPE,
        lastSeen
      };
      const payload = await getEntitiesOfProject(query, projectId);

      dispatch({
        type: PROTOCOL_TEMPLATES_LOADED,
        payload: { ...payload, projectId }
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      dispatch({
        type: PROTOCOL_TEMPLATES_ERROR,
        payload: { message, projectId }
      });
    }
  };
}

export default function getProtocolTemplatesIfNeeded(lastSeen: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    const projectId = getSelectedProjectId(state);
    if (!state.protocolTemplates.getIn(['projects', projectId, 'isLoading'])) {
      await dispatch(getProtocolTemplates(lastSeen, projectId));
    }
  };
}

export async function getProtocolTemplate(entityId: string) {
  try {
    const result = await getEntity(entityId);
    return result.data;
  } catch (ex) {
    const status = errorCode(ex);
    if (status === 404) {
      throw Error(commonErrorMessage(404, 'Protocol'));
    }
    throw Error('Load failed. Check your network connection.');
  }
}

export function deleteProtocolTemplates(entityIdList: Array<string>) {
  return async (
    dispatch: Dispatch<ProtocolTemplatesActionType, Record<string, any>>,
    getState: GetState
  ) => {
    const state = getState();
    const projectId = getSelectedProjectId(state);
    try {
      await deleteEntities(entityIdList);
      dispatch({
        type: PROTOCOL_TEMPLATES_DELETED,
        payload: { fileList: entityIdList, projectId }
      });
    } catch (error) {
      throw Error('Delete failed. Check your network connection.');
    }
  };
}

export function archiveProtocolTemplates(entityIdList: string[]) {
  return async (dispatch: Dispatch<ProtocolTemplatesActionType, Record<string, any>>) => {
    try {
      const res = await archiveEntities(entityIdList);
      const { entities } = res.data;
      dispatch({
        type: PROTOCOL_TEMPLATES_ARCHIVED,
        payload: { entities }
      });
    } catch (error) {
      throw Error('Archive failed. Check your network connection.');
    }
  };
}

export function renameProtocolTemplate(entityId: string, newName: string) {
  return async (
    dispatch: Dispatch<ProtocolTemplatesActionType, Record<string, any>>,
    getState: GetState
  ) => {
    const state = getState();
    const projectId = getSelectedProjectId(state);
    try {
      const res = await putEntity(entityId, {
        name: newName
      });
      const faEntity = res.data;
      dispatch({
        type: PROTOCOL_TEMPLATES_RENAMED,
        payload: {
          id: entityId,
          faEntity
        }
      });
    } catch (ex) {
      const status = errorCode(ex);

      if (status === 404) {
        dispatch({
          type: PROTOCOL_TEMPLATES_DELETED,
          payload: { fileList: [entityId], projectId }
        });
        throw Error(commonErrorMessage(404, 'Protocol'));
      }
      throw Error('Edit failed. Check your network connection.');
    }
  };
}

export function moveCfxProtocolToProject(entity: UserFile, projectId: string) {
  return async (dispatch: DispatchType) => {
    try {
      const faEntity = await moveEntity(entity, projectId);
      dispatch({
        type: PROTOCOL_TEMPLATES_DELETED,
        payload: { fileList: [entity.id], projectId: entity.parent_id }
      });
      dispatch({
        type: PROTOCOL_TEMPLATES_RENAMED,
        payload: {
          id: faEntity.id,
          faEntity
        }
      });
      trackEvent(TrackedOrganizationEvents.MoveFileToProject, {
        entityName: entity.name,
        fileType: entity.type,
        projectId
      });
    } catch (ex) {
      notification.error('Error moving protocol to project');
    }
  };
}

export async function exportPrcl(fileEntitiy: Map<string, any>) {
  const entityId = fileEntitiy.get('id');
  const protocolId = fileEntitiy.getIn(['meta', 'qpcrProtocol', 'protocol']) as string;
  const res = await getProtocol(entityId, protocolId, true);
  const rundef = res.data;
  downloadPrclFile(fileEntitiy.get('name'), rundef);
}

export function exportProtocol(fileEntitiy: Map<string, any>) {
  return async (dispatch: Dispatch<FileOperationActionType, Record<string, any>>) => {
    const fileOprationId = getNextUploadId();
    try {
      const fileName = fileEntitiy.get('name');
      dispatch({
        type: EXPORT_FILE_STARTED,
        payload: {
          id: fileOprationId,
          fileName
        }
      });

      await exportPrcl(fileEntitiy);

      dispatch({
        type: EXPORT_FILE_COMPLETE,
        payload: {
          id: fileOprationId
        }
      });
      trackEvent(TrackedFilesEvents.CfxExportProtocol, { fileName });
    } catch (ex) {
      if (isNetworkError(ex as Error)) {
        dispatch({
          type: EXPORT_FILE_FAILED,
          payload: {
            id: fileOprationId,
            errorMessage: DownloadNetworkError
          }
        });
        return;
      }
      dispatch({
        type: EXPORT_FILE_FAILED,
        payload: {
          id: fileOprationId
        }
      });
    }
  };
}

export function uploadProtocols(files: File[], projectId: ProjectId) {
  return async (dispatch: DispatchType) => {
    for (let i = 0; i < files.length; i += 1) {
      const protocolUpload = new QpcrProtocolUpload(files[i], projectId, dispatch);
      protocolUpload.start();
    }
  };
}
