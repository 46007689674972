import React, { KeyboardEvent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ModalDialog from '../../../frontend-common-libs/src/components/common/dialogs/ModalDialog';
import MoveFileToProjectVm from './MoveFileToProjectVm';
import {
  Project,
  USER_FILES
} from '../../../frontend-common-libs/src/common/project-management-types';
import '../../styles/move-file.scss';
import projectIcon from '../../../../img/folder.svg';
import warningIcon from '../../../settings-page/img/warning-icon.svg';
import { ReduxState } from '../../../types';
import { getProjects } from '../../selectors/selectors';
import { getActiveOrg } from '../../../organization-management/selectors/selectors';
import { modalStyleV2 } from '../../../frontend-common-libs/src/components/common/dialogs/custom-modal-styles';
import { ConnectViewModel } from '../../../frontend-common-libs/src/common/mvvm/ConnectViewModel';

export type Props = {
  moveFileToProjectVm: MoveFileToProjectVm;
  projects?: Project[];
};

export class MoveFileToProjectImpl extends React.Component<Props> {
  componentDidUpdate() {
    const { projects, moveFileToProjectVm } = this.props;
    if (!projects || projects === moveFileToProjectVm.projects) return;
    moveFileToProjectVm.projects = projects;
  }

  renderProjectRow(project: Project) {
    const { moveFileToProjectVm } = this.props;
    const selectProject = () => {
      moveFileToProjectVm.selectedProjectId = project.id;
    };
    return (
      <div
        onClick={() => {
          selectProject();
        }}
        className={classNames('project-row', {
          'project-row-selected': moveFileToProjectVm.selectedProjectId === project.id
        })}
        role="button"
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent) => {
          if (event.key === 'Spacebar' || event.key === ' ') selectProject();
        }}
        key={project.id}
        id={project.name}
      >
        <img src={projectIcon} alt="project" className="project-icon" />
        <span className="project-name with-ellipsis" title={project.name}>
          {project.name}
        </span>
      </div>
    );
  }

  render() {
    const { moveFileToProjectVm } = this.props;
    const {
      title,
      okBtnText,
      shouldBlock,
      onCancel,
      onMove,
      projects,
      file,
      selectedProjectName,
      saveInProgress
    } = moveFileToProjectVm;
    if (!shouldBlock) return null;
    const topRow = `${USER_FILES} > ${file.name}`;
    const customModalStyle = { ...modalStyleV2, modalStyle: 'move-file-modal' };
    return (
      <ModalDialog
        show={shouldBlock}
        title={title}
        onSuccess={onMove}
        onCancel={onCancel}
        okBtnText={okBtnText}
        okBtnDisabled={moveFileToProjectVm.selectedProjectId == null}
        customModalStyle={customModalStyle}
        inProgress={saveInProgress}
      >
        <div className="move-file-panel">
          <span className="with-ellipsis top-row" title={topRow}>
            {topRow}
          </span>
          <div className="project-list">
            {projects.map((project: Project) => this.renderProjectRow(project))}
          </div>
          <div className="warn-msg">
            {selectedProjectName && (
              <>
                <img src={warningIcon} alt="alert" className="warn-icon" />
                <div id="warning-msg">
                  {`After you move the file to a project, you cannot return it to ${USER_FILES}. Click Move to move the file or Cancel to close the dialog.`}
                </div>
              </>
            )}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

export function mapStateToProps(state: ReduxState) {
  return {
    // @ts-ignore
    projects: getProjects(state, getActiveOrg(state)).toJS()
  };
}

export function MoveFileToProjectView(props: Readonly<Props>) {
  const { moveFileToProjectVm } = props;
  return (
    <ConnectViewModel vm={moveFileToProjectVm} vmPropKey="moveFileToProjectVm">
      <MoveFileToProjectImpl {...props} />
    </ConnectViewModel>
  );
}

// @ts-ignore
export default connect(mapStateToProps, {})(MoveFileToProjectView);
