import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { PluginComponents, PluginManifest } from '@biorad-lsg-tsc/instrument-family-plugin';
import Loader from '../frontend-common-libs/src/components/common/Loader';
import RealTimePCRPlugin from '../real-time-pcr';
import InstrumentFamilyPluginRepository from './InstrumentFamilyPluginRepository';
import coreRoutes from '../core/routes';
import conventionalPcrRoutes from '../conventional-pcr-frontend/routes';
import LocalInstrumentFamilyPlugin from './LocalInstrumentFamilyPlugin';
import {
  InstrumentRow,
  InstrumentTile,
  Router as ConventionalPCRRouter,
  PageTitle as PTCTempoPageTitle,
  HelpLink as ConventionalPCRHelpLink,
  FleetManagement as PTCTempoFleetManagement
} from '../conventional-pcr-frontend';
import PTCTempoFileRow from '../conventional-pcr-frontend/files/PTCTempoFileRow';
import CpcrMsgsSubscriber from '../conventional-pcr-frontend/communication/CpcrMsgsSubscriber';
import { getSelectedProjectId } from '../project-management';
import { ReduxState } from '../types';
import PTCTempoRecentFileRow from '../conventional-pcr-frontend/recent-files/PTCTempoRecentFileRow';
import PTCTempoArchiveRow from '../conventional-pcr-frontend/archive/PTCTempoArchiveRow';
import PTCTempoUserPreferencesDetails from '../conventional-pcr-frontend/user-preferences/PTCTempoUserPreferencesDetails';
import FeatureFlags, {
  FeatureFlagKeys
} from '../frontend-common-libs/src/components/feature_flags';
import RemoteInstrumentFamilyPlugin from './RemoteInstrumentFamilyPlugin';
import RemotePluginManifest from './RemotePluginManifest';
import { API_DOMAIN } from '../frontend-common-libs/src/config/config';
import { InstrumentTypeEnum } from './types';
import { getSelectedProjectName } from '../project-management/selectors/selectors';

export type Props = {
  children: any;
  onPluginsInitialized: () => void;
};

type State = {
  isLoading: boolean;
};

export default class InstrumentFamilyPluginRepositoryInitializer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  private readonly instrumentFamilyPluginLocation = `https://www.${API_DOMAIN}/plugins/instrument-family`;

  componentDidMount() {
    const hasNotBeenInitialized =
      InstrumentFamilyPluginRepository.getInstance().getAll().length === 0;

    if (hasNotBeenInitialized) {
      this.initializePlugins();
    } else {
      const { onPluginsInitialized } = this.props;
      this.setState(
        {
          isLoading: false
        },
        onPluginsInitialized
      );
    }
  }

  initializePlugins = async (): Promise<void> => {
    const pluginRepository = InstrumentFamilyPluginRepository.getInstance();

    const realTimePCRPlugin = new RealTimePCRPlugin();
    pluginRepository.add(realTimePCRPlugin);

    const pluginManifestOverrideUrl = FeatureFlags().get(
      FeatureFlagKeys.PLUGIN_MANIFEST_OVERRIDE_URL
    );

    let pluginOverrideName;
    if (pluginManifestOverrideUrl) {
      const pluginManifestOverride = await RemotePluginManifest.create(pluginManifestOverrideUrl);
      if (pluginManifestOverride) {
        pluginOverrideName = pluginManifestOverride.name;
        pluginRepository.add(new RemoteInstrumentFamilyPlugin(pluginManifestOverride));
      }
    }

    if (FeatureFlags().get(FeatureFlagKeys.CONVENTIONAL_PCR_MICRO_FRONTEND)) {
      const remoteConventionalPCRPlugin = await this.createRemoteConventionalPCRPlugin();
      if (remoteConventionalPCRPlugin && remoteConventionalPCRPlugin.name !== pluginOverrideName) {
        pluginRepository.add(remoteConventionalPCRPlugin);
      }
    } else {
      const conventionalPCRPlugin = await this.createConventionalPCRPlugin();
      pluginRepository.add(conventionalPCRPlugin);
    }

    const remoteImageLabTouchPlugin = await this.createRemoteImageLabTouchPlugin();
    if (remoteImageLabTouchPlugin && remoteImageLabTouchPlugin.name !== pluginOverrideName) {
      pluginRepository.add(remoteImageLabTouchPlugin);
    }

    const { onPluginsInitialized } = this.props;
    this.setState(
      {
        isLoading: false
      },
      onPluginsInitialized
    );
  };

  createRemoteConventionalPCRPlugin = async (): Promise<
    RemoteInstrumentFamilyPlugin | undefined
  > => {
    const conventionalPluginManifestUrl = `${this.instrumentFamilyPluginLocation}/conventional-pcr/plugin-manifest.json`;

    return this.retrieveRemoteManifest(conventionalPluginManifestUrl);
  };

  createRemoteImageLabTouchPlugin = async (): Promise<RemoteInstrumentFamilyPlugin | undefined> => {
    const imageLabTouchPluginManifestUrl = `${this.instrumentFamilyPluginLocation}/image-lab-touch/plugin-manifest.json`;

    return this.retrieveRemoteManifest(imageLabTouchPluginManifestUrl);
  };

  // eslint-disable-next-line class-methods-use-this
  private async retrieveRemoteManifest(
    remotePluginManifestUrl: string
  ): Promise<RemoteInstrumentFamilyPlugin | undefined> {
    const remotePluginManifest = await RemotePluginManifest.create(remotePluginManifestUrl);

    return remotePluginManifest
      ? new RemoteInstrumentFamilyPlugin(remotePluginManifest)
      : undefined;
  }

  createConventionalPCRPlugin = async (): Promise<LocalInstrumentFamilyPlugin> => {
    const conventionalPCRManifest: PluginManifest = {
      name: 'ptc-tempo',
      basePath: '/pcr',
      instrumentTypes: [InstrumentTypeEnum.ptcTempo],
      createExperimentCard: {
        title: 'Conventional PCR (PTC Tempo)',
        instrumentThumbnail: '/img/tempo-instrument.png',
        primaryLinks: [],
        secondaryLinks: [
          {
            id: 'create-protocol-link',
            displayText: 'Create New PTC Protocol',
            path: `${coreRoutes.APP}${conventionalPcrRoutes.CREATE_PCR_PROTOCOL}`
          },
          {
            id: 'manage-protocols-link',
            displayText: 'Manage PTC Protocols',
            path: `${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`
          }
        ]
      }
    };
    const ptcMsgSubscriber = new CpcrMsgsSubscriber();
    ptcMsgSubscriber.registerEvent();
    const conventionalPCRComponents: PluginComponents = {
      app: InstrumentFamilyPluginRepositoryInitializer.connectRouter(ConventionalPCRRouter),
      instrumentTile: InstrumentTile,
      instrumentRow: InstrumentRow,
      fleetManagement: PTCTempoFleetManagement,
      fileRow: PTCTempoFileRow,
      recentFileRow: PTCTempoRecentFileRow,
      archiveRow: PTCTempoArchiveRow,
      userPreferenceDetail: PTCTempoUserPreferencesDetails,
      pageTitle: PTCTempoPageTitle,
      helpLink: ConventionalPCRHelpLink
    };

    return LocalInstrumentFamilyPlugin.create(conventionalPCRManifest, conventionalPCRComponents);
  };

  static connectRouter(router: ComponentType<any>) {
    function mapStateToProps(state: ReduxState): Record<string, any> {
      return {
        selectedProjectId: getSelectedProjectId(state),
        selectedProjectName: getSelectedProjectName(state)
      };
    }
    return connect(mapStateToProps)(router);
  }

  render() {
    const { children } = this.props;
    const { isLoading } = this.state;

    return isLoading ? <Loader /> : children;
  }
}
