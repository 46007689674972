import { ComponentType } from 'react';
import {
  InstrumentType,
  PluginCreateExperimentCardProperties,
  PluginName
} from '@biorad-lsg-tsc/instrument-family-plugin';
import coreRoutes from '../core/routes';
import realTimePcrRoutes from './routes';
import { InstrumentFamilyPlugin, InstrumentTypeEnum } from '../instrument-family-plugin';
import App from './App';
import RealTimePCRInstrumentTile from './instrument-tile/RealTimePCRInstrumentTile';
import RealTimeInstrumentRow from './instrument-row/RealTimeInstrumentRow';
import CFXOpusFileRow from './files/CFXOpusFileRow';
import CFXOpusRecentFileRow from './recent-files/CFXOpusRecentFileRow';
import CFXOpusArchiveRow from './archive/CFXOpusArchiveRow';
import CFXOpusUserPreferencesDetails from './user-preferences/CFXOpusUserPreferencesDetails';
import RealTimePageTitle from './page-title/RealTimePageTitle';
import HelpLink from './help-links';
import PluginCreateExperimentCard from '../instrument-family-plugin/PluginCreateExperimentCard';

export default class RealTimePCRPlugin implements InstrumentFamilyPlugin {
  public readonly basePath = '/qpcr';

  public readonly name: PluginName = 'opus';

  public readonly instrumentTypes: InstrumentType[] = [
    InstrumentTypeEnum.c1000Touch,
    InstrumentTypeEnum.cfx3G
  ];

  // eslint-disable-next-line class-methods-use-this
  public get createExperimentCard(): PluginCreateExperimentCard {
    const createExperimentCardProperties: PluginCreateExperimentCardProperties = {
      title: 'Real-Time PCR (CFX Opus)',
      instrumentThumbnail: '/img/opus-instrument.png',
      primaryLinks: [
        {
          id: 'create-run-link',
          displayText: 'Create New CFX Run',
          path: `${coreRoutes.APP}${realTimePcrRoutes.CREATE_QPCR_RUN_PROTOCOL}`
        },
        {
          id: 'create-run-from-template',
          displayText: 'Create New CFX Run from Template',
          path: `${coreRoutes.APP}${realTimePcrRoutes.CREATE_QPCR_RUN_FROM_TEMPLATE}`
        }
      ],
      secondaryLinks: [
        {
          id: 'create-protocol-link',
          displayText: 'Create New CFX Protocol',
          path: `${coreRoutes.APP}${realTimePcrRoutes.CREATE_QPCR_PROTOCOL}`
        },
        {
          id: 'manage-protocols-link',
          displayText: 'Manage CFX Protocols',
          path: `${coreRoutes.APP}${realTimePcrRoutes.QPCR_PROTOCOL_LIST}`
        }
      ]
    };
    return new PluginCreateExperimentCard(createExperimentCardProperties);
  }

  // eslint-disable-next-line class-methods-use-this
  public createInstrumentThumbnailUrl(instrumentThumbnailRelativePath: string): string {
    return instrumentThumbnailRelativePath;
  }

  // eslint-disable-next-line class-methods-use-this
  public getInstrumentTile(): ComponentType<any> {
    return RealTimePCRInstrumentTile;
  }

  // eslint-disable-next-line class-methods-use-this
  public getApp(): ComponentType<any> {
    return App;
  }

  // eslint-disable-next-line class-methods-use-this
  public getInstrumentRow(): ComponentType<any> {
    return RealTimeInstrumentRow;
  }

  // eslint-disable-next-line class-methods-use-this
  public getFileRow(): ComponentType<any> {
    return CFXOpusFileRow;
  }

  // eslint-disable-next-line class-methods-use-this
  public getRecentFileRow(): ComponentType<any> {
    return CFXOpusRecentFileRow;
  }

  // eslint-disable-next-line class-methods-use-this
  public getArchiveRow(): ComponentType<any> {
    return CFXOpusArchiveRow;
  }

  // eslint-disable-next-line class-methods-use-this
  public getUserPreferenceDetail(): ComponentType<any> {
    return CFXOpusUserPreferencesDetails;
  }

  // eslint-disable-next-line class-methods-use-this
  public getPageTitle(): ComponentType<any> {
    return RealTimePageTitle;
  }

  // eslint-disable-next-line class-methods-use-this
  public getHelpLink(): ComponentType<any> {
    return HelpLink;
  }

  // eslint-disable-next-line class-methods-use-this
  public getFleetManagement(): ComponentType<any> {
    // @ts-ignore
    return null;
  }
}
