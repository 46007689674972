import React from 'react';
import HelpCenter from '../../../utils/HelpCenter';
import HelpPageViewedTrackingEvent from '../../../frontend-common-libs/src/user-analytics/HelpPageViewedTrackingEvent';
import CommonMenuItemLink from '../../../frontend-common-libs/src/components/common-help-link/CommonMenuItemLink';

type Props = {
  id: string;
  className: string;
  helpPath: string;
};

export default function CoreHelpCenterLink({ id, className, helpPath }: Props) {
  const toRoute = new HelpCenter().helpUrl(helpPath);

  const onSelection = () => {
    new HelpPageViewedTrackingEvent(toRoute).track();
  };

  return (
    <CommonMenuItemLink
      id={id}
      className={className}
      toRoute={toRoute}
      label="Help Center"
      onSelection={onSelection}
    />
  );
}
