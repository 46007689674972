import { DispatchType, PROJECT_TOKEN_LOADED, PROJECT_TOKEN_LOADING } from './action-types';
import { getProjectAccessToken as getProjectAccessTokenApi } from '../api/project-api';
import { GetState } from '../../types';
import { getActiveOrg } from '../../organization-management/selectors/selectors';
import { isProjectTokenLoading, getProjectUserToken } from '../selectors/selectors';
import { CbWithToken } from '../../organization-management/actions/WithRefreshToken';
import { withRefreshOrgToken } from '../../organization-management/actions/with-refresh-org-token';

export function getProjectToken(projectId: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    const orgId = getActiveOrg(state);
    dispatch({
      type: PROJECT_TOKEN_LOADING,
      payload: { projectId }
    });
    const getProjectAccessTokenCb: CbWithToken = async (accessToken: string) => {
      return getProjectAccessTokenApi(projectId, accessToken);
    };
    const loginInfo = await withRefreshOrgToken(dispatch, getState, orgId, getProjectAccessTokenCb);
    dispatch({
      type: PROJECT_TOKEN_LOADED,
      payload: { projectId, loginInfo }
    });
  };
}

export function getProjectTokenIfNeeded(projectId: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    const isProjectTokenLoaded = getProjectUserToken(state, projectId) != null;
    if (!isProjectTokenLoaded && !isProjectTokenLoading(state, projectId)) {
      await getProjectToken(projectId)(dispatch, getState);
    }
  };
}
