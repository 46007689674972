import AWSIot from 'aws-iot-device-sdk';
import IOTCredentialsProvider from '../../../frontend-common-libs/src/iot/IOTCredentialsProvider';
import CredentialsRetryProvider from '../../../frontend-common-libs/src/iot/credentials/credentials-retry-provider';
import InstrumentGroupCredentials from '../../../frontend-common-libs/src/iot/instrument-group-credentials';
import { IOT_MESSAGE_BROKER } from '../../../frontend-common-libs/src/config/config';
import { guid } from '../../../frontend-common-libs/src/utils/commonUtils';

type InstrumentWebSocket = {
  instrumentId: string;
  created: number;
  ws: AWSIot.thingShadow;
};

export default class ReservationShadowWebsocketRepository {
  private static _instance: ReservationShadowWebsocketRepository | undefined = undefined;

  private instrumentWebsocket: InstrumentWebSocket[] = [];

  public static get instance(): ReservationShadowWebsocketRepository {
    if (this._instance == null) this._instance = new ReservationShadowWebsocketRepository();
    return this._instance;
  }

  public findInstrument(instrumentId: string): InstrumentWebSocket | undefined {
    return this.instrumentWebsocket.find(instrument => instrument.instrumentId === instrumentId);
  }

  public removeInstrument(instrumentId: string): InstrumentWebSocket[] {
    const instrumentSocketObj = this.findInstrument(instrumentId);

    if (instrumentSocketObj !== undefined) {
      instrumentSocketObj.ws.end();
    }
    return this.instrumentWebsocket.filter(instrument => instrument.instrumentId !== instrumentId);
  }

  public async getInstrumentWebsocket(instrumentId: string): Promise<AWSIot.thingShadow> {
    const instrumentSocket = this.findInstrument(instrumentId);

    if (instrumentSocket === undefined) {
      return this.createInstrumentWebsocket(instrumentId);
    }

    const websocketExpired = ReservationShadowWebsocketRepository.isWsExpired(
      instrumentSocket.created
    );
    if (websocketExpired) {
      this.instrumentWebsocket = this.removeInstrument(instrumentId);
      return this.createInstrumentWebsocket(instrumentId);
    }

    return instrumentSocket.ws;
  }

  public async closeInstrumentWebsocket(instrumentId: string): Promise<void> {
    const instrumentSocket = this.findInstrument(instrumentId);
    instrumentSocket?.ws.end();
    this.instrumentWebsocket = this.instrumentWebsocket.filter(
      instrument => instrument.instrumentId !== instrumentId
    );
  }

  public static isWsExpired(created: number): boolean {
    const oneHourInMs = 60 * 60 * 1000;
    const now = Date.now();
    const timeDifference = now - created;
    return timeDifference >= oneHourInMs;
  }

  private setInstrumentWebsocket(
    instrumentId: string,
    created: number,
    ws: AWSIot.thingShadow
  ): void {
    const instrumentSocket = this.findInstrument(instrumentId);

    if (instrumentSocket === undefined) {
      this.instrumentWebsocket.push({ instrumentId, created, ws });
    }
  }

  private static async getCredentials(instrumentId: string): Promise<InstrumentGroupCredentials> {
    const iotCredentialsProvider: IOTCredentialsProvider = new IOTCredentialsProvider();
    const credentialsRetryProvider = new CredentialsRetryProvider(iotCredentialsProvider, 1);
    const credentials = await credentialsRetryProvider.resolve();
    const { instrumentGroupCredentialsCollection } = credentials;
    return instrumentGroupCredentialsCollection.find((groupCredentials: any) => {
      const instrumentGroupCredentials = new InstrumentGroupCredentials(groupCredentials);
      return instrumentGroupCredentials.isInstrumentInGroup(instrumentId);
    });
  }

  private async createInstrumentWebsocket(instrumentId: string): Promise<AWSIot.thingShadow> {
    const instrumentCredentials = await ReservationShadowWebsocketRepository.getCredentials(
      instrumentId
    );

    // @ts-ignore
    const thingShadow = AWSIot.thingShadow({
      host: IOT_MESSAGE_BROKER.ENDPOINT,
      clientId: guid(),
      protocol: 'wss',
      maximumReconnectTimeMs: 8000,
      accessKeyId: instrumentCredentials.accessKeyId,
      secretKey: instrumentCredentials.secretKey,
      sessionToken: instrumentCredentials.sessionToken
    });
    const created = Date.now();

    this.setInstrumentWebsocket(instrumentId, created, thingShadow);
    return thingShadow;
  }
}
