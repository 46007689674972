import React from 'react';

type Props = {
  className?: string;
};

const Spinner = ({ className }: Props) => (
  <i className={`fa fa-spinner fa-spin ${className || ''}`} id="loading-screen-spinner" />
);

Spinner.defaultProps = {
  className: undefined
};

export default Spinner;
