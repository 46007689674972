import mixpanel, { Config } from 'mixpanel-browser';
import FeatureFlags, { FeatureFlagKeys } from '../components/feature_flags';
import getEnvironmentConfig from '../config/EnvironmentConfiguration';
import AnonymizedString from '../utils/AnonymizedString';
import EmailAddress from '../common/EmailAddress';

const tokens: { [key: string]: string } = {
  localhost: 'e85187b4d31ad0eeae5ceb6c01c75af2',
  dev: 'e85187b4d31ad0eeae5ceb6c01c75af2',
  staging: '7fa93c4c1a00e982a042ac805ac8915f',
  prod: '2d2a941d8870750c5ee22988bf82206c'
};

enum TrackingEnv {
  SQA_MIXPANEL = 'sqa_mixpanel',
  SQA = 'sqa',
  SQA_MANUAL = 'sqa_manual',
  DEFAULT = 'default'
}

export default class UserAnalyticsClient {
  constructor() {
    const config: Partial<Config> = { secure_cookie: true };
    mixpanel.init(UserAnalyticsClient.getToken(), config);
    UserAnalyticsClient.setTrackingEnvironment(FeatureFlags().get(FeatureFlagKeys.TRACKING_ENV));
  }

  private static getToken(): string {
    const { isLocalhost, env } = getEnvironmentConfig();
    if (isLocalhost) {
      return tokens.localhost;
    }
    return tokens[env] || tokens.prod;
  }

  private static setTrackingEnvironment(trackingEnvironment: string | Array<string>): void {
    if (trackingEnvironment === TrackingEnv.SQA_MIXPANEL) {
      // add 'super' property to mixpanel obj
      // this property will be included in all future tracking events until
      // mixpanel browser cookie is cleared or tracking_env is set to default
      // 'sqa_mixpanel' is used for manual testing mixpanel
      mixpanel.register({ trackingEnv: trackingEnvironment });
    } else if (
      getEnvironmentConfig().isLocalhost ||
      getEnvironmentConfig().env !== 'prod' ||
      trackingEnvironment === TrackingEnv.SQA ||
      trackingEnvironment === TrackingEnv.SQA_MANUAL
    ) {
      mixpanel.disable(); // Disable tracking when doing automated testing or local development
    } else if (trackingEnvironment === TrackingEnv.DEFAULT) {
      mixpanel.unregister('trackingEnv');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public track(
    event: string,
    params?: {
      [key: string]: any;
    }
  ): void {
    mixpanel.track(event, params);
  }

  // eslint-disable-next-line class-methods-use-this
  public handleLogin(identityId: string, email: string, isNewUser = false): void {
    const hashedUserId = new AnonymizedString(identityId).toString();
    mixpanel.people.set({
      'domain': new EmailAddress(email).domain
    });
    if (isNewUser) {
      mixpanel.alias(hashedUserId);
    }
    mixpanel.identify(hashedUserId);
  }

  // eslint-disable-next-line class-methods-use-this
  public handleLogout(): void {
    mixpanel.reset();
  }

  private static instance: UserAnalyticsClient | null | undefined = null;

  public static getInstance(): UserAnalyticsClient {
    if (this.instance) {
      return this.instance;
    }
    const instance = new UserAnalyticsClient();
    UserAnalyticsClient.instance = instance;
    return instance;
  }

  public static resetInstance(): void {
    UserAnalyticsClient.instance = null;
  }
}
