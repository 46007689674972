import { fromJS, Map, isKeyed } from 'immutable';
import {
  QPCRDATA_LOADED,
  QPCRDATA_ERROR,
  QPCRDATA_LOADING,
  QPCRDATA_STEP_LOADED,
  QPCRDATA_STEP_ERROR,
  QPCRDATA_STEP_LOADING,
  QPCRDATA_SAVE_LOADING,
  QPCRDATA_RUN_ADDED,
  QPCRDATA_RUN_EDITED,
  QPCRDATA_RUN_CLOSED,
  QPCRDATA_RUN_IS_SAVING
} from './qpcrdata_types';
import {
  getPcrData,
  postPcrRun,
  putPcrRun,
  computeStepData,
  postCloneAndUpdateRun
} from '../api/pcrData';
import { diffMap, maybeHash } from '../frontend-common-libs/src/utils/immutableUtils';
import Plate from '../helpers/Plate';
import { numToChar } from '../frontend-common-libs/src/utils/commonUtils';
import { QPCRDataActionType } from './qpcrdata_types';
import { Dispatch, GetState, StepAnalysisCompute } from '../types';
import { normalizeNewlines } from '../frontend-common-libs/src/common/strings';
import QPcrRunCreatedTrackingEvent from '../user-analytics/QPcrRunCreatedTrackingEvent';
import { getSelectedProjectId } from '../project-management';
import { getIsRunFromTemplate } from '../selectors/selectors';
import { withRefreshProjectToken } from '../project-management/actions/with-refresh-project-token';
import { CbWithToken } from '../organization-management/actions/WithRefreshToken';

const transformPCRData = (data: { [key: string]: any }) =>
  // @ts-ignore
  fromJS(data, (key, value, [path0]) => {
    if (path0 === 'settings') {
      // change 'settings' from List to Set as Lists are position sensitive
      return isKeyed(value) ? value.toMap() : value.toSet();
    }
    return isKeyed(value) ? value.toMap() : value.toList();
  });

const normalizeNotes = (runData: Map<string, any>) => {
  const notes = runData.getIn(['runInfo', 'notes']);
  if (!notes) return runData;
  // @ts-ignore
  return runData.setIn(['runInfo', 'notes'], normalizeNewlines(notes));
};

const groupDataByAnalysisMode = (runData: Map<string, any>) => {
  const groupMode = runData.getIn(['settings', 'analysis', 'groupMode']);
  const allStepData = runData.get('stepData');
  let stepGroupData = Map();
  if (allStepData) {
    const step = allStepData.keySeq().first();
    const stepData = allStepData.get(step) || Map();
    // @ts-ignore
    stepGroupData = fromJS({ [step]: { [groupMode]: stepData } });
  }
  return runData.set('stepData', stepGroupData);
};

const prepareRunData = (data: { [key: string]: any }): Map<string, any> => {
  let runData = transformPCRData(data);
  // @ts-ignore
  runData = normalizeNotes(runData);
  // @ts-ignore
  runData = groupDataByAnalysisMode(runData);
  // @ts-ignore
  return runData;
};

function getQPCRData(entityId: string) {
  return async (
    dispatch: Dispatch<
      QPCRDataActionType,
      {
        faId: string;
        data?: {
          [key: string]: any;
        };
      }
    >
  ) => {
    dispatch({
      type: QPCRDATA_LOADING,
      payload: { faId: entityId }
    });

    const MAX_RETRY = 3;
    let currentRetry = 0;

    while (currentRetry < MAX_RETRY) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await getPcrData(entityId);
        currentRetry = MAX_RETRY;
        dispatch({
          type: QPCRDATA_LOADED,
          payload: {
            data: prepareRunData(data),
            faId: entityId
          }
        });
      } catch (err) {
        currentRetry += 1;
        if (currentRetry === MAX_RETRY) {
          dispatch({
            type: QPCRDATA_ERROR,
            payload: {
              faId: entityId
            }
          });
          throw err;
        }
      }
    }
  };
}

export function getQPCRDataIfNeeded(entityId: string) {
  return async (
    dispatch: Dispatch<
      QPCRDataActionType,
      {
        [key: string]: any;
      }
    >,
    getState: GetState
  ) => {
    const state = getState().qpcrdata;
    // Only fetch protocol if we don't have any protocol data for this id
    // and we aren't loading and we haven't errored
    const entry = state.get(entityId);
    if (!entry || (!entry.get('loading') && !entry.get('data'))) {
      await dispatch(getQPCRData(entityId));
    }
  };
}

export function dispatchRunClosed(entityId: string) {
  return (
    dispatch: Dispatch<
      QPCRDataActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: QPCRDATA_RUN_CLOSED,
      payload: {
        faId: entityId
      }
    });
  };
}

function dispatchStepLoading(
  dispatch: Dispatch<QPCRDataActionType, any>,
  entityId: string,
  step: number,
  groupMode: string,
  plate: Map<string, any>,
  perStepAnalysisSettings: Map<string, any>
) {
  dispatch({
    type: QPCRDATA_STEP_LOADING,
    payload: {
      faId: entityId,
      step,
      groupMode,
      plate,
      perStepAnalysisSettings
    }
  });
}

function dispatchStepLoaded(
  dispatch: Dispatch<QPCRDataActionType, any>,
  stepAnalysisCompute: StepAnalysisCompute,
  stepData: Map<string, any>
) {
  dispatch({
    type: QPCRDATA_STEP_LOADED,
    payload: {
      faId: stepAnalysisCompute.entityId,
      step: stepAnalysisCompute.step,
      groupMode: stepAnalysisCompute.groupMode,
      stepData,
      plate: stepAnalysisCompute.plate,
      perStepAnalysisSettings: stepAnalysisCompute.perStepAnalysisSettings,
      driftCorrection: stepAnalysisCompute.driftCorrection
    }
  });
}

function dispatchStepError(
  dispatch: Dispatch<QPCRDataActionType, any>,
  entityId: string,
  step: number,
  groupMode: string,
  plate: Map<string, any>,
  perStepAnalysisSettings: Map<string, any>
) {
  dispatch({
    type: QPCRDATA_STEP_ERROR,
    payload: {
      faId: entityId,
      step,
      groupMode,
      plate,
      perStepAnalysisSettings
    }
  });
}

function getOrUpdateStepData(
  stepAnalysisCompute: StepAnalysisCompute,
  apiMethodWrapper: () => Promise<any>,
  outputParser?: (arg0: any) => Map<string, any>
) {
  return async (dispatch: Dispatch<any, any>) => {
    dispatchStepLoading(
      dispatch,
      stepAnalysisCompute.entityId,
      stepAnalysisCompute.step,
      stepAnalysisCompute.groupMode,
      stepAnalysisCompute.plate,
      stepAnalysisCompute.perStepAnalysisSettings
    );
    try {
      const { data } = await apiMethodWrapper();
      dispatchStepLoaded(
        dispatch,
        stepAnalysisCompute,
        // @ts-ignore
        outputParser ? outputParser(data) : fromJS(data)
      );
    } catch (err) {
      dispatchStepError(
        dispatch,
        stepAnalysisCompute.entityId,
        stepAnalysisCompute.step,
        stepAnalysisCompute.groupMode,
        stepAnalysisCompute.plate,
        stepAnalysisCompute.perStepAnalysisSettings
      );
    }
  };
}

function updateStepData(stepAnalysisCompute: StepAnalysisCompute) {
  return getOrUpdateStepData(stepAnalysisCompute, async () => computeStepData(stepAnalysisCompute));
}

export function getOrUpdateStepDataIfNeeded(stepAnalysisCompute: StepAnalysisCompute) {
  return async (
    dispatch: Dispatch<
      QPCRDataActionType,
      {
        [key: string]: any;
      }
    >,
    getState: GetState
  ) => {
    const stepNumStr = stepAnalysisCompute.step.toString();
    const { qpcrdata, currentCfxRun } = getState();

    // (1) Confirm duplicate requests are not made
    // ie: user changes plate and toggles between results and plate editor without making additional plate changes
    if (
      currentCfxRun.hasIn(['loading', stepNumStr, stepAnalysisCompute.groupMode]) &&
      !currentCfxRun.getIn([
        // loading
        'loading',
        stepNumStr,
        stepAnalysisCompute.groupMode,
        'error'
      ]) &&
      currentCfxRun.getIn([
        // not errored
        'loading',
        stepNumStr,
        stepAnalysisCompute.groupMode,
        'plateHash'
      ]) === maybeHash(stepAnalysisCompute.plate) &&
      currentCfxRun.getIn([
        // same plate
        'loading',
        stepNumStr,
        stepAnalysisCompute.groupMode,
        'analysisSettingsHash'
      ]) === maybeHash(stepAnalysisCompute.perStepAnalysisSettings)
    ) {
      // same analysis settings
      return;
    }

    // (2) If there are no pending requests...
    const originalPlate = qpcrdata.getIn([stepAnalysisCompute.entityId, 'data', 'plate']);
    const originalData = qpcrdata.getIn([
      stepAnalysisCompute.entityId,
      'data',
      'stepData',
      stepNumStr,
      stepAnalysisCompute.groupMode
    ]);
    // @ts-ignore
    const isSamePlate = maybeHash(originalPlate) === maybeHash(stepAnalysisCompute.plate);

    const originalAnalysisSettings = qpcrdata.getIn(['run', 'settings', 'analysis']);
    const isSameAnalysisSettings =
      // @ts-ignore
      maybeHash(originalAnalysisSettings) ===
      maybeHash(stepAnalysisCompute.perStepAnalysisSettings);
    if (originalData && isSamePlate && isSameAnalysisSettings) {
      // (2.A) If the plate and analysis settings are the same as the original plate and settings and the data has already been loaded
      // then just use that data
      // @ts-ignore
      dispatchStepLoaded(dispatch, stepAnalysisCompute, originalData);
    } else {
      // (2.B) If the plate has changed then step data needs to be updated
      // OR if the plate is the same but the data has not been loaded yet
      await dispatch(updateStepData(stepAnalysisCompute));
    }
  };
}

function qpcrSaveLoading(dispatch: Dispatch<QPCRDataActionType, any>, status: boolean) {
  return dispatch({
    type: QPCRDATA_SAVE_LOADING,
    payload: {
      isSaveInProgress: status
    }
  });
}
function qpcrRunIsSaving(
  dispatch: Dispatch<QPCRDataActionType, any>,
  entityId: string,
  isSaving: boolean
) {
  dispatch({
    type: QPCRDATA_RUN_IS_SAVING,
    payload: {
      faId: entityId,
      isSaving
    }
  });
}

export function createPCRRun(
  runName: string,
  runInfo: Map<string, any>,
  shouldRedirect: boolean,
  saveSource: string
) {
  return async (
    dispatch: Dispatch<
      QPCRDataActionType,
      {
        [key: string]: any;
      }
    >,
    getState: GetState
  ) => {
    let dispatchedRunInfo = runInfo;
    qpcrSaveLoading(dispatch, true);
    const plate = runInfo.get('plate');
    const projectId = getSelectedProjectId(getState());
    const run = runInfo
      .set('name', runName)
      .set('device', Plate.size(plate) === 96 ? 'CFX96' : 'CFX384')
      .set('parentId', projectId);

    try {
      const { data: faEntity } = await postPcrRun(run.toJS());
      const isRunFromTemplate = getIsRunFromTemplate(getState());
      new QPcrRunCreatedTrackingEvent(
        faEntity.id,
        Plate.size(plate),
        Plate.scanMode(plate),
        saveSource,
        isRunFromTemplate
      ).track();
      if (faEntity.versionNumber)
        dispatchedRunInfo = runInfo.set('versionNumber', faEntity.versionNumber);

      dispatch({
        type: QPCRDATA_RUN_ADDED,
        payload: {
          faEntity,
          data: dispatchedRunInfo,
          name: runName,
          shouldRedirect
        }
      });
    } catch (error) {
      qpcrSaveLoading(dispatch, false);
      throw error;
    }
  };
}

// function assumes there is always editedProtocol
function protocolChanges(editedRun: Map<string, any>, originalRun: Map<string, any>) {
  const editedProtocol = editedRun.get('protocol');
  const originalProtocol = originalRun.get('protocol');
  if (!originalProtocol) return fromJS({ protocol: editedProtocol });
  const diff = diffMap(editedProtocol, originalProtocol, ['id', 'fa_id']);
  if (diff.isEmpty()) return fromJS({});
  return fromJS({ protocol: editedProtocol });
}

// function assumes there is always plate in both edited and original
function plateChanges(editedRun: Map<string, any>, originalRun: Map<string, any>) {
  let changes = fromJS({});
  const editedPlate = editedRun.get('plate');
  const originalPlate = originalRun.get('plate');
  if (!editedPlate.equals(originalPlate)) {
    // @ts-ignore
    changes = changes.set('plate', diffMap(editedPlate, originalPlate, ['wells']));
    const editedWells = Plate.wells(editedPlate);
    const originalWells = Plate.wells(originalPlate);
    if (!editedWells.equals(originalWells)) {
      const excludeWellKeys = [];
      if (Plate.size(editedPlate) < Plate.size(originalPlate)) {
        for (let row = 1; row <= Plate.rows(originalPlate); row += 1) {
          const colStart = row <= Plate.rows(editedPlate) ? Plate.columns(editedPlate) + 1 : 1;
          for (let col = colStart; col <= Plate.columns(originalPlate); col += 1) {
            excludeWellKeys.push(numToChar(row) + col);
          }
        }
      }
      // @ts-ignore
      changes = changes.setIn(
        ['plate', 'wells'],
        diffMap(editedWells, originalWells, excludeWellKeys)
      );
    }
  }
  return changes;
}

function settingsChanges(editedRun: Map<string, any>, originalRun: Map<string, any>) {
  if (maybeHash(editedRun.get('settings')) !== maybeHash(originalRun.get('settings'))) {
    return fromJS({ settings: editedRun.get('settings') });
  }
  return fromJS({});
}

function createUpdateRunBody(
  runName: string,
  editedRunInfo: Map<string, any>,
  originalRunInfo: Map<string, any>
): Map<string, any> {
  let changes = protocolChanges(editedRunInfo, originalRunInfo);
  // @ts-ignore
  changes = changes.merge(plateChanges(editedRunInfo, originalRunInfo));
  // @ts-ignore
  changes = changes.merge(settingsChanges(editedRunInfo, originalRunInfo));
  // @ts-ignore
  changes = changes.set('name', runName);
  const notes = editedRunInfo.getIn(['runInfo', 'notes']);
  // @ts-ignore
  if (notes !== undefined) changes = changes.setIn(['runInfo', 'notes'], notes);
  const plateId = editedRunInfo.getIn(['runInfo', 'barcode']);
  // @ts-ignore
  if (plateId !== undefined) changes = changes.setIn(['runInfo', 'barcode'], plateId);
  const barcode = editedRunInfo.getIn(['runInfo', 'barcode']);
  // @ts-ignore
  if (barcode !== undefined) changes = changes.setIn(['runInfo', 'barcode'], barcode);
  const protocolName = editedRunInfo.getIn(['runInfo', 'protocolName']);
  if (protocolName !== undefined)
    // @ts-ignore
    changes = changes.setIn(['runInfo', 'protocolName'], protocolName);
  const versionNumber = originalRunInfo.get('versionNumber');
  // @ts-ignore
  if (versionNumber !== undefined) changes = changes.set('versionNumber', versionNumber);
  return changes as Map<string, any>;
}

export function editPCRRun(
  entityId: string,
  runName: string,
  editedRun: Map<string, any>,
  originalRunInfo: Map<string, any>,
  shouldRedirect = false
) {
  return async (
    dispatch: Dispatch<
      QPCRDataActionType,
      {
        [key: string]: any;
      }
    >,
    getState: GetState
  ) => {
    qpcrSaveLoading(dispatch, true);
    let editedRunInfo = editedRun;
    const updateRunBody = createUpdateRunBody(runName, editedRun, originalRunInfo);
    try {
      const state = getState();
      const projectId = getSelectedProjectId(state);
      qpcrRunIsSaving(dispatch, entityId, true);

      const putPcrRunCb: CbWithToken = async (projectAccessToken: string) => {
        return putPcrRun(entityId, updateRunBody.toJS(), projectAccessToken);
      };
      const { data: faEntity } = await withRefreshProjectToken(
        dispatch,
        getState,
        projectId,
        putPcrRunCb
      );
      qpcrRunIsSaving(dispatch, entityId, false);
      editedRunInfo = editedRunInfo.set('versionNumber', faEntity.versionNumber);
      dispatch({
        type: QPCRDATA_RUN_EDITED,
        payload: {
          faEntity,
          data: editedRunInfo,
          name: runName,
          shouldRedirect
        }
      });
    } catch (error) {
      qpcrRunIsSaving(dispatch, entityId, false);
      qpcrSaveLoading(dispatch, false);
      throw error;
    }
  };
}

export function cloneAndUpdateRun(
  entityId: string,
  runName: string,
  editedRun: Map<string, any>,
  originalRunInfo: Map<string, any>
) {
  return async (
    dispatch: Dispatch<
      QPCRDataActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    let editedRunInfo = editedRun;
    let cloneAndUpdateRunBody = createUpdateRunBody(runName, editedRun, originalRunInfo);
    cloneAndUpdateRunBody = cloneAndUpdateRunBody.set('entityId', entityId);
    try {
      qpcrRunIsSaving(dispatch, entityId, true);
      // @ts-ignore
      const { data: faEntity } = await postCloneAndUpdateRun(cloneAndUpdateRunBody.toJS());
      editedRunInfo = editedRun.set('versionNumber', faEntity.versionNumber).set('name', runName);

      dispatch({
        type: QPCRDATA_RUN_ADDED,
        payload: {
          faEntity,
          data: editedRunInfo,
          name: runName,
          shouldRedirect: true
        }
      });
    } catch (error) {
      qpcrRunIsSaving(dispatch, entityId, false);
      throw error;
    }
  };
}
