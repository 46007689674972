import { CPCR_PROTOCOL_ENTITY_TYPE } from '../conventional-pcr/protocol';
import { PROTOCOL_ENTITY_TYPE } from './protocol_common';

export type FileProgress = 'P' | 'C' | 'F' | 'FN' | 'CA' | 'PR' | 'US' | 'UR' | 'SE';

export const UPLOAD_IN_PROGRESS: FileProgress = 'P';
export const FILE_OPERATION_COMPLETE: FileProgress = 'C';
export const FILE_OPERATION_FAILED: FileProgress = 'F';
export const FILE_NETWORK_OPERATION_FAILED: FileProgress = 'FN';
export const UPLOAD_CANCELLED: FileProgress = 'CA';
export const FILE_OPERATION_PROCESSING: FileProgress = 'PR';
export const UPLOAD_UNSUPPORTED: FileProgress = 'US';
export const UPLOAD_COMPLETE_UNRECOGNIZED: FileProgress = 'UR';
export const UPLOAD_SIZE_EXCEEDED: FileProgress = 'SE';

export const FILETYPE_UPLOAD = 'upload';
export const FILETYPE_IN_PROGRESS = 'in-progress';
export const FILETYPE_CFX_RUN = 'cfx-run';
export const FILETYPE_PENDING_CFX_RUN = 'cfx-pending-run';
export const FILETYPE_IN_PROGRESS_CFX_RUN = 'cfx-in-progress-run';
const FILETYPE_UNKNOWN = 'upload';

export const FILETYPE_CFX_RUN_TEMPLATE = 'cfx-run-template';

export const FILETYPE_IN_PROGRESS_PTC_TEMPO_RUN = 'ptc-in-progress-run';
export const FILETYPE_PTC_RUN = 'ptc-run';

export const CDGO_SCN = 'cdgo-scn';
export const CDGO_SSCN = 'cdgo-sscn';
export const CDGO_MSCN = 'cdgo-mscn';
export const CDGO_SMCN = 'cdgo-smscn';

export type FileType =
  | typeof FILETYPE_UPLOAD
  | typeof FILETYPE_IN_PROGRESS
  | typeof FILETYPE_CFX_RUN
  | typeof FILETYPE_PENDING_CFX_RUN
  | typeof FILETYPE_IN_PROGRESS_CFX_RUN
  | typeof FILETYPE_UNKNOWN
  | typeof FILETYPE_CFX_RUN_TEMPLATE;

export const FileOperationType = { upload: 'upload', exportPcrd: 'exportPcrd' };

const fileStatusMap = {
  [FILETYPE_UPLOAD]: 'upload',
  [FILETYPE_IN_PROGRESS]: 'upload',
  [FILETYPE_CFX_RUN]: 'CFX run: Completed',
  [FILETYPE_PENDING_CFX_RUN]: 'CFX run: Pending',
  [FILETYPE_IN_PROGRESS_CFX_RUN]: 'CFX run: In progress',
  [FILETYPE_UNKNOWN]: 'upload',
  [FILETYPE_CFX_RUN_TEMPLATE]: 'CFX run: Template',
  [PROTOCOL_ENTITY_TYPE]: 'CFX Protocol',
  [CPCR_PROTOCOL_ENTITY_TYPE]: 'PTC Protocol'
};

// @ts-ignore
export const getFileTypeStatus = (fileType: string): string => fileStatusMap[fileType];

export const DOWNLOADABLE_FILE_TYPES: FileType[] = [FILETYPE_CFX_RUN];

export interface EntityIotMessageEvent {
  id: string;
  type: FileType;
  status: FileProgress;
}

export interface EntityIotMessage {
  event: EntityIotMessageEvent;
  sender: string;
}
