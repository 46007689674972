import { Dispatch } from '../../types';
import UploadQueue, { PostUploadResponse, UploadInfo } from './fileupload_queue';
import { isSupportedFile } from '../../frontend-common-libs/src/utils/fileUtils';
import request from '../../frontend-common-libs/src/utils/httpUtils';
import {
  FILEUPLOAD_CANCELLED,
  FILEUPLOAD_FAILED,
  FILEUPLOAD_INCOMPATIBLE,
  FILEUPLOAD_NETWORK_ERROR,
  FILEUPLOAD_PROCESSING,
  FILEUPLOAD_SIZE_EXCEEDED,
  FILEUPLOAD_STARTED,
  FILEUPLOAD_UNSUPPORTED
} from '../../frontend-common-libs/src/file-operations/file_operation_types';
import {
  EntityIotMessageEvent,
  FILE_OPERATION_COMPLETE,
  FileOperationType,
  FileProgress,
  FileType,
  FILETYPE_IN_PROGRESS,
  FILETYPE_UPLOAD,
  UPLOAD_IN_PROGRESS
} from '../../frontend-common-libs/src/common/userfiles_common';
import { USERFILES_PROCESSING_COMPLETE } from '../../file-management/actions/action-types';
import { GATEWAY } from '../../frontend-common-libs/src/config/config';
import uploadFile, {
  cancelUpload as cancelS3Upload
} from '../../frontend-common-libs/src/utils/petfUtils';
import { getEntityInfo } from '../../frontend-common-libs/src/api/entities';
import FileUploadNotStartedError from './FileUploadNotStartedError';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../frontend-common-libs/src/components/feature_flags';
import { DEFAULT_PROJECT_ID } from '../../frontend-common-libs/src/common/project-management-types';
import { isNetworkError } from '../../frontend-common-libs/src/utils/errorUtils';
import { DownloadNetworkError } from '../../frontend-common-libs/src/file-operations/messages';

export default class FileUpload {
  public queueId: number;

  public entityId: string | undefined;

  private name: string | undefined;

  private uploadQueue: UploadQueue;

  private processingStartedTimeOutId: NodeJS.Timeout | undefined;

  private processingCompletedTimeOutId: NodeJS.Timeout | undefined;

  private readonly ignorePreventInstrumentUploads: boolean;

  private readonly dispatch: Dispatch<any, any>;

  private readonly file: any;

  private readonly projectId: string;

  private readonly onUploadResolved: (queueId: number) => void;

  private static readonly timeToWaitForProgress: number = 30000;

  private static readonly timeToWaitForComplete: number = 90000;

  private static readonly maximumUploadSize: number = 15000000; // 15 MB

  private static readonly baseURL: string = GATEWAY.FILEARBITER_PATHS.BASE_URL;

  private static readonly pathTemplate: string = GATEWAY.FILEARBITER_PATHS.UPLOADPATH_URL;

  private cancelled = false;

  constructor(
    queueId: number,
    file: File,
    uploadQueue: UploadQueue,
    dispatch: Dispatch<any, any>,
    onUploadResolved: (queueId: number) => void,
    projectId: string = DEFAULT_PROJECT_ID
  ) {
    this.queueId = queueId;
    this.file = file;
    this.uploadQueue = uploadQueue;
    this.dispatch = dispatch;
    this.onUploadResolved = onUploadResolved;
    this.ignorePreventInstrumentUploads =
      FeatureFlags().get(FeatureFlagKeys.IGNORE_PREVENT_INSTRUMENT_UPLOADS) === true;
    this.projectId = projectId;
  }

  public start = async (): Promise<void> => {
    try {
      this.dispatch({
        type: FILEUPLOAD_STARTED,
        payload: {
          uploads: [
            {
              id: this.queueId,
              file: this.file,
              status: UPLOAD_IN_PROGRESS,
              fileOperationType: FileOperationType.upload
            }
          ]
        }
      });
      const { url, id, headers } = await this.acquireUploadInfo();
      if (!this.cancelled) {
        this.entityId = id;
        await uploadFile(url, headers, this.file, this.queueId);
        this.onProcessingStarted();
      }
    } catch (error) {
      this.onUploadFailed(error);
      this.onUploadResolved(this.queueId);
    }
  };

  private acquireUploadInfo = async (): Promise<UploadInfo> => {
    const { name, size } = this.file;
    const body = { name: encodeURIComponent(name), parentId: this.projectId };
    this.name = name;
    if (!isSupportedFile(name)) throw new Error(FILEUPLOAD_UNSUPPORTED);
    if (!FileUpload.isValidUploadSize(size)) throw new Error(FILEUPLOAD_SIZE_EXCEEDED);

    const postUploadRequest = async (): Promise<PostUploadResponse> => {
      const additionalParams = this.ignorePreventInstrumentUploads
        ? {
            headers: { 'ignore-prevent-instrument-uploads': true }
          }
        : {};
      const uploadResponse = await request.post(
        FileUpload.baseURL,
        {},
        FileUpload.pathTemplate,
        additionalParams,
        body
      );
      return uploadResponse as PostUploadResponse;
    };

    const response: PostUploadResponse = await this.uploadQueue.add(postUploadRequest);
    return response.data;
  };

  private static isValidUploadSize = (size: number): boolean => {
    return size != null && size <= FileUpload.maximumUploadSize;
  };

  private onUploadFailed = (error: any): void => {
    let errorMessage: string | undefined;
    if (isNetworkError(error)) errorMessage = DownloadNetworkError;

    if (error.message === 'Cancelled') {
      this.dispatch({
        type: FILEUPLOAD_CANCELLED,
        payload: {
          ids: [this.queueId]
        }
      });
    } else if (error.message === FILEUPLOAD_UNSUPPORTED) {
      this.dispatch({
        type: FILEUPLOAD_UNSUPPORTED,
        payload: {
          ids: [this.queueId],
          errorMessage: 'BR.io supports PCRD and ZPCR files only'
        }
      });
    } else if (error.message === FILEUPLOAD_SIZE_EXCEEDED) {
      this.dispatch({
        type: FILEUPLOAD_SIZE_EXCEEDED,
        payload: {
          ids: [this.queueId]
        }
      });
    } else {
      this.dispatch({
        type: FILEUPLOAD_FAILED,
        payload: {
          id: this.queueId,
          errorMessage
        }
      });
    }
  };

  public onProcessingStarted = (): void => {
    this.dispatch({
      type: FILEUPLOAD_PROCESSING,
      payload: {
        id: this.queueId
      }
    });
    this.processingStartedTimeOutId = setTimeout(
      this.onProcessingStartedTimeOut,
      FileUpload.timeToWaitForProgress
    );
  };

  public onProcessingInProgressUpdate = (): void => {
    this.clearProcessingStartedTimeOut();
    this.processingCompletedTimeOutId = setTimeout(
      this.onProcessingCompletedTimeOut,
      FileUpload.timeToWaitForComplete
    );
  };

  public onIotMessage = (entity: EntityIotMessageEvent): void => {
    const { type, status } = entity;
    if (type === FILETYPE_IN_PROGRESS) {
      this.onProcessingInProgressUpdate();
    } else if (status === FILE_OPERATION_COMPLETE) {
      this.onProcessingComplete(entity);
    }
  };

  public cancelUpload = (): void => {
    this.cancelled = true;
    this.dispatch({
      type: FILEUPLOAD_CANCELLED,
      payload: {
        ids: [this.queueId]
      }
    });
    cancelS3Upload(this.queueId);
    this.onUploadResolved(this.queueId);
  };

  public onProcessingStartedTimeOut = async (): Promise<void> => {
    if (!this.entityId) {
      throw new FileUploadNotStartedError(
        'Tried to call onProcessingStartedTimeOut before calling start.'
      );
    }
    try {
      const entity = await getEntityInfo(this.entityId);
      const { type } = entity.data;
      if (type === FILETYPE_UPLOAD) {
        this.dispatch({
          type: FILEUPLOAD_INCOMPATIBLE,
          payload: {
            id: this.queueId,
            faId: this.entityId,
            name: this.name
          }
        });
        this.clearProcessingStartedTimeOut();
        this.onUploadResolved(this.queueId);
      } else {
        this.onProcessingInProgressUpdate();
      }
    } catch (error) {
      this.dispatch({
        type: FILEUPLOAD_NETWORK_ERROR,
        payload: {
          id: this.queueId,
          faId: this.entityId,
          name: this.name
        }
      });
    }
  };

  public onProcessingCompletedTimeOut = async (): Promise<void> => {
    if (!this.entityId) {
      throw new FileUploadNotStartedError(
        'Tried to call onProcessingCompletedTimeOut before calling start.'
      );
    }

    const entityInfo = await getEntityInfo(this.entityId);
    const entity = entityInfo.data;
    const { type } = entity;
    if (type === FILETYPE_IN_PROGRESS) {
      this.onProcessingFailed();
    } else {
      this.onProcessingComplete(entity);
    }
  };

  public get fileNameExtension(): string | undefined {
    return this.file ? this.file.name.split('.').pop() : undefined;
  }

  private onProcessingComplete = (entity: { type: FileType; status: FileProgress }): void => {
    this.dispatch({
      type: USERFILES_PROCESSING_COMPLETE,
      payload: {
        entity,
        id: this.queueId
      }
    });
    this.clearProcessingCompletedTimeOut();
    this.onUploadResolved(this.queueId);
  };

  private onProcessingFailed = (): void => {
    this.dispatch({
      type: FILEUPLOAD_FAILED,
      payload: {
        id: this.queueId,
        message: 'Upload failed'
      }
    });
    this.clearProcessingCompletedTimeOut();
    this.onUploadResolved(this.queueId);
  };

  private clearProcessingStartedTimeOut = (): void => {
    if (this.processingStartedTimeOutId) {
      clearTimeout(this.processingStartedTimeOutId);
    }
  };

  private clearProcessingCompletedTimeOut = (): void => {
    if (this.processingCompletedTimeOutId) {
      clearTimeout(this.processingCompletedTimeOutId);
    }
  };

  public static create(
    queueId: number,
    file: File,
    uploadQueue: UploadQueue,
    dispatch: Dispatch<any, any>,
    onUploadResolved: (queueId: number) => void,
    projectId: string = DEFAULT_PROJECT_ID
  ): FileUpload {
    return new FileUpload(queueId, file, uploadQueue, dispatch, onUploadResolved, projectId);
  }
}
