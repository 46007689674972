import React from 'react';
import {
  Select,
  SelectMenuItem
} from '../../../../frontend-common-libs/src/components/common/dropdown';
import ProtocolListRepository from '../../../protocol/repository/ProtocolListRepository';
import { getProtocolsIfNeeded } from '../../../protocol/actions/protocol-actions';
import noneProtocolItem from '../../noneProtocolItem';
import styles from './styles/protocol-selection-dropdown.module.scss';

export type ProtocolListType = { name: string; id: string }[];

export type Props = {
  protocols: Map<string, any>;
  selectedProtocol: string;
  updateSelectedProtocol: (value: { id: string; name: string }) => void;
};

export function fetchProtocolsIfNeeded(projectId: string) {
  const projectProtocols = ProtocolListRepository.instance.getProjectProtocols(projectId);
  if (projectProtocols.staleData) {
    (async () => {
      await getProtocolsIfNeeded(projectId, '');
    })();
  }
  return projectProtocols.protocols;
}

export default function ProtocolSelectionDropdown(props: Readonly<Props>) {
  const { selectedProtocol, updateSelectedProtocol, protocols } = props;

  const buildProtocolOptionsList = (): { id: string; name: string }[] => {
    const protocolList: ProtocolListType = [];
    protocolList.push(noneProtocolItem);
    protocols.forEach(protocol => {
      protocolList.push({
        id: protocol.get('id'),
        name: protocol.get('name')
      });
    });
    return protocolList;
  };

  const protocolList: ProtocolListType = buildProtocolOptionsList();

  return (
    <div className={styles.protocolAssignmentContainer}>
      <div className={styles.protocolAssignmentText} data-testid="protocol-assignment-text">
        Assign Protocol to Reserved Instruments (Optional)
      </div>
      <div className={styles.protocolAssignmentSelect}>
        <Select
          id="protocol-options"
          data-testid="protocol-options"
          value={selectedProtocol === '' ? 'Select Protocol' : selectedProtocol}
          handleSelection={updateSelectedProtocol}
        >
          {protocolList.map(protocol => {
            return (
              <SelectMenuItem
                id={`protocol-${protocol.id}`}
                key={protocol.id}
                value={{ id: protocol.id, name: protocol.name }}
                selected={protocol.name === selectedProtocol}
              >
                {protocol.name}
              </SelectMenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
}
