import React, { PureComponent } from 'react';
import AutoSizer, { HorizontalSize } from 'react-virtualized-auto-sizer';
import { connect } from 'react-redux';
import { List } from 'immutable';
import * as instrumentActions from '../../../actions/instrument.actions';
import {
  getInstrumentsWithStatus,
  waitingForStatuses
} from '../../../selectors/instrument.selectors';
import { ReduxState } from '../../../../types';
import {
  Instrument,
  InstrumentsList
} from '../../../../frontend-common-libs/src/instruments/types';
import { InstrumentFamilyPluginRepository } from '../../../../instrument-family-plugin';

type InstrumentId = string;
type InstrumentType = string;
type InstrumentName = string;
type InstrumentStatus = string;
type InstrumentModel = string;

export type Props = {
  isLoading: boolean;
  instruments: InstrumentsList | List<unknown>;
  errorMessage?: string;
  loadInstrumentListIfNeeded: () => void;
};

export class RecentInstrumentsListImpl extends PureComponent<Props> {
  static defaultProps = {
    errorMessage: undefined
  };

  componentDidMount() {
    const { instruments, loadInstrumentListIfNeeded } = this.props;
    if (!instruments.size) {
      loadInstrumentListIfNeeded();
    }
  }

  handleRender = () => {
    const { instruments, isLoading, errorMessage } = this.props;
    if (isLoading) return this.renderLoader();
    if (errorMessage) return this.renderErrorRetry();

    const instrumentsList = instruments;

    if (!instrumentsList || instrumentsList.size === 0) {
      return this.renderNoInstrumentsMessage();
    }

    return this.renderRecentInstruments(instrumentsList);
  };

  renderRecentInstrumentsList = (
    instruments: InstrumentsList | List<unknown>,
    width: number | undefined
  ) => {
    const defaultWidth = 0;
    const maxWidth = (width || defaultWidth) / 205;
    const maxNumberOfInstruments = parseInt(maxWidth.toString(), 10);

    const instrumentsToShow = instruments.slice(
      0,
      maxNumberOfInstruments
    ) as unknown as List<Instrument>;

    return (
      <>
        {instrumentsToShow.map((instrument: Instrument) => {
          const deviceId = instrument.get('deviceId') as InstrumentId;
          const deviceType = instrument.get('deviceType') as InstrumentType;
          const name = instrument.get('name') as InstrumentName;
          const status = instrument.get('status') as InstrumentStatus;
          const model = instrument.get('model') as InstrumentModel;

          const plugin =
            InstrumentFamilyPluginRepository.getInstance().getPluginByInstrumentType(deviceType);

          if (plugin) {
            const InstrumentTile = plugin.getInstrumentTile();

            return (
              <InstrumentTile
                key={deviceId}
                deviceId={deviceId}
                deviceType={deviceType}
                name={name}
                status={status}
                model={model}
                instrument={instrument}
              />
            );
          }

          return null;
        })}
      </>
    );
  };

  renderRecentInstruments = (instruments: InstrumentsList | List<unknown>) => (
    <AutoSizer disableHeight style={{ width: '100%', display: 'flex' }}>
      {({ width }: HorizontalSize) => this.renderRecentInstrumentsList(instruments, width)}
    </AutoSizer>
  );

  renderLoader = () => (
    <div className="loader flex-bullseye">
      <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
      <span className="sr-only">Loading...</span>
    </div>
  );

  renderErrorRetry = () => {
    const { loadInstrumentListIfNeeded } = this.props;
    return (
      <div id="instruments-error" className="flex-bullseye">
        <span>Error retrieving instrument information</span>
        <button type="button" className="new-btn primary" onClick={loadInstrumentListIfNeeded}>
          Try Again
        </button>
      </div>
    );
  };

  renderNoInstrumentsMessage = () => (
    <div className="flex-bullseye" id="no-instruments-message">
      No Instruments
    </div>
  );

  render() {
    return (
      <div className="recent-instruments-list-container">
        <h4 id="recent-instrument-list-title">RECENT INSTRUMENTS</h4>
        <div className="recent-instrument-list">{this.handleRender()}</div>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const isLoading = state.instruments.get('isLoading') || waitingForStatuses(state);

  return {
    isLoading,
    instruments: isLoading ? List() : getInstrumentsWithStatus(state),
    errorMessage: state.instruments.get('error')
  };
}

export default connect(mapStateToProps, {
  loadInstrumentListIfNeeded: instrumentActions.loadInstrumentListIfNeeded
})(RecentInstrumentsListImpl);
