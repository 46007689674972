import React, { useEffect, useState } from 'react';
import './styles/instrument-details.scss';
import {
  NullableNumber,
  NullableString
} from '../../frontend-common-libs/src/common/nullable-types';
import InstrumentDetailsItem from './InstrumentDetailsItem';
import OpenInstrumentDetailsTrackingEvent from '../analytics/OpenInstrumentDetailsTrackingEvent';
import Protocol from './Protocol';
import { getCurrentUserIdentityId } from '../../frontend-common-libs/src/utils/authUtils';

export type Props = {
  instrumentId: string;
  serialNumber: string;
  softwareVersion: string;
  mainFirmwareVersion: string;
  powerFirmwareVersion: string;
  lidFirmwareVersion: string;
  instrumentName: string;
  instrumentNameLine2?: string;
  apiVersion: string;
  imageVersion: string;
  displayModel: string;
  instrumentModel: string;
  runCurrentCycle: NullableNumber;
  runTotalCycles: NullableNumber;
  runCurrentStep: NullableNumber;
  runTotalSteps: NullableNumber;
  isRunStatusDisplayed: boolean;
  runId: NullableString;
  runUserId: NullableString;
};

export default function InstrumentDetails({
  serialNumber,
  instrumentId,
  softwareVersion,
  mainFirmwareVersion,
  powerFirmwareVersion,
  lidFirmwareVersion,
  displayModel,
  apiVersion,
  instrumentName,
  instrumentNameLine2,
  imageVersion,
  instrumentModel,
  runCurrentCycle,
  runTotalCycles,
  runCurrentStep,
  runTotalSteps,
  isRunStatusDisplayed,
  runId,
  runUserId
}: Props) {
  const openInstrumentDetailsTrackingEvent = new OpenInstrumentDetailsTrackingEvent();
  openInstrumentDetailsTrackingEvent.track(instrumentModel, instrumentId);

  const [protocolName, setProtocolName] = useState<NullableString>('Loading ...');

  const getRunProtocolName = async () => {
    try {
      const protocol = new Protocol(runId);
      const runProtocolName = await protocol.getProtocolNameFromRun();
      setProtocolName(runProtocolName);
    } catch (error) {
      setProtocolName(null);
    }
  };

  useEffect(() => {
    if (runId) {
      getRunProtocolName();
    } else {
      setProtocolName(null);
    }
  }, [runId]);

  const isValidToDisplayRunState = () => {
    const currentUserId = getCurrentUserIdentityId();

    return currentUserId === runUserId;
  };

  const renderUserRunPanel = () => {
    return (
      <div className="run-panel run-panel-background">
        <div
          className="title-label"
          id={`protocol-name_${instrumentId}`}
          title={protocolName ?? ''}
        >
          {protocolName ?? ''}
        </div>
        <div className="run-details" id={`run-status-panel_${instrumentId}`}>
          {runCurrentCycle !== 0 && <div className="category">Cycles</div>}
          {runCurrentCycle !== 0 && (
            <div className="category-value separator" id={`cycle-step_${instrumentId}`}>
              {runCurrentCycle} of {runTotalCycles}
            </div>
          )}
          {runCurrentStep !== 0 && <div className="category">Steps</div>}
          {runCurrentStep !== 0 && (
            <div className="category-value" id={`protocol-step_${instrumentId}`}>
              {runCurrentStep} of {runTotalSteps}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderRunPanel = () => {
    if (isRunStatusDisplayed) {
      if (!isValidToDisplayRunState()) {
        return (
          <div className="run-panel run-panel-background">
            <div className="title-label">IN USE BY ANOTHER USER</div>
          </div>
        );
      }
      return renderUserRunPanel();
    }
    return <div className="run-panel" />;
  };

  return (
    <div className="conventional-pcr-details">
      <div>{renderRunPanel()}</div>
      <div className="run-details-panel" />
      <div className="instrument-details-panel">
        <div className="instrument-details-title-label">INSTRUMENT DETAILS</div>
        <div className="instrument-details-panel-body">
          <table className="general-instrument-details">
            <tbody>
              <InstrumentDetailsItem
                id={`sn_${instrumentId}`}
                value={serialNumber}
                label="Serial Number"
                valueClassName="general-instrument-details-value"
              />
              <InstrumentDetailsItem
                id={`instrument-details-model_${instrumentId}`}
                value={displayModel}
                label="Instrument Model"
                valueClassName="general-instrument-details-value"
              />
              <InstrumentDetailsItem
                id={`name_${instrumentId}`}
                value={instrumentName}
                label="Instrument Name"
                valueClassName="general-instrument-details-value"
              />
              {instrumentNameLine2 && (
                <InstrumentDetailsItem
                  id={`name-line-2_${instrumentId}`}
                  value={instrumentNameLine2}
                  label=""
                  valueClassName="general-instrument-details-value"
                />
              )}
            </tbody>
          </table>
          <table className="software-firmware-versions">
            <tbody>
              <InstrumentDetailsItem
                id={`software_${instrumentId}`}
                value={softwareVersion}
                label="Software"
              />
              <InstrumentDetailsItem
                id={`main-frmw_${instrumentId}`}
                value={mainFirmwareVersion}
                label="Main Firmware"
              />
              <InstrumentDetailsItem
                id={`power-frmw_${instrumentId}`}
                value={powerFirmwareVersion}
                label="Power Firmware"
              />
              <InstrumentDetailsItem
                id={`lid-frmw_${instrumentId}`}
                value={lidFirmwareVersion}
                label="Lid Firmware"
              />
              <InstrumentDetailsItem
                id={`api-ver_${instrumentId}`}
                value={apiVersion}
                label="API Version"
              />
              <InstrumentDetailsItem
                id={`image-ver_${instrumentId}`}
                value={imageVersion}
                label="Image Version"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
