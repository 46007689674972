import { GATEWAY } from '../../../frontend-common-libs/src/config/config';
import requests from '../../../frontend-common-libs/src/utils/httpUtils';

const domain = GATEWAY.CPCR_SERVICE_PATHS.BASE_URL;

export async function getCpcrRun(entityId: string): Promise<any> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.GET_RUN_URL(entityId);
  const res = await requests.get(domain, {}, path, {});
  return res.data;
}

export async function updateCpcrRun(entityId: string, body: any): Promise<any> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.GET_RUN_URL(entityId);
  const res = await requests.put(domain, {}, path, {}, body);
  return res.data;
}
