import React from 'react';
import { Link } from 'react-router-dom';
import { LinkContent } from './CreateExperimentCard';

export type Props = {
  id: string;
  links: LinkContent[];
};

export default function CreateExperimentCardLinks({ id, links }: Props) {
  return (
    <>
      {links.map(({ id: linkContentId, displayText, path }: LinkContent) => {
        const linkId = `${id}-${linkContentId}`;
        return (
          <Link key={linkId} id={linkId} to={path}>
            {displayText}
          </Link>
        );
      })}
    </>
  );
}
