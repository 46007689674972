import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import WorkflowHeader from '../workflow-layout/WorkflowHeader';
import WorkflowArea from '../workflow-layout/WorkflowArea';
import WorkflowPage from '../workflow-layout/WorkflowPage';
import WorkflowView from '../workflow-layout/WorkflowView';
import { getNewFileName } from '../../../frontend-common-libs/src/utils/fileUtils';
import TemplateSelector from './TemplateSelector';
import { getRunTemplate } from '../../../api/run-template';
import PrimaryButton from '../../../frontend-common-libs/src/common/buttons';
import notification from '../../../frontend-common-libs/src/utils/notifications';
import TemplateEntity from './models/TemplateEntity';
import { initRunFromTemplate as initRunFromTemplateAction } from '../../../actions/currentCfxRun_actions';
import coreRoutes from '../../../core/routes';
import realTimePcrRoutes from '../../../real-time-pcr/routes';

import '../../styles/template-selector.scss';
import { ReduxState } from '../../../types';
import { getSelectedProjectId } from '../../../project-management';
import { ProjectId } from '../../../frontend-common-libs/src/common/project-management-types';

export type TemplateSelectorPageProps = {
  initRunFromTemplate: (runTemplate: Record<string, any>, runName: string) => void;
  projectId: ProjectId;
};

type TemplateSelectorPageState = {
  name: string;
  isLoadingTemplate: boolean;
  selectedTemplateEntity?: TemplateEntity;
  redirectToPlate: boolean;
};

export class TemplateSelectorPageImpl extends Component<
  TemplateSelectorPageProps,
  TemplateSelectorPageState
> {
  constructor(props: TemplateSelectorPageProps) {
    super(props);
    this.state = {
      name: getNewFileName(),
      isLoadingTemplate: false,
      redirectToPlate: false
    };
  }

  private getTemplate = async () => {
    const { selectedTemplateEntity } = this.state;
    if (selectedTemplateEntity) {
      try {
        this.setState({ isLoadingTemplate: true });
        const template = await getRunTemplate(selectedTemplateEntity.id);
        const { name } = this.state;
        const { initRunFromTemplate } = this.props;
        await initRunFromTemplate(template.data, name);
        this.setState({ redirectToPlate: true });
      } catch (err) {
        notification.error('Failed to load template');
        this.setState({ isLoadingTemplate: false });
      }
    }
  };

  private handleTemplateSelection = (templateEntity: TemplateEntity) => {
    this.setState({ selectedTemplateEntity: templateEntity });
  };

  private renderTemplateSelector = () => {
    const { selectedTemplateEntity, isLoadingTemplate } = this.state;
    const { projectId } = this.props;
    return (
      <div className="run-template-selector-wrapper">
        <TemplateSelector
          disabled={isLoadingTemplate}
          handleSelection={this.handleTemplateSelection}
          selectedTemplateEntity={selectedTemplateEntity}
          projectId={projectId}
        />
        <div id="button-placeholder" className="button-group right">
          <PrimaryButton
            type="button"
            id="continue-to-plate-setup"
            disabled={!selectedTemplateEntity || isLoadingTemplate}
            onClick={this.getTemplate}
          >
            Continue to Plate Setup
          </PrimaryButton>
        </div>
      </div>
    );
  };

  render() {
    const { redirectToPlate } = this.state;
    if (redirectToPlate) {
      return <Redirect push to={`${coreRoutes.APP}${realTimePcrRoutes.QPCR}/create/plate`} />;
    }
    const { name } = this.state;
    return (
      <WorkflowPage>
        <WorkflowArea>
          <WorkflowHeader>
            <span>{name}</span>
          </WorkflowHeader>
          <WorkflowView>{this.renderTemplateSelector()}</WorkflowView>
        </WorkflowArea>
      </WorkflowPage>
    );
  }
}
function mapStateToProps(state: ReduxState) {
  return {
    projectId: getSelectedProjectId(state)
  };
}

export default connect(mapStateToProps, { initRunFromTemplate: initRunFromTemplateAction })(
  TemplateSelectorPageImpl
);
