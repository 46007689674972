import React from 'react';
import TRow from '../../../frontend-common-libs/src/components/common/tables/TRow';
import TCell from '../../../frontend-common-libs/src/components/common/tables/TCell';
import { NullableString } from '../../../frontend-common-libs/src/common/nullable-types';
import ConventionalPCRInstrumentTypeIcon from '../../instrument-row/ConventionalPCRInstrumentTypeIcon';
import styles from './styles/fleet-management-row.module.scss';

export type Props = {
  instrumentId: string;
  instrumentType: string;
  modelName: NullableString;
  instrumentName: string;
  children: [React.ReactNode, React.ReactNode];
  title?: string | null;
};
export default function FleetManagementRow(props: Readonly<Props>) {
  const { instrumentId, instrumentType, modelName, instrumentName, children, title } = props;

  const [checkbox, additionalColumns] = children;

  return (
    <TRow id={`inst-row_${instrumentId}`} tabIndex={-1} title={title}>
      {checkbox}
      <TCell className={styles.modelColumn}>
        <ConventionalPCRInstrumentTypeIcon type={instrumentType} />
        <span>{modelName}</span>
      </TCell>
      <TCell className={`${styles.nameCell} ${styles.cellText}`}>{instrumentName}</TCell>
      {additionalColumns}
    </TRow>
  );
}
