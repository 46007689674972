import React, { ChangeEvent, Component } from 'react';
import './styles/run-details.scss';
import { NullableString } from '../../../common/nullable-types';

export type Props = {
  originalNotes: string;
  setRunNotes: (notes: string, originalNotesLength: number) => void;
  runNotesError: NullableString;
  runNotes: string;
};

export class RunNotes extends Component<Props> {
  updateRunNotes = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { originalNotes, setRunNotes } = this.props;
    const newRunNotes = e.currentTarget.value;
    setRunNotes(newRunNotes, originalNotes.length);
  };

  render() {
    const { runNotes, runNotesError } = this.props;
    return (
      <div className={`input-group ${runNotesError ? 'error' : ''}`}>
        <div className="label-and-error">
          <span className="control-label">Notes</span>
          {runNotesError && (
            <span id="run-notes-error" className="error-message">
              {runNotesError}
            </span>
          )}
        </div>
        <textarea
          id="notes-field"
          placeholder="Type Notes here"
          value={runNotes || ''}
          onChange={this.updateRunNotes}
        />
      </div>
    );
  }
}
