import React, { useEffect, useRef, useState } from 'react';
import { FleetManagementRow } from '../../fleet-management-row';
import TCell from '../../../../frontend-common-libs/src/components/common/tables/TCell';
import Checkbox from '../../../../frontend-common-libs/src/common/checkbox';
import styles from './styles/manage-reservations-row.module.scss';
import InstrumentReservation from '../../InstrumentReservation';
import { ImmutableMap, InstrumentItem } from '../../../../frontend-common-libs/src/common/types';
import ReservationFacade from '../../ReservationFacade';

export type Props = {
  instrument: ImmutableMap<InstrumentItem>;
  reservation: InstrumentReservation;
  selectAllCheckbox: boolean;
  updateSelectedInstruments: (id: string, select: boolean) => void;
};

export default function ManageReservationsRow(props: Readonly<Props>) {
  const loadingProtocol = 'loading ...';
  const noProtocolAssigned = 'None';

  const { instrument, selectAllCheckbox, updateSelectedInstruments, reservation } = props;

  const [checkbox, setCheckbox] = useState<boolean>(selectAllCheckbox);
  const initialRender = useRef(true);
  const [protocolName, setProtocolName] = useState<string>(loadingProtocol);

  const instrumentId = instrument.get('id');
  const jobId = reservation.getJobId();

  const getProtocolName = async () => {
    const reservationProtocolName = await reservation.getProtocolName();
    setProtocolName(reservationProtocolName ?? noProtocolAssigned);
  };

  useEffect(() => {
    getProtocolName();
  }, [jobId]);

  const reservationState = {
    reservationShadowState: reservation.reservationShadowState,
    protocol: protocolName
  };

  const reservationFacade = new ReservationFacade({
    instrument,
    reservationState,
    userId: reservation.userId,
    reservationShadowLoaded: true
  });

  const {
    instrumentFacade,
    displayedReservationStatus,
    reservationStatusProperties,
    canManageReservation
  } = reservationFacade;
  const { modelName } = instrumentFacade;

  const canCheckboxBeDisplayed = canManageReservation;

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (canCheckboxBeDisplayed) {
      setCheckbox(selectAllCheckbox);
      updateSelectedInstruments(instrumentId, selectAllCheckbox);
    }
  }, [selectAllCheckbox]);

  useEffect(() => {
    if (!canCheckboxBeDisplayed) {
      setCheckbox(false);
      updateSelectedInstruments(instrumentId, false);
    }
  }, [canCheckboxBeDisplayed]);

  const handleSelectInstrument = (): void => {
    setCheckbox(!checkbox);
    updateSelectedInstruments(instrumentId, !checkbox);
  };

  const renderCheckboxCell = () => (
    <TCell>
      {canCheckboxBeDisplayed ? (
        <Checkbox
          id={`checkbox_${instrumentId}`}
          name={`checkbox_${instrumentId}`}
          value={checkbox}
          checked={checkbox}
          onChange={handleSelectInstrument}
        />
      ) : null}
    </TCell>
  );

  const renderReservationStatus = (): React.JSX.Element => {
    return (
      <>
        <img
          src={reservationStatusProperties.src}
          alt={reservationStatusProperties.alt}
          className={styles.statusImg}
        />
        {displayedReservationStatus}
      </>
    );
  };

  const renderProtocolName = () => {
    return protocolName;
  };

  const renderAdditionalColumns = () => (
    <>
      <TCell
        className={`${styles.commonColumn} ${styles.cellText}`}
        title={displayedReservationStatus}
      >
        {renderReservationStatus()}
      </TCell>
      <TCell className={`${styles.commonColumn} ${styles.cellText}`} title={protocolName}>
        {renderProtocolName()}
      </TCell>
    </>
  );

  const tooltipMessage =
    'Cannot cancel the reservation or modify the assigned protocol. The instrument is either in use, offline, or in an error state.';

  return (
    <FleetManagementRow
      instrumentId={instrumentId}
      instrumentType={instrument.get('type')}
      modelName={modelName}
      instrumentName={instrument.get('name')}
      title={!canCheckboxBeDisplayed ? tooltipMessage : null}
    >
      {renderCheckboxCell()}
      {renderAdditionalColumns()}
    </FleetManagementRow>
  );
}
