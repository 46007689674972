import { CFXRunTemplateActionType, CFXRUN_TEMPLATE_ADDED } from './cfx_run_template_types';
import { Dispatch, GetState } from '../types';
import RunTemplate from '../components/pcr/analysis/models/RunTemplate';
import { postRunTemplate } from '../api/run-template';
import QPcrRunTemplateCreatedTrackingEvent from '../user-analytics/QPcrRunTemplateCreatedTrackingEvent';
import Plate from '../helpers/Plate';
import { getSelectedProjectId } from '../project-management';

export default function createTemplate(runTemplate: RunTemplate) {
  return async (dispatch: Dispatch<CFXRunTemplateActionType, unknown>, getState: GetState) => {
    const projectId = getSelectedProjectId(getState());
    const id = await postRunTemplate(runTemplate);
    const plateSize = Plate.size(runTemplate.plate);
    const scanMode = Plate.scanMode(runTemplate.plate);

    new QPcrRunTemplateCreatedTrackingEvent(plateSize, scanMode).track();

    dispatch({
      type: CFXRUN_TEMPLATE_ADDED,
      payload: {
        faId: id,
        name: runTemplate.name,
        shouldRedirect: true,
        projectId
      }
    });
  };
}
