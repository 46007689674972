import React, { ComponentType, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import namedRoutes from '../../../core/routes';
import { getMaintenanceMessage as getMaintenanceMessageAction } from '../../../actions/maintenance_message/maintenance_message_actions';
import ProfileMenuContainer from '../ProfileMenuContainer';
import brLogo from '../../../../img/logo.svg';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../../frontend-common-libs/src/components/feature_flags';
import { ReduxState } from '../../../types';
import MaintenanceMessage from '../MaintenanceMessage';
import LocalStorageClient from '../../../utils/LocalStorageClient';
import { InstrumentFamilyPluginRepository } from '../../../instrument-family-plugin';
import { CorePageTitle, CoreHelpMenu } from '../../../core';
import styles from './styles/navbar.module.scss';
import OrgBreadcrumb from '../../../organization-management/organizations/org-breadcrumb/OrgBreadcrumb';

export type Props = {
  location: RouteComponentProps['location'];
  getMaintenanceMessage: typeof getMaintenanceMessageAction;
  maintenanceMessage: string;
  username: string;
};

type State = {
  showMaintenanceMessage: boolean;
};

export class NavBarImpl extends PureComponent<Props, State> {
  public static CONTENT_VERSION = 5;

  private readonly localStorageClient: LocalStorageClient;

  private readonly maintenanceMessageLocalStorageKey = 'maintenanceMessage';

  constructor(props: Props) {
    super(props);
    this.localStorageClient = new LocalStorageClient(props.username);

    const isLocalStorageContentStale = this.localStorageClient.isStale(
      NavBarImpl.CONTENT_VERSION,
      this.maintenanceMessageLocalStorageKey
    );
    const messageSettings = this.localStorageClient.getSettings(
      this.maintenanceMessageLocalStorageKey
    );
    let isShown = true;
    if (messageSettings) {
      const { isShown: userCurrentIsShown } = messageSettings;
      isShown = isLocalStorageContentStale ? true : userCurrentIsShown;
    }

    if (!messageSettings || isLocalStorageContentStale) {
      this.setDataToLocalStorage(true);
    }

    this.state = {
      showMaintenanceMessage: FeatureFlags().get(FeatureFlagKeys.MAINTENANCE_MESSAGE) && isShown
    };
  }

  async componentDidMount() {
    const { showMaintenanceMessage } = this.state;
    if (showMaintenanceMessage) {
      const { getMaintenanceMessage } = this.props;
      await getMaintenanceMessage();
    }
  }

  handleCloseMaintenanceMessage = () => {
    this.setState(
      {
        showMaintenanceMessage: false
      },
      () => {
        const { showMaintenanceMessage } = this.state;
        this.setDataToLocalStorage(showMaintenanceMessage);
      }
    );
  };

  private setDataToLocalStorage(isShown: boolean) {
    this.localStorageClient.setDataToLocalStorage(this.maintenanceMessageLocalStorageKey, {
      version: NavBarImpl.CONTENT_VERSION,
      isShown
    });
  }

  private renderPageTitle(): ReactElement {
    const { location } = this.props;
    let PageTitle: ComponentType<any> = CorePageTitle;
    let path = location.pathname;

    const pluginRoutePath = location.pathname.replace(`${namedRoutes.APP}`, '');
    const plugin = InstrumentFamilyPluginRepository.getInstance().getPluginByPath(pluginRoutePath);

    if (plugin) {
      PageTitle = plugin.getPageTitle();
      path = pluginRoutePath;
    }

    return <PageTitle path={path} className={styles.pageTitle} />;
  }

  render() {
    const { location, maintenanceMessage } = this.props;
    const { showMaintenanceMessage } = this.state;

    return (
      <header id="main-header">
        <nav id="navbar-static-top">
          <div id="header-title">
            <div className="navbar-header">
              <a
                id="br-logo"
                href="https://www.bio-rad.com"
                target="_blank"
                rel="noopener noreferrer"
                title="https://www.bio-rad.com"
              >
                <img src={brLogo} className="cursor-own" alt="Bio-Rad logo" />
              </a>
            </div>
            <h1 id="bio-rad-title">BR.io</h1>
            {this.renderPageTitle()}
          </div>
          {showMaintenanceMessage && maintenanceMessage && (
            <MaintenanceMessage
              message={maintenanceMessage}
              onClose={this.handleCloseMaintenanceMessage}
            />
          )}
          <div>
            <ul>
              <li>
                <OrgBreadcrumb />
              </li>
              <li>
                <ProfileMenuContainer />
              </li>
              <li>
                <CoreHelpMenu helpPath={location.pathname} />
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const { auth, maintenanceMessage } = state;
  return {
    username: auth.get('username'),
    maintenanceMessage: maintenanceMessage.get('message')
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getMaintenanceMessage: getMaintenanceMessageAction
    // @ts-ignore
  })(NavBarImpl)
);
