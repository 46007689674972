import {
  NullableNumber,
  NullableString
} from '../../frontend-common-libs/src/common/nullable-types';
import ptcTempoRunningIcon from '../assets/ptc-tempo-icon-running.svg';
import ptcTempoErrorIcon from '../assets/ptc-tempo-icon-warning.svg';
import ptcTempoIdleIcon from '../assets/ptc-tempo-icon-ready.svg';
import infiniteHoldIcon from '../assets/infinite-hold-status.svg';
import ptcTempoInfiniteHoldIcon from '../assets/ptc-tempo-icon-infinite-hold.svg';
import ptcTempoOfflineIcon from '../assets/ptc-tempo-icon-offline.svg';
import ptcTempoPausedIcon from '../assets/ptc-tempo-icon-paused.svg';
import ptcTempoUnknownIcon from '../assets/ptc-tempo-icon-no-badge.svg';
import runningIcon from '../assets/in-progress-status.svg';
import errorIcon from '../assets/error-status.svg';
import readyIcon from '../assets/ready-status.svg';
import connectingIcon from '../assets/connecting-status.svg';
import offlineIcon from '../assets/offline-status.svg';
import pausedIcon from '../assets/paused-status.svg';
import ptcTempo4848BlockAErrorIcon from '../assets/ptc-tempo-48-48-block-a-icon-warning.svg';
import ptcTempo4848BlockAInfiniteHoldIcon from '../assets/ptc-tempo-48-48-block-a-icon-infinite-hold.svg';
import ptcTempo4848BlockAOfflineIcon from '../assets/ptc-tempo-48-48-block-a-icon-offline.svg';
import ptcTempo4848BlockAPausedIcon from '../assets/ptc-tempo-48-48-block-a-icon-paused.svg';
import ptcTempo4848BlockAIdleIcon from '../assets/ptc-tempo-48-48-block-a-icon-ready.svg';
import ptcTempo4848BlockARunningIcon from '../assets/ptc-tempo-48-48-block-a-icon-running.svg';
import ptcTempo4848BlockAUnknownStatusIcon from '../assets/ptc-tempo-48-48-block-a-icon-no-badge.svg';
import ptcTempo4848BlockBErrorIcon from '../assets/ptc-tempo-48-48-block-b-icon-warning.svg';
import ptcTempo4848BlockBInfiniteHoldIcon from '../assets/ptc-tempo-48-48-block-b-icon-infinite-hold.svg';
import ptcTempo4848BlockBOfflineIcon from '../assets/ptc-tempo-48-48-block-b-icon-offline.svg';
import ptcTempo4848BlockBPausedIcon from '../assets/ptc-tempo-48-48-block-b-icon-paused.svg';
import ptcTempo4848BlockBIdleIcon from '../assets/ptc-tempo-48-48-block-b-icon-ready.svg';
import ptcTempo4848BlockBRunningIcon from '../assets/ptc-tempo-48-48-block-b-icon-running.svg';
import ptcTempo4848BlockBUnknownStatusIcon from '../assets/ptc-tempo-48-48-block-b-icon-no-badge.svg';

import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';
import { getTimeRemaining } from '../../frontend-common-libs/utils/instrumentUtils';
import { STATUS_EXPIRATION_WINDOW } from '../../frontend-common-libs/src/instruments/instrumentStatusTimeout';

export const TimeRemainingNA = '- - -'; // not applicable time remaining

export enum InstrumentModelEnum {
  PTCTempo4848 = 'PTCTempo4848',
  PTCTempo96 = 'PTCTempo96',
  PTCTempoDeepWell = 'PTCTempoDeepWell',
  PTCTempo384 = 'PTCTempo384'
}

export enum InstrumentModelNameEnum {
  PTCTempo4848 = 'PTC Tempo 48/48',
  PTCTempo96 = 'PTC Tempo 96',
  PTCTempoDeepWell = 'PTC Tempo Deepwell',
  PTCTempo384 = 'PTC Tempo 384'
}

export type InstrumentStatusEnumValue =
  | 'Offline'
  | 'Running'
  | 'Paused'
  | 'Error'
  | 'Idle'
  | 'Connecting...'
  | 'Infinite Hold';

export enum InstrumentStatusEnum {
  Offline = 'Offline',
  Running = 'Running',
  Paused = 'Paused',
  Error = 'Error',
  Idle = 'Idle',
  Connecting = 'Connecting...',
  InfiniteHold = 'Infinite Hold',
  Unknown = 'Unknown'
}

export type LidStatusEnumValue = 'Opened' | 'Closed' | 'Opening' | 'Closing' | '';

export enum InstrumentLidStatusEnum {
  Opened = 'Opened',
  Closed = 'Closed',
  Opening = 'Opening',
  Closing = 'Closing',
  Unknown = ''
}

export type InstrumentDetails = {
  serialNumber: string;
  softwareVersion?: string;
  mainFirmwareVersion?: string;
  powerFirmwareVersion?: string;
  apiVersion?: string;
  imageVersion?: string;
  lidFirmwareVersion?: string;
};

export enum BlockEnum {
  BlockA = 'BlockA' as any,
  BlockB = 'BlockB' as any
}

export enum BlockIdentifierEnum {
  BlockA = '_block-a',
  BlockB = '_block-b'
}

export const RunStatusMinimumSupportedMajorVersion = 3;

export const RunStatusDevSupportedMajorVersion = 0;

export function getMajorVersion(softwareVersion: string | undefined): number {
  if (softwareVersion) {
    const currentVersion = softwareVersion.split('.');
    const parsedVersion = parseInt(currentVersion[0], 10);
    if (Number.isInteger(parsedVersion)) {
      return parsedVersion;
    }
  }
  return -1;
}

function isRunInProgressState(status: NullableString): boolean {
  if (!status) return false;
  return [
    InstrumentStatusEnum.Running.toLowerCase(),
    InstrumentStatusEnum.Paused.toLowerCase()
  ].includes(status.toLowerCase());
}

function softwareVersionSupportsRunStatusPanel(softwareVersion: string | undefined): boolean {
  return (
    getMajorVersion(softwareVersion) >= RunStatusMinimumSupportedMajorVersion ||
    getMajorVersion(softwareVersion) === RunStatusDevSupportedMajorVersion
  );
}

export function isRunStatusDisplayed(
  status: NullableString,
  softwareVersion: string | undefined
): boolean {
  return isRunInProgressState(status) && softwareVersionSupportsRunStatusPanel(softwareVersion);
}

export default class InstrumentFacade {
  private readonly model: string;

  private readonly status: string;

  private readonly lidState: string;

  private readonly instrument: ImmutableMap<InstrumentItem>;

  private readonly instrumentState?: ImmutableMap<Record<string, any>>;

  private readonly deviceStatus?: ImmutableMap<Record<string, any>>;

  private readonly block?: BlockEnum;

  public constructor(instrument: ImmutableMap<InstrumentItem>, block?: BlockEnum) {
    this.instrument = instrument;
    this.model = instrument.get('model');
    this.deviceStatus = instrument.get('deviceStatus');
    this.instrumentState = instrument.getIn([
      'deviceStatus',
      'statusObject',
      'state'
    ]) as ImmutableMap<Record<string, any>>;
    this.block = block;
    this.status = this.setStatus();
    this.lidState = this.lidFromShadow(this.block);
  }

  public get details(): InstrumentDetails {
    return {
      serialNumber: this.instrument.get('serial'),
      softwareVersion: this.instrument.get('softwareVersion'),
      mainFirmwareVersion: this.instrument.getIn(['details', 'vers', 'mainFirmware']) as string,
      powerFirmwareVersion: this.instrument.getIn(['details', 'vers', 'powerFirmware']) as string,
      apiVersion: this.instrument.getIn(['details', 'vers', 'apiVersion']) as string,
      imageVersion: this.instrument.getIn(['details', 'vers', 'imageVersion']) as string,
      lidFirmwareVersion: this.instrument.getIn(['details', 'vers', 'lidFirmware']) as string
    };
  }

  public get instrumentModel(): NullableString {
    switch (this.model) {
      case 'PTCTempo4848':
        return InstrumentModelEnum.PTCTempo4848;
      case 'PTCTempo96':
        return InstrumentModelEnum.PTCTempo96;
      case 'PTCTempoDeepWell':
        return InstrumentModelEnum.PTCTempoDeepWell;
      case 'PTCTempo384':
        return InstrumentModelEnum.PTCTempo384;
      default:
        return null;
    }
  }

  public get modelName(): NullableString {
    switch (this.model) {
      case 'PTCTempo4848':
        return InstrumentModelNameEnum.PTCTempo4848;
      case 'PTCTempo96':
        return InstrumentModelNameEnum.PTCTempo96;
      case 'PTCTempoDeepWell':
        return InstrumentModelNameEnum.PTCTempoDeepWell;
      case 'PTCTempo384':
        return InstrumentModelNameEnum.PTCTempo384;
      default:
        return null;
    }
  }

  public get instrumentStatus(): NullableString {
    if (!this.status) {
      return null;
    }
    switch (this.status.toLowerCase()) {
      case InstrumentStatusEnum.Running.toLowerCase():
        return InstrumentStatusEnum.Running;
      case InstrumentStatusEnum.Error.toLowerCase():
        return InstrumentStatusEnum.Error;
      case InstrumentStatusEnum.Idle.toLowerCase():
        return InstrumentStatusEnum.Idle;
      case InstrumentStatusEnum.Offline.toLowerCase():
        return InstrumentStatusEnum.Offline;
      case InstrumentStatusEnum.Paused.toLowerCase():
        return InstrumentStatusEnum.Paused;
      case InstrumentStatusEnum.Connecting.toLowerCase():
        return InstrumentStatusEnum.Connecting;
      case InstrumentStatusEnum.InfiniteHold.toLowerCase():
        return InstrumentStatusEnum.InfiniteHold;
      default:
        return null;
    }
  }

  public get instrumentIcon(): string {
    if (this.model === 'PTCTempo4848' && this.block === BlockEnum.BlockA) {
      switch (this.instrumentStatus) {
        case InstrumentStatusEnum.Error:
          return ptcTempo4848BlockAErrorIcon;
        case InstrumentStatusEnum.Idle:
          return ptcTempo4848BlockAIdleIcon;
        case InstrumentStatusEnum.InfiniteHold:
          return ptcTempo4848BlockAInfiniteHoldIcon;
        case InstrumentStatusEnum.Offline:
          return ptcTempo4848BlockAOfflineIcon;
        case InstrumentStatusEnum.Paused:
          return ptcTempo4848BlockAPausedIcon;
        case InstrumentStatusEnum.Running:
          return ptcTempo4848BlockARunningIcon;
        default:
          return ptcTempo4848BlockAUnknownStatusIcon;
      }
    }

    if (this.model === 'PTCTempo4848' && this.block === BlockEnum.BlockB) {
      switch (this.instrumentStatus) {
        case InstrumentStatusEnum.Error:
          return ptcTempo4848BlockBErrorIcon;
        case InstrumentStatusEnum.Idle:
          return ptcTempo4848BlockBIdleIcon;
        case InstrumentStatusEnum.InfiniteHold:
          return ptcTempo4848BlockBInfiniteHoldIcon;
        case InstrumentStatusEnum.Offline:
          return ptcTempo4848BlockBOfflineIcon;
        case InstrumentStatusEnum.Paused:
          return ptcTempo4848BlockBPausedIcon;
        case InstrumentStatusEnum.Running:
          return ptcTempo4848BlockBRunningIcon;
        default:
          return ptcTempo4848BlockBUnknownStatusIcon;
      }
    }

    switch (this.instrumentStatus) {
      case InstrumentStatusEnum.Running:
        return ptcTempoRunningIcon;
      case InstrumentStatusEnum.Error:
        return ptcTempoErrorIcon;
      case InstrumentStatusEnum.Idle:
        return ptcTempoIdleIcon;
      case InstrumentStatusEnum.Offline:
        return ptcTempoOfflineIcon;
      case InstrumentStatusEnum.Paused:
        return ptcTempoPausedIcon;
      case InstrumentStatusEnum.InfiniteHold:
        return ptcTempoInfiniteHoldIcon;
      default:
        return ptcTempoUnknownIcon;
    }
  }

  public get statusIcon(): NullableString {
    switch (this.instrumentStatus) {
      case InstrumentStatusEnum.Running:
        return runningIcon;
      case InstrumentStatusEnum.Error:
        return errorIcon;
      case InstrumentStatusEnum.Idle:
        return readyIcon;
      case InstrumentStatusEnum.Connecting:
        return connectingIcon;
      case InstrumentStatusEnum.Offline:
        return offlineIcon;
      case InstrumentStatusEnum.Paused:
        return pausedIcon;
      case InstrumentStatusEnum.InfiniteHold:
        return infiniteHoldIcon;
      default:
        return null;
    }
  }

  public get timeRemaining(): string {
    if (this.isOffline()) return TimeRemainingNA;

    const blockPath = InstrumentFacade.getBlockPath(this.block);
    const time =
      this.instrumentState &&
      this.instrumentState.getIn(['reported', 'details', blockPath, 'timeRemainingSecs']);

    return getTimeRemaining(time as number);
  }

  public get lidStatus(): NullableString {
    if (!this.lidState) {
      return null;
    }
    switch (this.lidState.toLowerCase()) {
      case InstrumentLidStatusEnum.Opened.toLowerCase():
        return InstrumentLidStatusEnum.Opened;
      case InstrumentLidStatusEnum.Closed.toLowerCase():
        return InstrumentLidStatusEnum.Closed;
      case InstrumentLidStatusEnum.Opening.toLowerCase():
        return InstrumentLidStatusEnum.Opening;
      case InstrumentLidStatusEnum.Closing.toLowerCase():
        return InstrumentLidStatusEnum.Closing;
      default:
        return InstrumentLidStatusEnum.Unknown;
    }
  }

  public lidFromShadow(block?: BlockEnum): string {
    if (this.instrumentState) {
      return this.instrumentState.getIn([
        'reported',
        'details',
        InstrumentFacade.getBlockPath(block),
        'lid'
      ]) as string;
    }
    return '';
  }

  public get runCurrentStep(): NullableNumber {
    if (this.instrumentState) {
      return this.instrumentState.getIn([
        'reported',
        'details',
        InstrumentFacade.getBlockPath(this.block),
        'step'
      ]) as number;
    }
    return null;
  }

  public get runTotalSteps(): NullableNumber {
    if (this.instrumentState) {
      return this.instrumentState.getIn([
        'reported',
        'details',
        InstrumentFacade.getBlockPath(this.block),
        'tstep'
      ]) as number;
    }
    return null;
  }

  public get runCurrentCycle(): NullableNumber {
    if (this.instrumentState) {
      return this.instrumentState.getIn([
        'reported',
        'details',
        InstrumentFacade.getBlockPath(this.block),
        'cyc'
      ]) as number;
    }
    return null;
  }

  public get runTotalCycles(): NullableNumber {
    if (this.instrumentState) {
      return this.instrumentState.getIn([
        'reported',
        'details',
        InstrumentFacade.getBlockPath(this.block),
        'tcyc'
      ]) as number;
    }
    return null;
  }

  public get runSampleTemp(): NullableNumber {
    if (this.instrumentState) {
      return this.instrumentState.getIn([
        'reported',
        'details',
        InstrumentFacade.getBlockPath(this.block),
        'stemp'
      ]) as number;
    }
    return null;
  }

  public get runUserId(): NullableString {
    if (this.instrumentState) {
      return this.instrumentState.getIn(['reported', 'userID']) as string;
    }
    return null;
  }

  public get isConnecting(): boolean {
    return this.instrumentStatus === InstrumentStatusEnum.Connecting;
  }

  public isOffline(): boolean {
    if (this.deviceStatus) {
      if (this.hasBlockTimestamp(BlockEnum.BlockB)) {
        return this.isBlockOffline(BlockEnum.BlockA) && this.isBlockOffline(BlockEnum.BlockB);
      }
      return this.isBlockOffline(BlockEnum.BlockA);
    }
    return false;
  }

  public setStatus(): string {
    switch (true) {
      case !this.deviceStatus:
        return InstrumentStatusEnum.Connecting;
      case this.isOffline():
        return InstrumentStatusEnum.Offline;
      default:
        return this.statusFromShadow(this.block);
    }
  }

  public statusFromShadow(block?: BlockEnum): string {
    if (this.instrumentState) {
      return this.instrumentState.getIn([
        'reported',
        'details',
        InstrumentFacade.getBlockPath(block),
        'status'
      ]) as string;
    }
    return '';
  }

  private static getBlockPath(block?: BlockEnum): string {
    if (block && block === BlockEnum.BlockB) {
      return 'blkB';
    }
    return 'blkA';
  }

  private static lastStatusTimestampPath(block: BlockEnum): Array<string> {
    return [
      'statusObject',
      'metadata',
      'reported',
      'details',
      InstrumentFacade.getBlockPath(block),
      'status',
      'timestamp'
    ];
  }

  private hasBlockTimestamp(block: BlockEnum): boolean {
    if (this.deviceStatus) {
      const lastStatusTimeStamp = this.deviceStatus.getIn(
        InstrumentFacade.lastStatusTimestampPath(block)
      ) as number;

      return !!lastStatusTimeStamp;
    }
    return false;
  }

  private isBlockOffline(block: BlockEnum): boolean {
    if (this.deviceStatus) {
      const lastStatusTimeStamp = this.deviceStatus.getIn(
        InstrumentFacade.lastStatusTimestampPath(block)
      ) as number;
      // AWS returns sec in timestamp. convert to MS for proper calculation
      return Date.now() - lastStatusTimeStamp * 1000 > STATUS_EXPIRATION_WINDOW;
    }
    return false;
  }

  public timeoutUntilExpiration(block: BlockEnum = BlockEnum.BlockA): number {
    if (this.deviceStatus) {
      const lastStatusTimeStamp = this.deviceStatus.getIn(
        InstrumentFacade.lastStatusTimestampPath(block)
      ) as number;
      const lastStatusTimeStampInMillis = lastStatusTimeStamp * 1000;
      return STATUS_EXPIRATION_WINDOW - (Date.now() - lastStatusTimeStampInMillis);
    }
    return 0;
  }

  public dualBlockInstrumentTimeout(blockATimeout: number): number {
    // In a dual block instrument, the instrument goes offline when both blocks A and B are Offline
    // This can be interpreted as when the last active block goes offline, both blocks go offline
    // therefore, use the last active updated timestamp of block A or B as the instrument timeout
    return Math.max(blockATimeout, this.timeoutUntilExpiration(BlockEnum.BlockB));
  }

  public get runId(): NullableString {
    if (this.instrumentState) {
      return this.instrumentState.getIn([
        'reported',
        'details',
        InstrumentFacade.getBlockPath(this.block),
        'runID'
      ]) as string;
    }
    return null;
  }
}
