import ProtocolUpload from '../../../frontend-common-libs/src/file-operations/upload-protocol/ProtocolUpload';
import { UserFile } from '../../../frontend-common-libs/src/common/types';
import { creatQpcrProtocolFromFile } from '../../../api/pcrData';
import { PROTOCOL_TEMPLATES_ADD } from '../../../actions/protocolTemplate_types';
import { ProjectId } from '../../../frontend-common-libs/src/common/project-management-types';
import { Dispatch } from '../../../types';
import {
  TrackedFilesEvents,
  trackEvent
} from '../../../frontend-common-libs/src/user-analytics/trackedEvents';

export default class QpcrProtocolUpload extends ProtocolUpload {
  protected readonly dispatch: Dispatch<any, any>;

  constructor(file: File, projectId: ProjectId, dispatch: Dispatch<any, any>) {
    super(file, projectId);
    this.dispatch = dispatch;
  }

  async createProtocolFromFile(buffer: Record<string, any>): Promise<UserFile> {
    const filename = this.getFileNameNoExtension();
    const res = await creatQpcrProtocolFromFile(filename, buffer, this.projectId);
    return res.faEntity;
  }

  notifyProtocolCreated(entity: UserFile) {
    this.dispatch({
      type: PROTOCOL_TEMPLATES_ADD,
      payload: {
        entity,
        projectId: this.projectId
      }
    });
    trackEvent(TrackedFilesEvents.CfxUploadProtocol, { fileName: entity.name });
  }
}
