import { connect } from 'react-redux';
import { Map } from 'immutable';
import React, { PureComponent } from 'react';
import getProtocolTemplatesIfNeededAction, {
  archiveProtocolTemplates,
  renameProtocolTemplate,
  exportProtocol,
  uploadProtocols,
  moveCfxProtocolToProject as moveCfxProtocolToProjectAction
} from '../../actions/protocolTemplate_actions';
import { ReduxState } from '../../types';
import { getSelectedProjectId } from '../../project-management';
import {
  ManageProtocols,
  ManageProtocolsProps
} from '../../frontend-common-libs/src/components/pcr/ManageProtocols';
import coreRoutes from '../../core/routes';
import realTimePcrRoutes from '../../real-time-pcr/routes';
import UploadHandlerRepository from '../../frontend-common-libs/src/file-operations/upload-files/UploadHandlerRepository';
import UploadHandler from '../../frontend-common-libs/src/file-operations/upload-files/UploadHandler';
import {
  canMoveFileToProject,
  getSelectedProjectName
} from '../../project-management/selectors/selectors';
import { UserFile } from '../../frontend-common-libs/src/common/types';
import MoveFileToProjectVm from '../../project-management/manage-projects/move-file-to-project/MoveFileToProjectVm';
import MoveFileToProject from '../../project-management/manage-projects/move-file-to-project/MoveFileToProject';

export type Props = {
  canMoveFile: boolean;
  moveCfxProtocolToProject: (entity: UserFile, projectId: string) => any;
} & ManageProtocolsProps;

export type State = {
  moveFileToProjectVm: MoveFileToProjectVm;
};
export class ManageCfxProtocolsImpl extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    if (props.uploadProtocols != null) {
      UploadHandlerRepository.instance.addHandler(
        new UploadHandler(
          `${coreRoutes.APP}${realTimePcrRoutes.QPCR_PROTOCOL_LIST}`,
          props.uploadProtocols,
          'Upload CFX Protocols',
          '.prcl'
        )
      );
    }
    this.state = { moveFileToProjectVm: new MoveFileToProjectVm(props.moveCfxProtocolToProject) };
  }

  onMoveFile = (file: UserFile) => {
    const { moveFileToProjectVm } = this.state;
    moveFileToProjectVm.showMoveFileToProject(file);
  };

  render() {
    const { canMoveFile } = this.props;
    const { moveFileToProjectVm } = this.state;
    const moveFile = canMoveFile ? this.onMoveFile : undefined;
    const manageProtocolsProps = { ...this.props, moveFile };
    return (
      <>
        <ManageProtocols {...manageProtocolsProps} />
        {canMoveFile && <MoveFileToProject moveFileToProjectVm={moveFileToProjectVm} />}
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  const projectProtocols = state.protocolTemplates.getIn(['projects', projectId]) as Map<
    string,
    any
  >;
  return {
    isLoading: projectProtocols.get('isLoading'),
    protocols: projectProtocols.get('protocols').valueSeq(),
    lastSeen: projectProtocols.get('lastSeen'),
    errorMessage: projectProtocols.get('errorMessage'),
    staleData: projectProtocols.get('staleData'),
    loaded: projectProtocols.get('loaded'),
    projectId,
    projectName: getSelectedProjectName(state),
    createProtocolRoute: `${coreRoutes.APP}${realTimePcrRoutes.CREATE_QPCR_PROTOCOL}`,
    openProtocolRoute: `${coreRoutes.APP}${realTimePcrRoutes.QPCR_PROTOCOL}`,
    canMoveFile: canMoveFileToProject(state)
  };
}

export default connect(mapStateToProps, {
  getProtocolsIfNeeded: getProtocolTemplatesIfNeededAction,
  archiveProtocols: archiveProtocolTemplates,
  renameProtocol: renameProtocolTemplate,
  exportProtocol,
  uploadProtocols,
  moveCfxProtocolToProject: moveCfxProtocolToProjectAction
  // @ts-ignore
})(ManageCfxProtocolsImpl);
