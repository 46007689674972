import requests from '../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../frontend-common-libs/src/config/config';
import { getEntityJWT } from '../frontend-common-libs/src/api/entities';
import { QPCRProtocol } from '../frontend-common-libs/src/components/pcr/pcr_protocol_types';
import {
  ProjectId,
  DEFAULT_PROJECT_ID
} from '../frontend-common-libs/src/common/project-management-types';
import { addAccessTokenToHeaders } from '../organization-management/api/access-control';

const domain = GATEWAY.PCR_PROTOCOL_SERVICE_PATHS.BASE_URL;
const JWT_HEADER = 'x-biorad-jwt';

async function createAdditionParams(id: string): Promise<any> {
  const token = await getEntityJWT(id);
  return {
    headers: {
      [JWT_HEADER]: token
    }
  };
}

export async function getProtocol(
  entityID: string,
  protocolID: string,
  rundef = false
): Promise<any> {
  const path = GATEWAY.PCR_PROTOCOL_SERVICE_PATHS.GET_PROTOCOL_URL(protocolID);

  let additionalParams = await createAdditionParams(entityID);
  if (rundef) additionalParams = { ...additionalParams, queryParams: { f: 'rundef' } };
  return requests.get(domain, {}, path, additionalParams);
}

export async function putProtocol(
  entityId: string,
  protocolId: string,
  protocol: QPCRProtocol,
  name: string,
  versionNumber: number,
  projectAccessToken?: string
): Promise<any> {
  const path = GATEWAY.PCR_PROTOCOL_SERVICE_PATHS.PUT_PROTOCOL_URL(protocolId);
  let additionalParams = await createAdditionParams(entityId);
  additionalParams = addAccessTokenToHeaders(projectAccessToken, additionalParams);
  const body = { ...protocol, filename: name, versionNumber };
  return requests.put(domain, {}, path, additionalParams, body);
}

export function postProtocol(
  name: string,
  protocol: QPCRProtocol,
  projectId: ProjectId = DEFAULT_PROJECT_ID
): Promise<any> {
  const path = GATEWAY.PCR_PROTOCOL_SERVICE_PATHS.CREATE_PROTOCOL_URL;
  const body = { ...protocol, filename: name, parentId: projectId };
  return requests.post(domain, {}, path, {}, body);
}
