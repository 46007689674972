import Reservation, { ReservationShadowState } from './Reservation';
import Job from './Job';
import { DesiredBlock, IncomingMessage, Metadata, ReportedBlock } from './iot/incomingMessage';
import { TopicStub } from '../../frontend-common-libs/src/iot/InstrumentNamedShadow';

type ComponentCallback = (fleetInstrument: InstrumentReservation) => void;
type ReservationCallback = (topic: string, payload: unknown) => void;

export default class InstrumentReservation {
  public readonly userId: string;

  private readonly _instrumentId: string;

  private readonly componentCallback: ComponentCallback;

  private readonly reservation: Reservation;

  public reservationShadowState: ReservationShadowState | null = null;

  private job: Job | null = null;

  private _protocolName: string | null = null;

  private _isLoaded = false;

  public constructor(userId: string, instrumentId: string, componentCallback: ComponentCallback) {
    this.userId = userId;
    this._instrumentId = instrumentId;
    this.componentCallback = componentCallback;
    this.reservation = new Reservation();
  }

  public async connect(): Promise<void> {
    try {
      const reservationCallback = this.createReservationCallback();
      await this.reservation.connect(this._instrumentId, reservationCallback);

      this.reservationShadowState = await Reservation.getCurrentReservation(this._instrumentId);
      if (this.reservationShadowState?.jobId) {
        this.job = new Job(this.userId, this.reservationShadowState.jobId);
        this._protocolName = await this.job.getProtocolName();
      }

      if (!this.reservationShadowState.desired) {
        this.reservationShadowState.desired = { metadata: {} };
      }

      this._isLoaded = true;
    } catch {
      console.error(`Failed to get reservation for instrument ${this._instrumentId}`);
    }
  }

  public async disconnect(topicStub: TopicStub): Promise<void> {
    await this.reservation.disconnect(this._instrumentId, topicStub);
    this._isLoaded = false;
  }

  public get isLoaded(): boolean {
    return this._isLoaded;
  }

  public async reserve(): Promise<void> {
    await this.reservation.create(this.instrumentId, this.userId);
  }

  public async unreserve(): Promise<void> {
    await this.reservation.unreserve(this.instrumentId);
    if (this.job?.jobId !== null) {
      await this.unassignProtocol();
    }
  }

  public async assignProtocol(entityId: string, protocolName: string): Promise<void> {
    this._protocolName = protocolName;
    try {
      this.job = await Job.create(this.instrumentId, this.userId, entityId);
      // @ts-ignore possibly undefined
      await this.reservation.addJob(this.instrumentId, this.job?.jobId);
    } catch (error) {
      throw new Error(`Failed to create job for instrument ${this.instrumentId}`);
    }
  }

  public async unassignProtocol(): Promise<void> {
    if (this.job) {
      try {
        await this.job.delete();
        await this.reservation.removeJob(this.instrumentId);
        this.job = null;
        this._protocolName = null;
      } catch (error) {
        throw new Error(`Failed to unassign protocol for instrument ${this.instrumentId}`);
      }
    }
  }

  public get instrumentId(): string {
    return this._instrumentId;
  }

  public get protocolName(): string | null {
    return this._protocolName;
  }

  private createReservationCallback(): ReservationCallback {
    // give callback a name in listener array
    const reservationUpdateTopicCallback = (topic: string, payload: unknown) => {
      const payloadJson: IncomingMessage = JSON.parse((payload as Buffer).toString());
      if (topic.includes(`/accepted`)) {
        this.updateShadowStateWithIncomingMessage(payloadJson);
        this.componentCallback(this);
      }
    };
    return reservationUpdateTopicCallback;
  }

  private updateReportedShadowState(reportedBlock: ReportedBlock): void {
    const reportedInstrumentBlock = reportedBlock.instrument;
    const reportedReservationBlock = reportedBlock.reservation;

    if (this.reservationShadowState && reportedReservationBlock) {
      if (reportedReservationBlock.userID) {
        this.reservationShadowState.userId = reportedReservationBlock.userID;
      }
      if (reportedReservationBlock.reserved !== undefined) {
        this.reservationShadowState.reserved = reportedReservationBlock.reserved;
      }
      if (reportedReservationBlock.job !== undefined) {
        this.reservationShadowState.jobId = reportedReservationBlock.job?.jobId ?? null;
        this.job = new Job(this.userId, this.reservationShadowState.jobId);
        this.getProtocolName();
      }
    }
    if (this.reservationShadowState && reportedInstrumentBlock?.loggedIn !== undefined) {
      this.reservationShadowState.loggedIn = reportedInstrumentBlock.loggedIn;
    }
  }

  private updateDesiredShadowState(desiredBlock: DesiredBlock, metadata: Metadata): void {
    const desiredReservationBlock = desiredBlock.reservation;
    const metadataDesiredReservationBlock = metadata.desired.reservation;

    if (
      this.reservationShadowState &&
      this.reservationShadowState.desired &&
      desiredReservationBlock
    ) {
      const { userID, reserved, job } = desiredReservationBlock;
      if (userID !== undefined) {
        this.reservationShadowState.desired.userId = userID;
        this.reservationShadowState.desired.metadata.userId =
          metadataDesiredReservationBlock?.userID.timestamp;
      }
      if (reserved !== undefined) {
        this.reservationShadowState.desired.reserved = reserved;
        this.reservationShadowState.desired.metadata.reserved =
          metadataDesiredReservationBlock?.reserved.timestamp;
      }
      if (job !== undefined) {
        this.reservationShadowState.desired.jobId = job?.jobId ?? null;
        this.job = new Job(this.userId, this.reservationShadowState.desired.jobId);
        this.getProtocolName();
      }
    }
  }

  private updateShadowStateWithIncomingMessage(incomingMessage: IncomingMessage): void {
    const reportedBlock = incomingMessage.state?.reported;
    const desiredBlock = incomingMessage.state?.desired;
    const { metadata } = incomingMessage;

    if (reportedBlock) {
      this.updateReportedShadowState(reportedBlock);
    }
    if (desiredBlock) {
      this.updateDesiredShadowState(desiredBlock, metadata);
    }
  }

  public isReservedByUser(): boolean {
    return (
      this.reservationShadowState?.reserved === true &&
      this.reservationShadowState?.userId === this.userId
    );
  }

  public getJobId(): string | null {
    return this.job?.jobId ?? null;
  }

  public async getProtocolName(): Promise<string | null> {
    if (!this.job?.jobId) {
      this._protocolName = null;
    } else {
      this._protocolName = (await this.job?.getProtocolName()) ?? null;
    }
    return this._protocolName;
  }

  public async startRun(): Promise<void> {
    await this.reservation.startReservationRun(this.instrumentId);
  }

  public async instrumentOpenLid(): Promise<void> {
    await this.reservation.openLid(this.instrumentId);
  }

  public async instrumentCloseLid(): Promise<void> {
    await this.reservation.closeLid(this.instrumentId);
  }

  public async stopRun(): Promise<void> {
    await this.reservation.stopReservationRun(this.instrumentId);
  }

  public async skipStep(): Promise<void> {
    await this.reservation.skipStep(this.instrumentId);
  }
}
