import { Map, fromJS, List } from 'immutable';
import { UserPermissions } from '@biorad-lsg-tsc/organization';
import {
  PROJECT_ADDED,
  PROJECT_REMOVED,
  PROJECT_SELECTED,
  ProjectManagementActionTypes,
  PROJECTS_LOADED,
  PROJECTS_LOADING,
  PROJECT_DETAILS_LOADING,
  PROJECT_DETAILS_LOADED,
  PROJECT_USER_ADDED,
  PROJECT_TOKEN_LOADED,
  PROJECT_TOKEN_LOADING,
  PROJECT_MEMBER_ROLE_CHANGED,
  PROJECT_USER_REMOVED,
  PROJECT_RENAMED,
  PROJECT_TOKEN_REVOKED
} from '../actions/action-types';
import { ApiAction } from '../../types';
import { UNAUTH_USER } from '../../auth/actions/auth_types';
import { ORG_REMOVED, ORG_RENAMED } from '../../organization-management/actions/action-types';

const INITIAL_STATE: Map<string, any> = Map({});

const onProjectsLoading = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { orgId } = action.payload;
  return state.setIn(['projects', orgId, 'loading'], true);
};

const sortProjectsByName = (projects: List<any>) => {
  return projects.sortBy(p => p.get('name'));
};

const sortUsersByName = (projectDetails: Map<string, any>) => {
  if (!projectDetails) return projectDetails;
  let users = projectDetails.get('users') as List<Map<string, any>>;
  if (!users) return projectDetails;
  users = users.sortBy(u => u.get('userEmail'));
  return projectDetails.set('users', users);
};

const onProjectsLoaded = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { orgId, orgName } = action.payload;
  const projects = sortProjectsByName(fromJS(action.payload.projects) as List<any>);
  return state
    .setIn(['projects', orgId, 'loading'], false)
    .setIn(['projects', orgId, 'projects'], projects)
    .setIn(['projects', orgId, 'orgName'], orgName);
};

const onProjectAdded = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { project, orgId } = action.payload;
  const projects = state.getIn(['projects', orgId, 'projects']) as List<any>;
  if (!projects) return state;
  const filteredProjects = projects.filter(proj => proj.get('id') !== project.id);
  const projectsNew = sortProjectsByName(filteredProjects.unshift(fromJS(project)));
  return state.setIn(['projects', orgId, 'projects'], projectsNew);
};

const onProjectRemoved = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId, orgId } = action.payload;
  const projects = state.getIn(['projects', orgId, 'projects']) as List<any>;
  if (!projects) return state;
  const filteredProjects = projects.filter(proj => proj.get('id') !== projectId);
  return state
    .setIn(['projects', orgId, 'projects'], filteredProjects)
    .removeIn(['details', projectId]);
};

const onProjectRenamed = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId, name, orgId } = action.payload;
  let projects = state.getIn(['projects', orgId, 'projects']) as List<any>;
  if (!projects) return state;
  projects = projects.map(project =>
    project.get('id') === projectId ? project.set('name', name) : project
  );
  const projectsNew = sortProjectsByName(projects);
  return state.setIn(['projects', orgId, 'projects'], projectsNew);
};

function onChangeProjectSelection(state: Map<string, any>, action: any) {
  const { projectId } = action.payload;
  return state.set('selectedProjectId', projectId);
}

const onProjectDetailsLoading = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId } = action.payload;
  return state.setIn(['details', projectId, 'loading'], true);
};

const onProjectDetailsLoaded = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId, details } = action.payload;
  const detailsMap = sortUsersByName(fromJS(details) as Map<string, any>);
  return state
    .setIn(['details', projectId, 'loading'], false)
    .setIn(['details', projectId, 'details'], detailsMap);
};

const onProjectUserAdded = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId, userName, userRole } = action.payload;
  let detailsMap = state.getIn(['details', projectId, 'details']) as Map<string, any>;
  if (!detailsMap) return state;
  let projectUsers = detailsMap.get('users') as List<any>;
  if (!projectUsers) return state;
  projectUsers = projectUsers.filter(user => user.get('userEmail') !== userName);
  projectUsers = projectUsers.unshift(fromJS({ userEmail: userName, userRole }));
  detailsMap = detailsMap.set('users', projectUsers);
  return state.setIn(['details', projectId, 'details'], sortUsersByName(detailsMap));
};

const onProjectMemberRoleChanged = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId, userName, role } = action.payload;
  let users = state.getIn(['details', projectId, 'details', 'users']) as List<any>;
  if (!users) return state;
  users = users.map(user =>
    user.get('userEmail') === userName ? user.set('userRole', role) : user
  );
  return state.setIn(['details', projectId, 'details', 'users'], users);
};

const onProjectUserRemoved = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId, userName } = action.payload;
  const users = state.getIn(['details', projectId, 'details', 'users']) as List<any>;
  if (!users) return state;
  const filteredUsers = users.filter(user => user.get('userEmail') !== userName);
  return state.setIn(['details', projectId, 'details', 'users'], filteredUsers);
};

const onProjectTokenLoading = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId } = action.payload;
  return state.setIn(['details', projectId, 'loadingProjectAccessToken'], true);
};

const onProjectTokenLoaded = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId, loginInfo } = action.payload;
  const { userToken } = loginInfo;
  const permissions = new UserPermissions(userToken.accessToken);
  return state
    .setIn(['details', projectId, 'userPermissions'], permissions)
    .setIn(['details', projectId, 'userToken'], userToken)
    .setIn(['details', projectId, 'loadingProjectAccessToken'], false);
};

const onProjectTokenRevoked = (
  state: Map<string, any>,
  action: ApiAction<ProjectManagementActionTypes, any>
) => {
  const { projectId } = action.payload;
  return state
    .removeIn(['details', projectId, 'userPermissions'])
    .removeIn(['details', projectId, 'userToken'])
    .removeIn(['details', projectId, 'loadingProjectAccessToken']);
};

const onOrgRenamed = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { orgId, name } = action.payload;
  const orgName = state.getIn(['projects', orgId, 'orgName']);
  if (!orgName) return state;
  return state.setIn(['projects', orgId, 'orgName'], name);
};

const onOrgRemoved = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { orgId } = action.payload;
  return state.removeIn(['projects', orgId]);
};

const resetState = () => INITIAL_STATE;

const actionMap = {
  [PROJECTS_LOADING]: onProjectsLoading, // loading user projects
  [PROJECTS_LOADED]: onProjectsLoaded, // user projects loaded
  [PROJECT_SELECTED]: onChangeProjectSelection,
  [PROJECT_ADDED]: onProjectAdded,
  [PROJECT_REMOVED]: onProjectRemoved,
  [PROJECT_RENAMED]: onProjectRenamed,
  [PROJECT_DETAILS_LOADING]: onProjectDetailsLoading, // loading project details
  [PROJECT_DETAILS_LOADED]: onProjectDetailsLoaded, // project details loaded
  [PROJECT_USER_ADDED]: onProjectUserAdded,
  [PROJECT_USER_REMOVED]: onProjectUserRemoved,
  [PROJECT_MEMBER_ROLE_CHANGED]: onProjectMemberRoleChanged,
  [PROJECT_TOKEN_LOADING]: onProjectTokenLoading,
  [PROJECT_TOKEN_LOADED]: onProjectTokenLoaded,
  [PROJECT_TOKEN_REVOKED]: onProjectTokenRevoked,
  [ORG_RENAMED]: onOrgRenamed,
  [ORG_REMOVED]: onOrgRemoved,
  [UNAUTH_USER]: resetState // User logged out
};

export default function projectsReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
