import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes, { ROUTE_NOT_FOUND } from '../routes';
import { ProtocolListPage } from '../protocol/ProtocolListPage';
import CreateProtocolPage from '../protocol/CreateProtocolPage';
import PageNotFound from '../../frontend-common-libs/src/components/pages/PageNotFound';
import EditProtocolPage from '../protocol/EditProtocolPage';
import IoTInitializer from '../communication/iot/IoTInitializer';
import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import RunPage from '../completed-run/run/RunPage';

export type Props = {
  selectedProjectId: ProjectId;
  rootPath?: string;
};

export default function Router(props: Props): ReactElement {
  const { rootPath } = props;

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${rootPath}${routes.PCR_PROTOCOL_LIST}`}
          // @ts-ignore
          render={() => <ProtocolListPage {...props} />}
        />
        <Route
          exact
          path={`${rootPath}${routes.CREATE_PCR_PROTOCOL}`}
          render={() => <CreateProtocolPage {...props} />}
        />
        <Route exact path={`${rootPath}${routes.EDIT_PCR_PROTOCOL}`} component={EditProtocolPage} />
        <Route path={`${rootPath}${routes.VIEW_PCR}`} component={RunPage} />
        <Route path={ROUTE_NOT_FOUND} component={PageNotFound} />
      </Switch>
      <IoTInitializer />
    </>
  );
}
