import React, { useState } from 'react';
import { Map } from 'immutable';
import InstrumentReservation from '../InstrumentReservation';
import styles from './styles/instrument-control.module.scss';
import InstrumentControlTable from '../instrument-control-tab/instrument-control-table/InstrumentControlTable';
import { NoReservedInstrumentsMessage } from '../no-reserved-instruments-message';
import { ImmutableMap, InstrumentItem } from '../../../frontend-common-libs/src/common/types';
import Actions from '../instrument-control-tab/actions/Actions';

export type Props = {
  instruments: ImmutableMap<InstrumentItem>[];
  reservations: Map<string, InstrumentReservation>;
};

export default function InstrumentControl(props: Readonly<Props>) {
  const { instruments, reservations } = props;
  const hasReservedInstruments = reservations.size > 0;

  const [selectedInstruments, setSelectedInstruments] = useState<Array<string>>([]);

  const updateSelectedInstruments = (id: string, select: boolean): void => {
    const isInstrumentSelected = selectedInstruments.includes(id);
    setSelectedInstruments(previousSelection => {
      if (select && !isInstrumentSelected) {
        return [...previousSelection, id];
      }
      if (!select) {
        return previousSelection.filter(instrumentId => instrumentId !== id);
      }
      return previousSelection;
    });
  };

  const reservedInstruments = instruments.filter(instrument => {
    const instrumentId = instrument.get('id');
    return reservations.has(instrumentId);
  });

  return (
    <div
      className={`flex-column-container ${styles.instrumentControlContainer}`}
      data-testid="instrument-control-container"
    >
      {hasReservedInstruments ? (
        <div className={styles.instrumentControlTableContainer}>
          <InstrumentControlTable
            instruments={reservedInstruments}
            reservations={reservations}
            updateSelectedInstruments={updateSelectedInstruments}
          />
          <Actions
            instruments={reservedInstruments}
            reservations={reservations}
            selectedInstruments={selectedInstruments}
          />
        </div>
      ) : (
        <NoReservedInstrumentsMessage />
      )}
    </div>
  );
}
