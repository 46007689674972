import React from 'react';
import {
  InstrumentFamilyPluginRepository,
  InstrumentFamilyPlugin
} from '../../instrument-family-plugin';
import CreateExperimentCard from '../../frontend-common-libs/src/components/create-experiment/CreateExperimentCard';

export default function CreateExperiment() {
  return (
    <div className="create-experiment-page">
      <span id="create-experiment-header" className="create-experiment-header">
        Define Your Experiment
      </span>
      {InstrumentFamilyPluginRepository.getInstance()
        .getAll()
        .map((plugin: InstrumentFamilyPlugin) => {
          const { createExperimentCard, name } = plugin;

          if (!createExperimentCard) {
            return null;
          }

          const {
            title,
            primaryLinks,
            secondaryLinks,
            instrumentThumbnail: instrumentThumbnailRelativePath
          } = createExperimentCard;

          const instrumentThumbnailAbsolutePath = plugin.createInstrumentThumbnailUrl(
            instrumentThumbnailRelativePath
          );

          return (
            <CreateExperimentCard
              id={`${name}-card`}
              key={name}
              img={instrumentThumbnailAbsolutePath}
              header={title}
              primaryLinks={primaryLinks}
              secondaryLinks={secondaryLinks}
            />
          );
        })}
    </div>
  );
}
