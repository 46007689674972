import { useEffect, useRef } from 'react';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';
import { ReservationStatusEnum } from './ReservationFacade';
import InstrumentReservation from './InstrumentReservation';

export type Props = {
  instrument: ImmutableMap<InstrumentItem>;
  reservation: InstrumentReservation;
  reservationStatus: string;
  reservationTimeout: number | null;
  cancellationTimeout: number | null;
};
export default function ReservationTimer({
  instrument,
  reservation,
  reservationStatus,
  reservationTimeout,
  cancellationTimeout
}: Props) {
  const reservationTimer = useRef<any>();

  const instrumentName = instrument.get('name');
  const reservationPendingTimeoutCallback = async () => {
    try {
      await reservation.unreserve();
      notification.info(
        `Your reservation request for ${instrumentName} has timed out. Try again.`,
        {
          autoClose: false
        }
      );
    } catch (error) {
      console.log('Error when unreserve', error);
    }
  };

  const cancelationPendingTimeoutCallback = async () => {
    try {
      await reservation.reserve();
      notification.info(
        `Your reservation cancellation request for ${instrumentName} has timed out. Try again.`,
        {
          autoClose: false
        }
      );
    } catch (error) {
      console.log('Error when re-reserving', error);
    }
  };

  const clearTimer = () => {
    if (reservationTimer.current) {
      clearTimeout(reservationTimer.current);
    }
  };

  useEffect(() => {
    if (
      reservationStatus === ReservationStatusEnum.ReservationPending &&
      reservationTimeout != null
    ) {
      reservationTimer.current = setTimeout(async () => {
        await reservationPendingTimeoutCallback();
      }, reservationTimeout);
    }

    return clearTimer;
  }, [reservationStatus]);

  useEffect(() => {
    if (
      reservationStatus === ReservationStatusEnum.CancellationPending &&
      cancellationTimeout != null
    ) {
      reservationTimer.current = setTimeout(async () => {
        await cancelationPendingTimeoutCallback();
      }, cancellationTimeout);
    }

    return clearTimer;
  }, [reservationStatus]);

  return null;
}
