import RunTemplate from '../components/pcr/analysis/models/RunTemplate';
import { GATEWAY } from '../frontend-common-libs/src/config/config';
import requests from '../frontend-common-libs/src/utils/httpUtils';

const domain = GATEWAY.QPCRDATA_SERVICE_PATHS.BASE_URL;

export async function postRunTemplate(runTemplate: RunTemplate) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.CREATE_RUN_TEMPLATE;
  const body = runTemplate.toJS();

  const response = await requests.post(domain, {}, path, {}, body);
  const { id } = response;
  return id;
}

export async function getRunTemplate(entityId: string) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.GET_RUN_TEMPLATE(entityId);
  return requests.get(domain, {}, path, {});
}
