import React from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import _ from 'lodash';
import { Map, Seq } from 'immutable';
import ProtocolTemplatesLoader from '../ProtocolTemplatesLoader';
import { QpcrProtocolImpl } from '../pcr-protocols/QpcrProtocol';
import Loader from '../../../frontend-common-libs/src/components/common/Loader';
import { getPCRProtocolIfNeeded as getPCRProtocolIfNeededAction } from '../../../actions/pcr_protocol_actions';
import notification from '../../../frontend-common-libs/src/utils/notifications';
import InfiniteScrollSelect from '../../../frontend-common-libs/src/components/common/dropdown/InfiniteScrollSelect';
import {
  formatId,
  SelectMenuItem
} from '../../../frontend-common-libs/src/components/common/dropdown';
import {
  setProtocolIsUpdating as setProtocolIsUpdatingAction,
  updateProtocol as updateProtocolAction
} from '../../../actions/currentCfxRun_actions';
import {
  getIsRunFromTemplate,
  isPendingRun as getIsPendingRun
} from '../../../selectors/selectors';
import { PrimaryButton } from '../../../frontend-common-libs/src/components/common/buttons';
import { ReduxState } from '../../../types';

export type Props = {
  entityId?: string;
  showProtocolSelector?: boolean;
  protocolEntity?: Map<string, any>;
  protocol?: Map<string, any>;
  protocolIsUpdating: boolean;
  setProtocolIsUpdating: (updating: boolean) => void;
  updateProtocol: (
    protocol: Map<string, any>,
    entity: Map<string, any> | null,
    protocolName: string | null | undefined
  ) => void;
  protocols: Map<string, any>;
  getPCRProtocolIfNeeded: (entityId: string, protocolId: string) => void;
  isPendingRun: boolean;
  protocolName?: string;
  qpcrdata?: Map<string, any>;
  isNewPendingRun: boolean;
  isRunFromTemplate: boolean;
};

export type State = {
  selectedEntity?: Map<string, any>;
  isChangingProtocol: boolean;
};

export class PCRRunProtocolImpl extends React.Component<Props, State> {
  static defaultProps = {
    entityId: undefined,
    showProtocolSelector: false,
    protocolEntity: undefined,
    protocol: undefined,
    protocolName: undefined,
    qpcrdata: undefined
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedEntity: props.protocolEntity,
      isChangingProtocol: false
    };
  }

  componentDidUpdate() {
    const { protocolIsUpdating, protocols, updateProtocol } = this.props;
    const { selectedEntity } = this.state;
    if (protocolIsUpdating && selectedEntity) {
      const protocol = protocols.getIn([selectedEntity.get('id'), 'protocol']);
      const protocolName = selectedEntity != null ? selectedEntity.get('name') : undefined;
      if (protocol) {
        // @ts-ignore
        updateProtocol(protocol, selectedEntity, protocolName);
      }
    }
  }

  getProtocol = async (entity: Map<string, any>) => {
    const { setProtocolIsUpdating, getPCRProtocolIfNeeded, protocolEntity } = this.props;
    const { selectedEntity } = this.state;
    try {
      setProtocolIsUpdating(true);
      const protocolId = entity.getIn(['meta', 'qpcrProtocol', 'protocol']);
      // @ts-ignore
      await getPCRProtocolIfNeeded(entity.get('id'), protocolId);
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Unknown Error';
      // if selectedEntity has not changed
      if (entity === selectedEntity) {
        setProtocolIsUpdating(false);
        this.setState({ selectedEntity: protocolEntity });
        notification.error(`Failed to load "${_.truncate(entity.get('name'))}". ${errorMessage}`);
      }
    }
  };

  selectProtocol = (entity: Map<string, any>) => {
    const { setProtocolIsUpdating } = this.props;
    const { selectedEntity } = this.state;
    if (entity !== selectedEntity) {
      setProtocolIsUpdating(false);
      this.setState({ selectedEntity: entity }, () => this.getProtocol(entity));
    }
  };

  changeProtocol = () => {
    this.setState({
      isChangingProtocol: true,
      selectedEntity: undefined
    });
  };

  cancelChangeProtocol = () => {
    const { setProtocolIsUpdating, entityId, qpcrdata: qpcrDataProp, updateProtocol } = this.props;
    setProtocolIsUpdating(true);
    // get original protocol from existing pending runs in the network state (this is an array of all the pending runs visited during a session)
    if (qpcrDataProp) {
      const qpcrdata = qpcrDataProp.getIn([entityId, 'data']);
      // @ts-ignore
      const originalProtocol = qpcrdata.get('protocol');
      // @ts-ignore
      const originalProtocolName = qpcrdata.getIn(['runInfo', 'protocolName']);

      this.setState(
        {
          isChangingProtocol: false
        },
        () => {
          updateProtocol(originalProtocol, null, originalProtocolName);
          setProtocolIsUpdating(false);
        }
      );
    }
  };

  isShowProtocol = () => {
    const { isNewPendingRun, isPendingRun } = this.props;
    const { isChangingProtocol, selectedEntity } = this.state;
    return isNewPendingRun || isPendingRun ? !isChangingProtocol || selectedEntity != null : true;
  };

  isProtocolNameShown = () => {
    const { isNewPendingRun, isPendingRun, protocolName, isRunFromTemplate } = this.props;
    return (!isNewPendingRun || !isPendingRun || isRunFromTemplate) && protocolName;
  };

  isProtocolSelectionShown = () => {
    const { isPendingRun, protocolName, isNewPendingRun, isRunFromTemplate } = this.props;
    const { isChangingProtocol } = this.state;
    return (
      isPendingRun && (!protocolName || isChangingProtocol || isNewPendingRun) && !isRunFromTemplate
    );
  };

  isChangeProtocolButtonShown = () => {
    const { isPendingRun, isNewPendingRun } = this.props;
    return isPendingRun && !isNewPendingRun;
  };

  _renderProtocolEntities = (protocolEntities: Seq.Indexed<Map<string, any>>) => {
    const { selectedEntity } = this.state;
    return (
      protocolEntities &&
      protocolEntities.map(entity => {
        const id = entity.get('id').toString();
        return (
          <SelectMenuItem
            key={entity.get('id')}
            selected={selectedEntity && entity.get('id') === selectedEntity.get('id')}
            value={entity}
            id={formatId('protocol-dropdown', id)}
          >
            {entity.get('name')}
          </SelectMenuItem>
        );
      })
    );
  };

  _renderDropdowm = (protocolEntities: Seq.Indexed<Map<string, any>>, scrollProps: any) => {
    const { selectedEntity } = this.state;
    return (
      <InfiniteScrollSelect
        {...scrollProps}
        id="protocol-selector-dropdown"
        placeholder="Choose a protocol for your run…"
        handleSelection={this.selectProtocol}
        value={selectedEntity ? selectedEntity.get('name') : undefined}
      >
        {this._renderProtocolEntities(protocolEntities)}
      </InfiniteScrollSelect>
    );
  };

  renderProtocolSelector = (
    isLoading: boolean,
    protocolEntities: Seq.Indexed<Map<string, any>>,
    hasMoreData: boolean,
    errorMessage: string,
    fetchMore: (...args: Array<any>) => any
  ) => {
    const { isChangingProtocol } = this.state;
    const scrollProps = {
      fetchMoreData: fetchMore,
      hasMoreData,
      isLoading,
      loadingErrored: !!errorMessage
    };
    return (
      <div className="run-protocol-header">
        <div className="label-and-error">
          <span className="control-label large">SELECT A PROTOCOL</span>
          {errorMessage && (
            <span id="run-name-error" className="error-message">
              Error loading protocols
            </span>
          )}
        </div>
        <div className="run-protocol-select">
          <div className="display-horizontal">
            {this._renderDropdowm(protocolEntities, scrollProps)}
            {isChangingProtocol && (
              <button
                id="cancel-change-protocol"
                type="button"
                onClick={this.cancelChangeProtocol}
                className="secondary-button-link align-button"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderProtocol() {
    const { protocolIsUpdating, protocol, isPendingRun, entityId, protocolName } = this.props;
    if (protocolIsUpdating) return <Loader />;
    const protocolEntityId = protocol ? protocol.get('fa_id') || entityId : undefined;
    return protocol && this.isShowProtocol() ? (
      <>
        {this.isProtocolNameShown() && (
          <div>
            <div className="protocol-name-label">PROTOCOL</div>
            <div className="display-horizontal protocol-name-text">
              <div id="protocol-name" className="protocol-name-label ">
                {protocolName}
              </div>
              {this.isChangeProtocolButtonShown() && (
                <button
                  id="change-protocol"
                  type="button"
                  onClick={this.changeProtocol}
                  className="secondary-button-link align-button"
                >
                  Change
                </button>
              )}
            </div>
          </div>
        )}
        <QpcrProtocolImpl
          key={protocolEntityId}
          // @ts-ignore
          template={fromJS({ protocol })}
          disabled
          showProtocolName={false}
          isCreate={false}
          isPendingRun={isPendingRun}
        />
      </>
    ) : (
      /* disabled continue-to-plate-setup button located in same location as enabled button that is rendered in PCRProtocolImpl */
      <div id="button-placeholder" className="button-group right">
        <PrimaryButton type="button" id="continue-to-plate-setup" disabled>
          Continue to Plate Setup
        </PrimaryButton>
      </div>
    );
  }

  render() {
    return (
      <div className="run-protocol-wrapper">
        {this.isProtocolSelectionShown() && (
          <ProtocolTemplatesLoader render={this.renderProtocolSelector} />
        )}
        {this.renderProtocol()}
      </div>
    );
  }
}

function mapStateToProps(
  state: ReduxState,
  ownProps: {
    [key: string]: any;
  }
) {
  const { protocols, currentCfxRun, qpcrdata } = state;
  const protocolEntity = currentCfxRun.get('protocolEntity');
  const protocol = currentCfxRun.getIn(['run', 'protocol']);
  const protocolName = currentCfxRun.getIn(['run', 'runInfo', 'protocolName']);
  const protocolIsUpdating = currentCfxRun.get('protocolIsUpdating');
  const isPendingRun = getIsPendingRun(state);
  const isNewPendingRun = qpcrdata ? qpcrdata.get(ownProps.entityId) == null : true;
  const isRunFromTemplate = getIsRunFromTemplate(state);
  return {
    protocolEntity,
    protocol,
    protocolIsUpdating,
    protocols,
    isPendingRun,
    protocolName,
    qpcrdata,
    isNewPendingRun,
    isRunFromTemplate
  };
}

export default connect(mapStateToProps, {
  getPCRProtocolIfNeeded: getPCRProtocolIfNeededAction,
  setProtocolIsUpdating: setProtocolIsUpdatingAction,
  updateProtocol: updateProtocolAction
  // @ts-ignore
})(PCRRunProtocolImpl);
