import React, { useEffect, useRef, useState } from 'react';
import { FleetManagementRow } from '../../fleet-management-row';
import TCell from '../../../../frontend-common-libs/src/components/common/tables/TCell';
import Checkbox from '../../../../frontend-common-libs/src/common/checkbox';
import styles from './styles/create-reservations-row.module.scss';
import InstrumentReservation from '../../InstrumentReservation';
import { ImmutableMap, InstrumentItem } from '../../../../frontend-common-libs/src/common/types';
import ReservationFacade from '../../ReservationFacade';
import ReservationTimer from '../../ReservationTimer';

export type Props = {
  instrument: ImmutableMap<InstrumentItem>;
  reservation: InstrumentReservation;
  selectAllCheckbox: boolean;
  updateSelectedInstruments: (id: string, select: boolean) => void;
};

export default function CreateReservationsRow(props: Readonly<Props>) {
  const { instrument, selectAllCheckbox, updateSelectedInstruments, reservation } = props;

  const [checkbox, setCheckbox] = useState<boolean>(selectAllCheckbox);
  const initialRender = useRef(true);
  const offlineTimeout = useRef<any>();

  const [isOffline, setIsOffline] = useState(false);

  const instrumentId = instrument.get('id');

  const reservationState = {
    reservationShadowState: reservation?.reservationShadowState,
    protocol: null
  };

  const reservationFacade = new ReservationFacade({
    instrument,
    reservationState,
    userId: reservation?.userId,
    reservationShadowLoaded: !!reservation?.isLoaded
  });

  const {
    instrumentFacade,
    isInstrumentReservable,
    displayedReservationStatus,
    reservationStatusProperties
  } = reservationFacade;
  const { modelName, instrumentStatus } = instrumentFacade;

  const canCheckboxBeDisplayed = isInstrumentReservable;

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (canCheckboxBeDisplayed) {
      setCheckbox(selectAllCheckbox);
      updateSelectedInstruments(instrumentId, selectAllCheckbox);
    }
  }, [selectAllCheckbox]);

  useEffect(() => {
    const timeout = instrumentFacade.timeoutUntilExpiration();

    if (timeout > 0) {
      setIsOffline(false);
      offlineTimeout.current = setTimeout(() => {
        setIsOffline(true);
      }, timeout);
    }
    return () => {
      if (offlineTimeout.current) {
        setIsOffline(false);
        clearTimeout(offlineTimeout.current);
      }
    };
  }, [instrumentStatus, isOffline]);

  const handleSelectInstrument = (): void => {
    setCheckbox(!checkbox);
    updateSelectedInstruments(instrumentId, !checkbox);
  };

  const renderCheckboxCell = () => {
    return (
      <TCell>
        {canCheckboxBeDisplayed ? (
          <Checkbox
            id={`checkbox_${instrumentId}`}
            name={`checkbox_${instrumentId}`}
            value={checkbox}
            checked={checkbox}
            onChange={handleSelectInstrument}
          />
        ) : null}
      </TCell>
    );
  };

  const renderReservationStatus = (): React.JSX.Element => {
    return (
      <>
        <img
          src={reservationStatusProperties.src}
          alt={reservationStatusProperties.alt}
          className={styles.statusImg}
        />
        {displayedReservationStatus}
      </>
    );
  };

  const renderAdditionalColumns = () => (
    <TCell
      className={`${styles.commonColumn} ${styles.cellText}`}
      title={displayedReservationStatus}
    >
      {renderReservationStatus()}
      <ReservationTimer
        instrument={instrument}
        reservation={reservation}
        reservationStatus={displayedReservationStatus}
        reservationTimeout={reservationFacade.reservationPendingTimeout()}
        cancellationTimeout={reservationFacade.cancellationPendingTimeout()}
      />
    </TCell>
  );

  return (
    <FleetManagementRow
      instrumentId={instrumentId}
      instrumentType={instrument.get('type')}
      modelName={modelName}
      instrumentName={instrument.get('name')}
    >
      {renderCheckboxCell()}
      {renderAdditionalColumns()}
    </FleetManagementRow>
  );
}
