import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  MaxProjectUsers,
  ProjectPermissions,
  ProjectRoles,
  projectRolesDisplayNames
} from '@biorad-lsg-tsc/organization';
import { toWords } from 'number-to-words';
import { Project, ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import '../styles/edit-project.scss';
import '../../organization-management/organizations/styles/members-table.scss';
import { emailValid } from '../../frontend-common-libs/src/common/form_validators';
import { addUserToProject as addUserToProjectAction } from '../actions/add-user-to-project';
import EditProjectHeader from './EditProjectHeader';
import { getProjectsDetailsIfNeeded as getProjectsDetailsIfNeededAction } from '../actions/get-project-details';
import { ReduxState } from '../../types';
import {
  isProjectDetailsLoaded,
  getProjectDetails,
  getProjectUserPermissions,
  getProjectUserToken
} from '../selectors/selectors';
import { ProjectDetails } from '../api/types';
import MemberRow from './MemberRow';
import { setProjectRole as setProjectRoleAction } from '../actions/set-project-role';
import ProjectToken from './ProjectToken';
import { removeUserFromProject as removeUserFromProjectAction } from '../actions/remove-user-from-project';
import { renameProject as renameProjectAction } from '../actions/rename-project';
import {
  isAddedUserNotAMemberOfTheOrganization,
  isAddUserMaxProjectUsersError,
  isUserAlreadyInProjectError
} from '../../organization-management/api/api-errors';
import AddUserToTenant from '../../organization-management/organizations/AddUserToTenant';

export type Props = {
  project: Project;
  getProjectsDetailsIfNeeded: (projectId: ProjectId) => any;
  loadingDetails: boolean;
  details: ProjectDetails | undefined;
  addUserToProject: (projectId: string, userName: string, userRole: ProjectRoles) => any;
  hasEditPermissions?: boolean;
  projectAccessToken?: string;
  setProjectRole?: (projectId: string, userName: string, role: string) => any;
  removeUserFromProject?: (projectId: string, userName: string) => any;
  renameProject?: (projectId: string, name: string) => any;
};

export type State = {
  emailError: string | undefined;
};

export class EditProjectImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.initialState;
  }

  private readonly initialState = {
    emailError: undefined
  };

  componentDidMount() {
    this.getProjectsDetailsIfNeeded();
  }

  componentDidUpdate(prevProps: Props) {
    const { project } = this.props;
    if (project !== prevProps.project) {
      this.setState(this.initialState);
    }
    this.getProjectsDetailsIfNeeded();
  }

  getProjectsDetailsIfNeeded() {
    const { project, getProjectsDetailsIfNeeded, projectAccessToken } = this.props;
    if (!projectAccessToken) return;
    getProjectsDetailsIfNeeded(project.id);
  }

  addUser = async (invitedUserEmail: string, invitedUserRole: ProjectRoles) => {
    const { project, addUserToProject } = this.props;
    if (!invitedUserEmail) {
      this.setState({ emailError: 'Email address cannot be empty.' });
      return false;
    }
    const emailError = emailValid(invitedUserEmail);
    this.setState({ emailError });
    if (emailError != null) return false;
    try {
      await addUserToProject(project.id, invitedUserEmail, invitedUserRole);
      return true;
    } catch (e) {
      this.handleInvitingUserError(e as Error);
      return false;
    }
  };

  handleInvitingUserError(e: Error) {
    let emailError = 'Error adding user';
    if (isUserAlreadyInProjectError(e)) {
      emailError = `This user has already been added to the project.`;
    } else if (isAddUserMaxProjectUsersError(e)) {
      emailError = `Limit exceeded. You can add up to ${toWords(MaxProjectUsers)} users.`;
    } else if (isAddedUserNotAMemberOfTheOrganization(e)) {
      emailError = 'The email address must belong to a member of the organization.';
    }
    this.setState({ emailError });
  }

  renderAddUser() {
    const { emailError } = this.state;
    const { hasEditPermissions } = this.props;
    return (
      <AddUserToTenant
        onAddUser={this.addUser}
        initialInvitedUserRole={ProjectRoles.Contributor}
        addButtonEnabled={hasEditPermissions}
        emailError={emailError}
        roleEnum={ProjectRoles}
        displayNames={projectRolesDisplayNames}
      />
    );
  }

  onChangeMemberRole = async (userName: string, role: string) => {
    const { setProjectRole, project } = this.props;
    if (!setProjectRole) return;
    await setProjectRole(project.id, userName, role);
  };

  onRemoveUser = async (userName: string) => {
    const { removeUserFromProject, project } = this.props;
    if (!removeUserFromProject) return;
    await removeUserFromProject(project.id, userName);
  };

  onRenameProject = async (name: string) => {
    const { renameProject, project } = this.props;
    if (!renameProject) return;
    await renameProject(project.id, name);
  };

  renderMembers() {
    const { details, hasEditPermissions, project } = this.props;
    const users = details?.users;
    return (
      <div>
        <div className="members-table">
          <span className="members-header">Project Members</span>
          <div className="member-rows">
            {users?.map((user: any, index: number) => {
              const { userEmail, userRole } = user;
              return (
                <MemberRow
                  name={userEmail}
                  role={userRole}
                  index={index}
                  key={userEmail}
                  onChangeRole={this.onChangeMemberRole}
                  disabled={!hasEditPermissions}
                  showRemoveUser={hasEditPermissions}
                  onRemoveUser={this.onRemoveUser}
                  removeUserMessage={`Are you sure you want to remove ${userEmail} from project ${project.name}?`}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderProjectDetails() {
    const { loadingDetails } = this.props;
    if (loadingDetails)
      return <span className="fa fa-spinner fa-spin fa-fw loader" id="loading-details" />;
    return (
      <div>
        {this.renderMembers()}
        {this.renderAddUser()}
      </div>
    );
  }

  render() {
    const { project, details, hasEditPermissions } = this.props;

    return (
      <div className="edit-project">
        <ProjectToken projectId={project.id} />
        <EditProjectHeader
          project={project}
          created={details?.created}
          owner={details?.owner}
          hasEditPermissions={hasEditPermissions}
          onRenameProject={this.onRenameProject}
        />
        {this.renderProjectDetails()}
      </div>
    );
  }
}

export function mapStateToProps(
  state: ReduxState,
  ownProps: Props
): {
  [key: string]: any;
} {
  const projectId = ownProps.project?.id;
  const details = getProjectDetails(state, projectId)?.toJS() as ProjectDetails;
  const projectUserPermissions = getProjectUserPermissions(state, projectId);
  const hasEditPermissions = projectUserPermissions?.hasPermissions([
    ProjectPermissions.ProjectAdministration
  ]);
  return {
    loadingDetails: !isProjectDetailsLoaded(state, projectId),
    details,
    hasEditPermissions,
    projectAccessToken: getProjectUserToken(state, projectId)
  };
}

export default connect(mapStateToProps, {
  getProjectsDetailsIfNeeded: getProjectsDetailsIfNeededAction,
  addUserToProject: addUserToProjectAction,
  setProjectRole: setProjectRoleAction,
  removeUserFromProject: removeUserFromProjectAction,
  renameProject: renameProjectAction
  // @ts-ignore
})(EditProjectImpl);
