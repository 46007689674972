import { HELP_BASE_DOMAIN, HELP_NEXT_BASE_DOMAIN } from '../frontend-common-libs/src/config/config';
import coreRoutes, { getRouteName, getRoute } from '../core/routes';
import FeatureFlags, {
  FeatureFlagKeys
} from '../frontend-common-libs/src/components/feature_flags';

export const docHelpPaths = {
  Home: '/Output/CloudUA_Next/Content/CloudUA/Home.htm',
  Contact: '/Output/CloudUA_Next/Content/CloudUA/GettingHelp.htm',
  Managing_Experiments: '/Output/CloudUA_Next/Content/CloudUA/ManagingExperiments.htm',
  Instrument_Management: '/Output/CloudUA_Next/Content/CloudUA/ManagingInstruments.htm',
  File_Management: '/Output/CloudUA_Next/Content/CloudUA/ManagingFiles.htm',
  Archive: '/Output/CloudUA_Next/Content/CloudUA/ArchivingFiles.htm',
  Managing_User_Profile: '/Output/CloudUA_Next/Content/CloudUA/ManagingUserProfile.htm',
  Terms_Of_Use: '/Output/CloudUA_Next/Content/CloudUA/BRioTermsofUse.htm',
  Forgot_Password: '/Output/CloudUA_Next/Content/CloudUA/ForgottenPassword.htm',
  default: '/Output/CloudUA_Next/Content/CloudUA/Welcome.htm',
  Change_Password: '/Output/CloudUA_Next/Content/CloudUA/User_Prefs/ChangePassword.htm',
  Link_Instrument_Troubleshooting_Tips:
    '/Output/CloudUA_Next/Content/CloudUA/TroubleshootingTips/TS_InstrumentLinkorConnect.htm',
  Early_Access_Program: '/Output/CloudUA_Next/Content/CloudUA/Addl_Info/EAP.htm',
  Organization: '/Output/Org_Mgmt/Content/CloudUA/DataSharing/DataSharing_Intro.htm',
  Managing_Organizations:
    '/Output/Org_Mgmt/Content/CloudUA/DataSharing/DataSharing_Organizations.htm',
  Managing_Projects: '/Output/Org_Mgmt/Content/CloudUA/DataSharing/DataSharing_Projects.htm'
};

const routesToHelpPaths = {
  [coreRoutes.HOME]: docHelpPaths.Home,
  [coreRoutes.CONTACT]: docHelpPaths.Contact,
  [coreRoutes.CREATE_EXPERIMENT]: docHelpPaths.Managing_Experiments,
  [coreRoutes.INSTRUMENT_LIST]: docHelpPaths.Instrument_Management,
  [coreRoutes.FILE_LIST]: docHelpPaths.File_Management,
  [coreRoutes.ARCHIVE]: docHelpPaths.Archive,
  [coreRoutes.USER_PROFILE]: docHelpPaths.Managing_User_Profile,
  [coreRoutes.CHANGE_PASSWORD]: docHelpPaths.Change_Password,
  [coreRoutes.FORGOT_PASSWORD]: docHelpPaths.Forgot_Password,
  [coreRoutes.LINK_INSTRUMENT]: docHelpPaths.Link_Instrument_Troubleshooting_Tips,
  [coreRoutes.EARLY_ACCESS_PROGRAM]: docHelpPaths.Early_Access_Program,
  [coreRoutes.ORGANIZATION]: docHelpPaths.Organization,
  [coreRoutes.ORGANIZATION_MEMBERS]: docHelpPaths.Managing_Organizations,
  [coreRoutes.ORGANIZATION_PROJECTS]: docHelpPaths.Managing_Projects
};

export default class HelpCenter {
  private readonly isSupportNextEnabled?: boolean;

  private readonly routesToHelpPaths: { [route: string]: string };

  public readonly helpCenterDomain: string;

  public constructor() {
    this.isSupportNextEnabled = FeatureFlags().get(FeatureFlagKeys.SUPPORT_NEXT);
    this.routesToHelpPaths = routesToHelpPaths;
    this.helpCenterDomain = this.isSupportNextEnabled ? HELP_NEXT_BASE_DOMAIN : HELP_BASE_DOMAIN;
  }

  public helpUrl(helpPath: string): string {
    return `${this.helpCenterDomain}${helpPath}`;
  }

  public getHelpUrl(path: string): string {
    let helpPath = this.routesToHelpPaths[path];
    if (!helpPath) {
      const routeName = getRouteName(path);
      const route = getRoute(routeName);
      helpPath = this.routesToHelpPaths[route] || docHelpPaths.default;
    }
    return this.helpUrl(helpPath);
  }
}
