import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { fromJS, Map } from 'immutable';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import conventionalPcrRoutes from '../routes';
import coreRoutes from '../../core/routes';
import { getNewFileName } from '../../frontend-common-libs/src/utils/fileUtils';
import './styles/pcr-protocol.scss';
import { getDefaultProtocol } from './repository/default-protocol';
import { PcrProtocolEditor } from '../../frontend-common-libs/src/components/pcr/pcr-protocols/PcrProtocolEditor';
import QPcrProtocol from '../../frontend-common-libs/src/components/pcr/pcr-protocols/models/QPcrProtocol';
import { createProtocol } from './actions/protocol-actions';

export type Props = {
  history?: RouteComponentProps['history'];
  selectedProjectId: ProjectId;
};

export type State = {
  done: boolean;
};

export class CreateProtocolPageImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      done: false
    };
  }

  loadPcrProtocol = async () => {
    const { selectedProjectId } = this.props;
    const options = {
      name: getNewFileName(),
      protocol: fromJS(getDefaultProtocol()) as Map<string, any>,
      projectId: selectedProjectId
    };
    return new QPcrProtocol(options);
  };

  createProtocol = async (pcrProtocol: QPcrProtocol) => {
    try {
      await createProtocol(pcrProtocol);
      this.setState({ done: true });
    } catch (e) {
      const errorMessage = 'Error creating protocol';
      notification.error(errorMessage);
    }
  };

  goToProtocolListPage = () => {
    const { history } = this.props;
    if (history) {
      history.replace(`${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`);
    }
  };

  render() {
    const { done } = this.state;
    if (done) {
      return <Redirect to={`${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`} />;
    }
    return (
      <div id="create-page" className="pcr-protocol-page">
        <Link
          id="protocols"
          to={`${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`}
          className="back-link"
        >
          <span className="chevron left">Back to protocols</span>
        </Link>
        <PcrProtocolEditor
          loadProtocol={this.loadPcrProtocol}
          enableApplyIfNoChanges
          applyButtonText="Add"
          onApplyPressed={this.createProtocol}
          onCancelPressed={this.goToProtocolListPage}
          isPcr
        />
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(CreateProtocolPageImpl);
