import { PROJECT_TOKEN_LOADED, PROJECT_TOKEN_LOADING } from './action-types';
import { refreshProjectAccessToken } from '../api/project-api';
import { getProjectRefreshToken, getProjectUserToken } from '../selectors/selectors';
import { getActiveOrg } from '../../organization-management/selectors/selectors';
import { GetState } from '../../types';
import {
  CbWithToken,
  DispatchType,
  WithRefreshToken
} from '../../organization-management/actions/WithRefreshToken';

export class WithRefreshProjectToken extends WithRefreshToken {
  constructor(
    protected dispatch: DispatchType,
    protected getState: GetState,
    protected projectId: string
  ) {
    super(dispatch, getState);
  }

  getToken(): string {
    return getProjectUserToken(this.getState(), this.projectId) as string;
  }

  getRefreshToken(): string {
    return getProjectRefreshToken(this.getState(), this.projectId) as string;
  }

  dispatchTokenLoading() {
    this.dispatch({
      type: PROJECT_TOKEN_LOADING,
      payload: { projectId: this.projectId }
    });
  }

  dispatchTokenLoaded(token: any) {
    this.dispatch({
      type: PROJECT_TOKEN_LOADED,
      payload: { loginInfo: token, projectId: this.projectId }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async refreshAccessToken(refreshToken: string, accessToken: string) {
    const orgId = getActiveOrg(this.getState());
    return refreshProjectAccessToken(refreshToken, accessToken, orgId);
  }
}

export async function withRefreshProjectToken(
  dispatch: DispatchType,
  getState: GetState,
  projectId: string,
  cb: CbWithToken
) {
  return new WithRefreshProjectToken(dispatch, getState, projectId).withRefreshToken(cb);
}
