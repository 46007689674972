import requests from '../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../frontend-common-libs/src/config/config';
import GetPCRDataRequest from './requests/GetPCRDataRequest';
import ComputeStepDataRequest from './requests/ComputeStepDataRequest';
import { StepAnalysisCompute } from '../types';
import { NullableString } from '../frontend-common-libs/src/common/nullable-types';
import {
  ProjectId,
  DEFAULT_PROJECT_ID
} from '../frontend-common-libs/src/common/project-management-types';
import { getParamsWithAccessTokenHeader } from '../organization-management/api/access-control';

const domain = GATEWAY.QPCRDATA_SERVICE_PATHS.BASE_URL;

export async function getPcrData(entityID: string, step?: number) {
  return new GetPCRDataRequest(entityID, step).send();
}

export type GetRunsQueryParams = {
  fmt: string;
  size?: number;
  scanMode?: string;
  lastSeen?: string;
  projectId?: string;
};

export async function getRuns(
  plateSize: number | null | undefined,
  scanMode: NullableString,
  lastSeen: NullableString,
  projectId: NullableString
) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.GET_RUNS;
  let queryParams: GetRunsQueryParams = {
    fmt: 'rundef'
  };
  if (plateSize && scanMode) {
    queryParams = {
      ...queryParams,
      size: plateSize,
      scanMode
    };
  }
  if (lastSeen) {
    queryParams = {
      ...queryParams,
      lastSeen
    };
  }
  if (projectId) {
    queryParams = {
      ...queryParams,
      projectId
    };
  }
  return requests.get(domain, {}, path, { queryParams });
}

export async function getRunPlate(runId: string) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.GET_RUN_PLATE(runId);
  return requests.get(domain, {}, path, {});
}

export async function getRunLogReq(runId: string) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.GET_RUN_LOG(runId);
  return requests.get(domain, {}, path, {});
}

export async function postPcrRun(run: { [key: string]: any }) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.CREATE_RUN_URL;
  return requests.post(domain, {}, path, {}, run);
}

export async function putPcrRun(
  entityID: string,
  run: {
    [key: string]: any;
  },
  projectAccessToken?: string
) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.GETPCRDATA_URL(entityID);
  const params = getParamsWithAccessTokenHeader(projectAccessToken);
  return requests.put(domain, {}, path, params, run);
}

export async function postCloneAndUpdateRun(run: { [key: string]: any }) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.CLONE_AND_UPDATE;
  return requests.post(domain, {}, path, {}, run);
}

export async function generatePcrdFile(entityID: string) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.GENERATE_PCRD_URL(entityID);
  return requests.get(domain, {}, path, {});
}

export async function getGeneratePcrdPolling(requestId: string) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.GET_GENERATE_PCRD_POLLING(requestId);
  return requests.get(domain, {}, path, {});
}

export function computeStepData(stepAnalysisCompute: StepAnalysisCompute) {
  return new ComputeStepDataRequest(stepAnalysisCompute).send();
}

export async function creatQpcrProtocolFromFile(
  name: string,
  file: Record<string, any>,
  projectId: ProjectId = DEFAULT_PROJECT_ID
) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.CREATE_PROTOCOL_FROM_FILE;
  const body = { file, name, parentId: projectId };
  const res = await requests.post(domain, {}, path, {}, body);
  return res.data;
}
