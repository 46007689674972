import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, Seq } from 'immutable';
import { getUserFilesIfNeeded as getUserFilesIfNeededAction } from '../../file-management/actions/actions';
import RecentFilesTable from './RecentFilesTable';
import { ReduxState } from '../../types';
import { getSelectedProjectId } from '../../project-management';

export type RecentFilesProps = {
  isLoading: boolean;
  files: Seq.Indexed<Map<string, any>>;
  lastSeen: string;
  errorMessage: string;
  staleData: boolean;
  getUserFilesIfNeeded: typeof getUserFilesIfNeededAction;
};

export class RecentFilesImpl extends Component<RecentFilesProps> {
  componentDidMount() {
    const { files } = this.props;
    if (!files.size) {
      this.fetchMore();
    }
  }

  componentDidUpdate() {
    const { errorMessage, isLoading, staleData } = this.props;
    if (errorMessage === '' && !isLoading && staleData) this.fetchMore();
  }

  fetchMore = () => {
    const { getUserFilesIfNeeded, lastSeen } = this.props;
    getUserFilesIfNeeded(lastSeen);
  };

  render() {
    const { isLoading, files, errorMessage } = this.props;
    return (
      <RecentFilesTable
        isLoading={isLoading}
        list={files}
        errorMessage={errorMessage}
        getUserFilesIfNeeded={this.fetchMore}
      />
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  const projectFiles = state.userFiles.getIn(['projects', projectId]) as Map<string, any>;
  return {
    isLoading: projectFiles.get('isLoading'),
    files: projectFiles.get('files').valueSeq(),
    lastSeen: projectFiles.get('lastSeen'),
    errorMessage: projectFiles.get('errorMessage'),
    staleData: projectFiles.get('staleData')
  };
}

export default connect(mapStateToProps, {
  getUserFilesIfNeeded: getUserFilesIfNeededAction
  // @ts-ignore
})(RecentFilesImpl);
