import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { Map } from 'immutable';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { canOpenFile, getPathForFileType } from '../../frontend-common-libs/src/utils/fileUtils';
import { isMac } from '../../frontend-common-libs/src/utils/commonUtils';
import {
  popupMenuCommands,
  PopupMenuItem
} from '../../frontend-common-libs/src/components/common/menus/PopupMenu';
import EntityRow from '../../frontend-common-libs/src/components/common/tables/EntityRow';
import {
  FILETYPE_IN_PROGRESS_PTC_TEMPO_RUN,
  PTCTempoFileIcon,
  PTCTempoFileRowType
} from '../common';
import { UserFile } from '../../frontend-common-libs/src/common/types';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../frontend-common-libs/src/components/feature_flags';

export type Props = {
  item: Map<string, any>;
  tableBodyRef: any;
  archiveUserFiles: (fileList: string) => unknown;
  renameFile: (fileId: string, newName: string) => unknown;
  onSelectedRow: (id: string) => void;
  moveFile?: (entity: UserFile) => unknown;
  fileHistory?: (entity: UserFile) => unknown;
};

export type State = {
  redirectToFile: boolean;
};

export default class PTCTempoFileRow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      redirectToFile: false
    };
  }

  private get fileIcon(): JSX.Element {
    const { item } = this.props;
    const fileType = item.get('type');
    return <PTCTempoFileIcon type={fileType} />;
  }

  private get displayedFileType(): string {
    const { item } = this.props;
    const fileType = item.get('type');
    return PTCTempoFileRowType.getDisplayedFileType(fileType);
  }

  onFileKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      this.tryOpenFile(event);
    }
  };

  notifyCannotOpen = () => {
    const { item } = this.props;
    const name = item.get('name');
    notification.info(`"${_.truncate(name)}" cannot be viewed.`);
  };

  moveFileAllowed = () => {
    const { item } = this.props;
    return item.get('type') !== FILETYPE_IN_PROGRESS_PTC_TEMPO_RUN;
  };

  createMenuItemsList = () => {
    const { renameFile, archiveUserFiles, moveFile, fileHistory } = this.props;
    const menuItems: PopupMenuItem[] = [];

    menuItems.push({
      menuItemKey: popupMenuCommands.rename,
      menuItemCallback: renameFile,
      menuItemTitle: 'Edit File'
    });

    if (moveFile && this.moveFileAllowed()) {
      menuItems.push({
        menuItemKey: popupMenuCommands.move,
        menuItemCallback: moveFile
      });
    }

    if (FeatureFlags().get(FeatureFlagKeys.FILE_HISTORY) && fileHistory) {
      menuItems.push({
        menuItemKey: popupMenuCommands.fileHistory,
        menuItemCallback: fileHistory
      });
    }

    menuItems.push({
      menuItemKey: popupMenuCommands.separator,
      menuItemCallback: () => {}
    });
    menuItems.push({
      menuItemKey: popupMenuCommands.archive,
      menuItemCallback: archiveUserFiles,
      menuItemTitle: 'Archive File'
    });

    return menuItems;
  };

  tryOpenFile = (event: any) => {
    const { item } = this.props;
    const fileType = item.get('type');
    if (canOpenFile(fileType)) {
      event.preventDefault();
      event.stopPropagation();
      if ((event.ctrlKey && !isMac()) || (event.metaKey && isMac())) {
        const id = item.get('id');
        const url = getPathForFileType(fileType, id);
        window.open(url, '_blank');
      } else {
        this.setState({ redirectToFile: true });
      }
    } else {
      this.notifyCannotOpen();
    }
  };

  render() {
    const { item, tableBodyRef } = this.props;
    const id = item.get('id');
    const fileType = item.get('type');

    const { redirectToFile } = this.state;
    if (redirectToFile) {
      return <Redirect push to={getPathForFileType(fileType, id)} />;
    }

    const canOpen = canOpenFile(fileType);

    return (
      <EntityRow
        id={`file-item_${id}`}
        linkId={`file-link_${id}`}
        item={item}
        tableBodyRef={tableBodyRef}
        canOpen={canOpen}
        popupItems={this.createMenuItemsList()}
        linkedItemName="File"
        entityPath={getPathForFileType(fileType, id)}
        onKeyDown={this.onFileKeyDown}
        onClick={this.tryOpenFile}
        icon={this.fileIcon}
        displayedFileType={this.displayedFileType}
      />
    );
  }
}
